<nz-page-header>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a [routerLink]="whitelabel ? '../../..' : '../..'">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="whitelabel ? '../..' : '..'">
        <i nz-icon nzType="shop"></i>
        Whitelabels
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item *ngIf="whitelabel">
      <a routerLink="..">
        <img *ngIf="whitelabel" [src]="whitelabel?.favIcon" style="width: 20px"> <i *ngIf="!whitelabel" nz-icon
                                                                                    nzType="shop"></i>
        {{whitelabel.companyName}}
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon [nzType]="whitelabel ? 'edit' : 'plus'"></i>
      {{whitelabel ? 'Editar' : 'Criar'}} whitelabel
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title>
    <i nz-icon nzType="shop"></i> {{whitelabel ? 'Editar' : 'Criar'}} whitelabel
  </nz-page-header-title>
  <nz-page-header-extra>
    <button nz-button nzType="primary" [nzLoading]="status === STATUS.LOADING" (click)="save()"
            [disabled]="status === STATUS.LOADING">
      Salvar <i nz-icon nzType="check"></i>
    </button>
  </nz-page-header-extra>
</nz-page-header>

<div nz-row class="p-3">
  <div nz-col nzSpan="24">
    <nz-steps nzType="navigation" nzSize="small" [nzCurrent]="index" (nzIndexChange)="onIndexChange($event)">
      <nz-step nzTitle="Informações comerciais" nzIcon="audit"
               [nzStatus]="((whitelabelDetailsForm.invalid && whitelabelDetailsForm.touched) || (whitelabelStylesForm.invalid && whitelabelStylesForm.touched)) && 'error' || 'process'"></nz-step>
      <nz-step nzTitle="Configurações técnicas" nzIcon="cloud"
               [nzStatus]="((whitelabelStoreDetailsForm.invalid && whitelabelStoreDetailsForm.touched) || (whitelabelIntegrationKeysForm.invalid && whitelabelIntegrationKeysForm.touched) || whitelabelEmailConfigForm.invalid && whitelabelEmailConfigForm.touched) && 'error' || 'process'"></nz-step>
    </nz-steps>
  </div>
  <div nz-col nzSpan="24">
    <ng-container *ngIf="index === STEPS.DETAIL">
      <form nz-form [formGroup]="whitelabelDetailsForm">
        <div nz-row [nzGutter]="24" class="mt-3">
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">Habilitado</nz-form-label>
              <nz-form-control nzHasFeedback [nzSpan]="24">
                <nz-radio-group formControlName="enabled">
                  <label nz-radio-button [nzValue]="false">Não</label>
                  <label nz-radio-button [nzValue]="true">Sim</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Identificação</nz-form-label>
                <input nz-input placeholder="econdos" formControlName="name">
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Nome da empresa</nz-form-label>
                <input nz-input placeholder="eCondos Sistemas" formControlName="companyName">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Assinatura do e-mail</nz-form-label>
                <input nz-input placeholder="Atenciosamente, eCondos" formControlName="emailSignature">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Nome do remetente</nz-form-label>
                <input nz-input placeholder="eCondos" formControlName="emailFromName">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>E-mail de redirecionamento</nz-form-label>
                <input nz-input placeholder="app@econdos.com.br" formControlName="emailFrom">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Telefone</nz-form-label>
                <input nz-input placeholder="(12) 9999-9999" formControlName="phone" maxlength="15">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>E-mail de suporte</nz-form-label>
                <input nz-input placeholder="suporte@econdos.com.br" formControlName="emailSupport">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Link da web</nz-form-label>
                <input nz-input placeholder="app.econdos.com.br" formControlName="webAppUrl">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Link do QRCode</nz-form-label>
                <input nz-input placeholder="qr.econdos.com.br" formControlName="qrCodePageUrl">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Mensagem da página do QRCode</nz-form-label>
                <input nz-input placeholder="Acesso o condomínio pelo QRCode" formControlName="qrCodePageMessage">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Título da página web</nz-form-label>
                <input nz-input placeholder="eCondos" formControlName="pageTitle">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-label>Link do executável Windows 32 bits</nz-form-label>
              <nz-input-group nzSearch [nzAddOnAfter]="copyButtonX86">
                <input formControlName="x86Installer" type="text" nz-input placeholder="Link do executável x86"/>
              </nz-input-group>
              <ng-template #copyButtonX86>
                <button nzSearch nz-button nzType="primary"
                        (click)="copyLink(whitelabelDetailsForm.get('x86Installer')?.value)">
                  <i nz-icon nzType="copy"></i>
                </button>
              </ng-template>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-label>Link do executável Windows 64 bits</nz-form-label>
              <nz-input-group nzSearch [nzAddOnAfter]="copyButtonX64">
                <input formControlName="x64Installer" type="text" nz-input placeholder="Link do executável x64"/>
              </nz-input-group>
              <ng-template #copyButtonX64>
                <button nzSearch nz-button nzType="primary"
                        (click)="copyLink(whitelabelDetailsForm.get('x64Installer')?.value)">
                  <i nz-icon nzType="copy"></i>
                </button>
              </ng-template>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Link de suporte ao cliente</nz-form-label>
                <input nz-input placeholder="Insira o link do suporte ao cliente" formControlName="customerSupportUrl">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Mensagem de boas vindas (app)</nz-form-label>
                <input nz-input placeholder="Bem vindo ao eCondos" formControlName="welcomeMessage">
              </nz-form-control>

            </nz-form-item>
          </div>
        </div>
      </form>

      <form nz-form [formGroup]="whitelabelStylesForm">
        <div nz-row [nzGutter]="24">
          <nz-divider nzText="Cores"></nz-divider>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor de fundo da barra</nz-form-label>
                <input type="color" nz-input formControlName="--navbar-background">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor do texto</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--navbar-text">
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor da sombra da barra</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--navbar-hover-background">
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor do texto sombreado</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--navbar-hover-text">
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor de fundo do modal</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--modal-header-background">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor de texto sombreado do modal</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--modal-header-text">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor primária</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--primary">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor primária escura</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--primary-darker">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor primária</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--on-primary">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor de fundo</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--background">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="12" nzLg="6" nzXXl="4">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Cor de fundo</nz-form-label>
                <input type="color" nz-input placeholder="#333" formControlName="--on-background">
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="index === STEPS.RESOURCE">
      <form nz-form [formGroup]="whitelabelStoreDetailsForm">
        <div nz-row [nzGutter]="24">
          <nz-divider nzText="Lojas do aplicativo"></nz-divider>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Nome do pacote (Google)</nz-form-label>
                <input nz-input placeholder="br.com.econdos" formControlName="googlePackageName">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Nome do pacote (Apple)</nz-form-label>
                <input nz-input placeholder="br.com.econdos" formControlName="applePackageName">
              </nz-form-control>

            </nz-form-item>
          </div>
        </div>
      </form>
      <form nz-form [formGroup]="whitelabelIntegrationKeysForm">
        <div nz-row [nzGutter]="24">
          <nz-divider nzText="Notificações"></nz-divider>
          <p>Esta seção permite configurar o serviço de notificações de forma intuitiva, sem a necessidade de acessar o
            OneSignal. É necessário informar alguns dados, são eles:</p>

          <ng-container *ngIf="!whitelabelIntegrationKeysForm.dirty">
            <nz-descriptions nzTitle="ID do Firebase" nzBordered>
              <nz-descriptions-item nzTitle="Como acessar" nzSpan="2">Criar uma conta no <a
                href="https://console.firebase.google.com/" target="_blank"> Firebase</a></nz-descriptions-item>
              <nz-descriptions-item nzTitle="Criar projeto" nzSpan="2">
                Crie um projeto com o nome do whitelabel. Exemplo:
                eCondos {{whitelabelDetailsForm.get('name')?.value || 'whitelabel'}}
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Encontre o ID da API" nzSpan="2">Encontre o ID do remetente em
                Configurações
                > Cloud Messaging
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Exemplo do ID" nzSpan="2">1074228500078</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Encontre a chave" nzSpan="2">Encontre o ID do remetente em Configurações
                > Cloud Messaging
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Exemplo de chave" nzSpan="2">
                AAAA1NzGzEo:APA91bGpYsW4ulpQOsogqKax09277r...
              </nz-descriptions-item>
            </nz-descriptions>

            <nz-descriptions class="mt-2" nzTitle="Certificado P12" nzBordered>
              <nz-descriptions-item nzTitle="Como acessar" nzSpan="3">
                Acesse <a href="https://onesignal.com/provisionator" target="_blank"> OneSignal Provisionator</a> com a
                conta da Apple Developer
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Como gerar" nzSpan="2">Com o aplicativo e os certificados criado na Apple.
                Selecione o nome do pacote para gerar os certificados.
              </nz-descriptions-item>
              <nz-descriptions-item nzTitle="Certificado" nzSpan="2">
                Baixe o certificado com extensão p12 e faça o upload do arquivo juntamente com a senha que foi gerada.
              </nz-descriptions-item>
            </nz-descriptions>
          </ng-container>

          <nz-divider nzType="horizontal" nzOrientation="left" nzText="OneSignal"></nz-divider>

          <ng-container *ngIf="!whitelabelIntegrationKeysForm.dirty">
            <form [formGroup]="oneSignalSetup" style="display: flex; max-width: 100%;">
              <div nz-col [nzSpan]="6">
                <nz-form-item>
                  <nz-form-control>
                    <nz-form-label>Firebase ID</nz-form-label>
                    <input nz-input placeholder="1074228500078" formControlName="firebaseId">
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col [nzSpan]="6">
                <nz-form-item>
                  <nz-form-control>
                    <nz-form-label>Firebase Key</nz-form-label>
                    <input nz-input placeholder="AAAA_DdWIiA" formControlName="firebaseKey">
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col [nzSpan]="12">
                <nz-form-label class="p-0">Certificado P12</nz-form-label>
                <div style="display: flex; align-items: start; gap: 5px;">
                  <nz-upload
                    nzAccept=".p12"
                    [nzMultiple]="false"
                    [nzLimit]="1"
                    (nzChange)="handleCertificateUpload($event)">
                    <button nz-button>
                      <span nz-icon nzType="upload"></span>
                      Selecionar certificado
                    </button>
                  </nz-upload>
                  <nz-input-group nzAddOnBefore="Senha">
                    <input type="text" nz-input placeholder="1345235" formControlName="certificateId"/>
                  </nz-input-group>
                </div>
              </div>
            </form>

            <div nz-col [nzSpan]="24">
              <ng-template #indicatorTemplate><span nz-icon nzType="loading"></span></ng-template>
              <button type="submit" nz-button nzType="primary" [disabled]="oneSignalSetup.invalid"
                      (click)="handleOneSignalConfig()">
                Enviar configuração
                <nz-spin *ngIf="oneSignalSetupStatus === STATUS.LOADING" nzSimple
                         [nzIndicator]="indicatorTemplate"></nz-spin>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="whitelabelIntegrationKeysForm.dirty">
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control nzHasFeedback>
                  <nz-form-label>OneSignal APP ID</nz-form-label>
                  <input nz-input placeholder="056325fe-25a4-4e8b-a461-894d3d9dc698" formControlName="oneSignalAppId">
                </nz-form-control>

              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control nzHasFeedback>
                  <nz-form-label>OneSignal Rest API Key</nz-form-label>
                  <input nz-input placeholder="M2FjZjgyZjQtY2NkZi00MzhiLWJiYzQtODAxNTNlZTkzNTk4"
                         formControlName="oneSignalRestApiKey">
                </nz-form-control>

              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control nzHasFeedback>
                  <nz-form-label>OneSignal Google Sender ID</nz-form-label>
                  <input nz-input placeholder="648609397568" formControlName="oneSignalGoogleSenderId">
                </nz-form-control>

              </nz-form-item>
            </div>
          </ng-container>

        </div>
      </form>

      <form nz-form [formGroup]="whitelabelEmailConfigForm">
        <div nz-row [nzGutter]="24">
          <nz-divider nzText="E-mail"></nz-divider>
          <div nz-col nzXs="24" nzSm="12" nzMd="6">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" class="p-0">Modos</nz-form-label>
              <nz-form-control nzHasFeedback [nzSpan]="24">
                <nz-radio-group [(ngModel)]="smtpByNodeMailer" [ngModelOptions]="{standalone: true}">
                  <label nz-radio-button [nzValue]="false">Modo 1</label>
                  <label nz-radio-button [nzValue]="true">Modo 2</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzXs="24" nzSm="12" nzMd="6">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" class="p-0">SSL</nz-form-label>
              <nz-form-control nzHasFeedback [nzSpan]="24">
                <nz-radio-group formControlName="ssl">
                  <label nz-radio-button [nzValue]="false">Ativ.</label>
                  <label nz-radio-button [nzValue]="true">Desativ.</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="24">
          <div nz-col nzXs="24" nzSm="12" nzMd="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Usuário</nz-form-label>
                <input nz-input placeholder="app@econdos.com.br" formControlName="user">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Senha</nz-form-label>
                <nz-form-label>Senha</nz-form-label>
                <input nz-input placeholder="*****" formControlName="password">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Servidor SMTP</nz-form-label>
                <input nz-input placeholder="smtp.kinghost.com.br" formControlName="host">
              </nz-form-control>

            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-label>Porta SMTP</nz-form-label>
                <input nz-input placeholder="587" formControlName="port">
              </nz-form-control>

            </nz-form-item>
          </div>
        </div>
      </form>

    </ng-container>
  </div>
</div>

