<nz-page-header class="site-page-header" nzTitle="Funcionalidades"></nz-page-header>

<div nz-row [nzGutter]="16" class="px-3 py-3">
  <nz-card *ngFor="let item of items" nz-col class="gutter-row" [nzSpan]="6" style="cursor: pointer" [routerLink]="item.route">
    <h2>{{ item.title }}</h2>

    <div style="text-align: center">
      <i style="font-size: 3rem" nz-icon [nzType]="item.icon" nzTheme="outline"></i>
    </div>
  </nz-card>
</div>
