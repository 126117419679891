<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="../..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="..">
        <i nz-icon nzType="shop"></i>
        Condomínios
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="shop"></i>
      Condomínio
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title>Condomínio</nz-page-header-title>
</nz-page-header>

<div class="px-4 condo-details-container">
  <ng-container *ngIf="route.data | async as data">
    <div nz-row class="space-align-container">
      <div nz-col>
        <nz-avatar [nzSize]="64" nzIcon="shop" [nzSrc]="data.condos?.pictures[0]?.thumbnail"></nz-avatar>
      </div>
      <div nz-col nzSpan="18" nz-scape nzAlign="center" style="margin-left: 15px">
        <h2>{{ data.condos?.name }}</h2>
        <button (click)="showNotifyModal(data.condos)" nzSize="small" nz-button nzType="primary" nzDanger>
          <i nz-icon nzType="notification"></i>
          Enviar notificação
        </button>
      </div>
    </div>

    <hr />

    <div>
      <div>
        <label>Id: </label>
        <span>
          {{ data.condos?._id }}
        </span>
      </div>

      <div>
        <label>Endereço: </label>
        <span> {{ data.condos?.address }}, {{ data.condos?.city }} - {{ data.condos?.state }} </span>
      </div>

      <div>
        <label>CNPJ :</label>
        <span>
          {{ data.condos?.cnpj }}
        </span>
      </div>

      <div *ngFor="let phone of data.condos?.phones">
        <label>Telefone:</label>
        <span>
          {{ phone }}
        </span>
      </div>

      <div>
        <label>Data de criação:</label>
        <span>
          {{ data.condos?.createdAt | date: 'dd/MM/yyyy, HH:mm:ss' }}
        </span>
      </div>

      <div>
        <label>Criado por:</label>
        <a [routerLink]="['/home/users/' + data.condos?.createdBy?._id]">
          {{ data.condos?.createdBy?.firstName }} {{ data.condos?.createdBy?.lastName }}
        </a>
      </div>

      <div>
        <label>Plano:</label>
        <span>
          {{ data.condos?.plan?.name }}
        </span>
      </div>

      <div>
        <label>Periodo de teste:</label>
        <span>
          {{ data.condos?.daysOfTrial + ' dias' }}
        </span>
      </div>

      <div>
        <label>Tipo de unidades:</label>
        <span>
          {{ data.condos?.type }}
        </span>
      </div>

      <div>
        <label>Total de unidades: </label>
        <button *ngIf="residencesCount < 0" (click)="getResidences(data.condos?._id)" nzSize="small" nz-button nzType="primary">
          Ver Unidades
        </button>
        <ng-container *ngIf="residencesCount >= 0"> {{ residencesCount }} unidade(s) </ng-container>
      </div>

      <div>
        <div *ngIf="data.condos?.whiteLabel">
          <label>WhiteLabel:</label>
          <span>
            {{ data.condos.whiteLabel?.companyName }}
          </span>
          <button (click)="showModalWhiteLabelSelect(data.condos, false)" nzSize="small" nz-button nzType="primary">
            <i nz-icon nzType="edit" nzTheme="outline"></i>
          </button>
        </div>

        <div *ngIf="!data.condos?.whiteLabel">
          <label>WhiteLabel: </label>
          <span>
            <button (click)="showModalWhiteLabelSelect(data.condos, true)" nzSize="small" nz-button nzType="primary">
              Adicionar Whitelabel
            </button>
          </span>
        </div>
      </div>

      <div *ngIf="!data.condos.sale">
        <label>Responsável Financeiro: </label>
        <button (click)="showModal(data?.condos)" nzSize="small" nz-button nzType="primary">Cadastrar responsável</button>
      </div>

      <div>
        <label>Habilitar configurações </label>
        <button nz-button [nzType]="'primary'" (click)="updateSelfConfigPermition(data.condos)" [nzSize]="'small'">
          {{ data.condos.selfConfig === 'ENABLED' ? 'Habilitado' : 'Desabilitado' }}
        </button>
      </div>

      <nz-collapse>
        <nz-collapse-panel
          nzHeader="Alterar Configurações do Condomínio"
          nzActive="false"
          [nzDisabled]="data.condos.selfConfig !== 'ENABLED' || condoParamsKeys.length === 0">
          <div>
            <label style="margin-right: 10px">Selecione um plano customizado: </label>
            <nz-radio-group [(ngModel)]="plan" nzSize="small" nzButtonStyle="solid" (ngModelChange)="onPlanChange()">
              <label nz-radio-button nzValue="BASIC">Plano BASIC</label>
              <label nz-radio-button nzValue="INTER">Plano INTER</label>
              <label nz-radio-button nzValue="FULL">Plano FULL</label>
            </nz-radio-group>
          </div>
          <nz-divider></nz-divider>
          <div nz-row class="condo-configs-container">
            <div nz-col nzSm="24" nzMd="12" nzLg="8" *ngFor="let param of condoParamsKeys">
              <label
                nz-checkbox
                [(ngModel)]="CONDO_PARAMS[param].toggleValue"
                (ngModelChange)="hasChanged = true"
                type="checkbox"
                data-toggle="toggle">
                <span nz-tooltip [nzTooltipTitle]="CONDO_PARAMS[param].description">{{ CONDO_PARAMS[param].label }}</span>
              </label>
            </div>
          </div>
          <div style="text-align: center">
            <button
              nz-button
              *ngIf="hasChanged"
              [nzType]="'primary'"
              (click)="updateCondoConfigs(data.condos)"
              [nzLoading]="status === STATUS.LOADING"
              [disabled]="status === STATUS.LOADING">
              Salvar Mudanças nas Configurações
            </button>
          </div>
        </nz-collapse-panel>
      </nz-collapse>

      <nz-collapse *ngIf="data.condos.sale" nzExpandIconPosition="right" [nzBordered]="true">
        <nz-collapse-panel [nzShowArrow]="false" [nzHeader]="'Dados do Responsável'">
          <button (click)="showModal(data?.condos)" nzSize="small" nz-button nzType="primary">Modificar Responsável</button>
          <div>
            <label>Nome: </label>
            <span>
              {{ data.condos?.sale?.name }}
            </span>
          </div>

          <div>
            <label>Endereço: </label>
            <span>
              {{ data.condos?.sale?.address }}, {{ data.condos?.sale?.neighborhood }}, {{ data.condos?.sale?.city }} -
              {{ data.condos?.sale?.state }}
            </span>
          </div>

          <div>
            <label>Documento:</label>
            <span> {{ data.condos?.sale?.document?.type }}: {{ data.condos?.sale?.document?.number }} </span>
          </div>

          <div>
            <label>Telefone:</label>
            <span>
              {{ data.condos?.sale?.phone }}
            </span>
          </div>

          <h2 style="font-weight: bold">Detalhes Financeiros</h2>
          <hr />

          <div>
            <label>Valor por unidade:</label>
            <span>
              {{ data.condos?.sale?.unitValue | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>

          <div>
            <label>Quantidade de unidades:</label>
            <span>
              {{ data.condos?.sale?.numberOfResidences | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>

          <div>
            <label>Integração:</label>
            <span>
              {{ data.condos?.sale?.integrations | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>

          <div>
            <label>Implantação:</label>
            <span>
              {{ data.condos?.sale?.implantation | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>

          <div>
            <label>WhiteLabel:</label>
            <span>
              {{ data.condos?.sale?.whiteLabelValue | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>
          <div>
            <label>Manutenção do WhiteLabel:</label>
            <span>
              {{ data.condos?.sale?.whiteLabelMaintenance | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>
          <div>
            <label>Outros Valores:</label>
            <span>
              {{ data.condos?.sale?.otherValue | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>

          <div>
            <label>Desconto:</label>
            <span>
              {{ data.condos?.sale?.discount | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>

          <div>
            <label>Valor Final:</label>
            <span>
              {{ data.condos?.sale?.totalValue | currency: 'R$ ' : 'symbol' : '1.2-2' : 'pt' }}
            </span>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </ng-container>
</div>
