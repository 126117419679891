import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {WhiteLabelService} from '@api/service/white-label.service';
import {WhiteLabel} from '@api/model/white-label';
import {ActivatedRoute, Router} from '@angular/router';
import {emailValidator} from '@api/util/validators';
import {distinctUntilChanged, filter, map, takeUntil, timeout} from 'rxjs/operators';
import {noop, Observable, Subject} from 'rxjs';
import {formatPhone} from '@api/utils';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {ClipboardService} from 'ngx-clipboard';
import {NzUploadChangeParam} from 'ng-zorro-antd/upload';
import {OneSignalApp, OneSignalService} from '@api/service/one-signal.service';
import {environment} from '@environment';

const awsS3Paths: any = {
  'resources/icon.png': 'icon.png',
  'resources/splash.png': 'splash.png',
  'src/assets/img/logo.png': 'logo.png',
  'resources/custom/android-notifications/drawable-hdpi/ic_stat_onesignal_default.png': 'notification-icons/drawable-hdpi/ic_stat_onesignal_default.png',
  'resources/custom/android-notifications/drawable-mdpi/ic_stat_onesignal_default.png': 'notification-icons/drawable-mdpi/ic_stat_onesignal_default.png',
  'resources/custom/android-notifications/drawable-xhdpi/ic_stat_onesignal_default.png': 'notification-icons/drawable-xhdpi/ic_stat_onesignal_default.png',
  'resources/custom/android-notifications/drawable-xxhdpi/ic_stat_onesignal_default.png': 'notification-icons/drawable-xxhdpi/ic_stat_onesignal_default.png',
  'resources/custom/android-notifications/drawable-xxxhdpi/ic_stat_onesignal_default.png': 'notification-icons/drawable-xxxhdpi/ic_stat_onesignal_default.png',
  'resources/custom/android-notifications/drawable-xxxhdpi/ic_onesignal_large_icon_default.png': 'notification-icons/drawable-xxxhdpi/ic_onesignal_large_icon_default.png'
}

const getBase64FromFile = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    console.log('file', file)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

@Component({
  selector: 'app-create-white-label-page',
  templateUrl: './create-white-label-page.html',
  styleUrls: ['./create-white-label-page.scss'],
})
export class CreateWhiteLabelComponent implements OnInit, OnDestroy {
  @Input() public whitelabel!: WhiteLabel;

  public whitelabelDetailsForm!: FormGroup;
  // public whitelabelResourcesForm!: FormGroup;
  public whitelabelStoreDetailsForm!: FormGroup;
  public whitelabelStylesForm!: FormGroup;
  public whitelabelIntegrationKeysForm!: FormGroup;
  // public whitelabelTextReplacementsForm!: FormArray;
  // public whitelabelFileReplacementsForm!: FormArray;
  public whitelabelEmailConfigForm!: FormGroup;

  public smtpByNodeMailer = false;

  public index = 0;
  public STEPS = {
    DETAIL: 0,
    RESOURCE: 1,
    TEXT: 2,
    FILE: 3
  };
  public STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  }
  public status: number | null = null;

  public oneSignalSetup!: FormGroup;
  public oneSignalSetupStatus: number | null = null;
  public oneSignalCertificateUpload$!: Observable<string>;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly whitelabelService: WhiteLabelService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NzNotificationService,
    private clipboard: ClipboardService,
    private oneSignalService: OneSignalService
  ) {
    this.oneSignalCertificateUpload$ = new Observable<string>()
  }

  private setValuesToUpdate(): void {
    const {
      enabled,
      name,
      companyName,
      emailSignature,
      emailFromName,
      emailFrom,
      phone,
      emailSupport,
      webAppUrl,
      qrCodePageUrl,
      qrCodePageMessage,
      pageTitle,
      x86Installer,
      x64Installer,
      applePackageName,
      googlePackageName,
      cssVariables,
      oneSignalRestApiKey,
      oneSignalGoogleSenderId,
      oneSignalAppId,
      emailjs,
      customerSupportUrl,
      nodemailerUri,
      textReplacements
    } = this.whitelabel;

    const welcomeMessageReplacement = (textReplacements || []).find((replacement: any) => replacement?.from === 'Seja bem vindo ao eCondos!');
    const welcomeMessage = welcomeMessageReplacement?.to || '';

    const details = {
      enabled,
      name,
      companyName,
      emailSignature,
      emailFromName,
      emailFrom,
      phone,
      emailSupport,
      webAppUrl,
      qrCodePageUrl,
      qrCodePageMessage,
      pageTitle,
      x86Installer,
      x64Installer,
      customerSupportUrl,
      welcomeMessage
    };
    const packages = {applePackageName, googlePackageName};
    const integrationKeys = {oneSignalAppId, oneSignalRestApiKey, oneSignalGoogleSenderId}
    this.whitelabelDetailsForm.patchValue(details, {emitEvent: false});
    this.whitelabelStoreDetailsForm.patchValue(packages, {emitEvent: false});

    this.whitelabelIntegrationKeysForm.patchValue(integrationKeys, {emitEvent: false});
    this.whitelabelIntegrationKeysForm.markAsDirty();

    this.whitelabelStylesForm.patchValue(cssVariables, {emitEvent: false});

    if (nodemailerUri?.length) {
      // smtps://app@conecta-mais.com:Conect@+rs0519@mail.conecta-mais.com:465
      const [, email, passwordAndServer, port] = nodemailerUri.replace('//', '').split(':');
      const [password, server] = passwordAndServer.split('@');
      this.whitelabelEmailConfigForm.patchValue({
        user: email,
        password,
        host: server,
        port
      })
      this.smtpByNodeMailer = true;
    } else if (emailjs) {
      this.whitelabelEmailConfigForm.patchValue(emailjs, {emitEvent: false});
      this.smtpByNodeMailer = false;
    }
  }

  private initForms(): void {
    this.whitelabelDetailsForm = this.formBuilder.group({
      enabled: [true],
      name: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      emailSignature: ['', [Validators.required]],
      emailFromName: ['', [Validators.required]],
      emailFrom: ['', [emailValidator, Validators.required]],
      phone: ['', [Validators.required, Validators.maxLength(15), Validators.minLength(14)]],
      emailSupport: ['', [emailValidator]],
      webAppUrl: ['', [Validators.required]],
      qrCodePageUrl: ['', [Validators.required]],
      qrCodePageMessage: [''],
      pageTitle: ['', [Validators.required]],
      x86Installer: [''],
      x64Installer: [''],
      customerSupportUrl: [''],
      welcomeMessage: ['']
    });
    this.whitelabelStylesForm = this.formBuilder.group({
      '--navbar-background': ['', [Validators.required]],
      '--navbar-text': ['', [Validators.required]],
      '--navbar-hover-background': ['', [Validators.required]],
      '--navbar-hover-text': ['', [Validators.required]],
      '--modal-header-background': ['', [Validators.required]],
      '--modal-header-text': ['', [Validators.required]],
      '--primary': ['', [Validators.required]],
      '--primary-darker': ['', [Validators.required]],
      '--on-primary': ['', [Validators.required]],
      '--background': ['', [Validators.required]],
      '--on-background': ['', [Validators.required]]
    });
    this.whitelabelIntegrationKeysForm = this.formBuilder.group({
      oneSignalAppId: [''],
      oneSignalRestApiKey: [''],
      oneSignalGoogleSenderId: ['']
    });
    this.whitelabelStoreDetailsForm = this.formBuilder.group({
      applePackageName: ['', [Validators.required]],
      googlePackageName: ['', [Validators.required]]
    });
    this.whitelabelEmailConfigForm = this.formBuilder.group({
      user: ['', [Validators.required]],
      password: ['', [Validators.required]],
      host: ['', [Validators.required]],
      ssl: [false, [Validators.required]],
      port: [80, [Validators.required]]
    });
    this.oneSignalSetup = this.formBuilder.group({
      certificateId: ['', Validators.required],
      certificateFile: [null, Validators.required],
      firebaseId: ['', Validators.required],
      firebaseKey: ['', Validators.required]
    })

    if (this.whitelabel) {
      this.setValuesToUpdate();
    }

    this.whitelabelDetailsForm.get('phone')?.valueChanges?.pipe(
      filter(() => !this.whitelabel),
      filter(v => v),
      distinctUntilChanged(),
      map(value => formatPhone(value)),
      takeUntil(this.destroyed$)
    ).subscribe(phone => this.whitelabelDetailsForm.get('phone')?.setValue(phone, {emitEvent: false}));
  }

  ngOnInit(): void {
    this.whitelabel = this.route.snapshot.data?.whiteLabel;
    this.initForms();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private buildSmtpConfig(whitelabel: any) {
    if (this.smtpByNodeMailer) {
      const {user, password, host, port} = this.whitelabelEmailConfigForm.value;
      const nodemailerUri = `smtps://${user}:${password}@${host}:${port}`;
      whitelabel.emailjs = null;
      whitelabel.nodemailerUri = nodemailerUri;
    } else {
      whitelabel.emailjs = this.whitelabelEmailConfigForm.value;
      whitelabel.nodemailerUri = '';
    }
  }

  private buildWhitelabel(): FormGroup {
    this.whitelabelDetailsForm.get('welcomeMessage')?.disable();
    const details = {...this.whitelabelDetailsForm.controls};
    const packages = {...this.whitelabelStoreDetailsForm.controls};
    const cssVariables = this.whitelabelStylesForm;
    const integrationKeys = {...this.whitelabelIntegrationKeysForm.controls};
    const emailjs = this.whitelabelEmailConfigForm;
    return new FormGroup({
      ...details,
      ...packages,
      ...integrationKeys,
      cssVariables,
      emailjs
    })
  }

  private dirtyForms(): void {
    Object.values(this.whitelabelDetailsForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({onlySelf: true});
      }
    });
    Object.values(this.whitelabelStoreDetailsForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({onlySelf: true});
      }
    });
    Object.values(this.whitelabelStylesForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({onlySelf: true});
      }
    });
    Object.values(this.whitelabelIntegrationKeysForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({onlySelf: true});
      }
    });
    Object.values(this.whitelabelEmailConfigForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({onlySelf: true});
      }
    });
  }

  public save(): void {
    this.status = this.STATUS.LOADING;
    const whitelabel = this.buildWhitelabel();
    if (whitelabel.valid) {
      let newWhitelabel = whitelabel.value;
      this.buildSmtpConfig(newWhitelabel);
      newWhitelabel = {
        ...newWhitelabel,
        textReplacements: this.generateTextReplacements(),
        filesReplacements: this.generateFileReplacements(),
        ...this.generateResourceReplacements()
      }
      let subscription: Observable<any>;
      if (this.whitelabel) {
        const whitelabelId: string = this.whitelabel._id as string;
        subscription = this.whitelabelService.update(whitelabelId, newWhitelabel);
      } else {
        subscription = this.whitelabelService.create(newWhitelabel);
      }
      subscription.pipe(timeout(10000)).subscribe(() => {
        this.status = this.STATUS.SUCCESS;
        this.notificationService.create('success', 'WhiteLabel', 'Salvo com sucesso!');
        this.router.navigate(['home', 'whitelabels']);
      }, () => {
        this.status = this.STATUS.ERROR;
        this.notificationService.create('error', 'Erro', 'Não foi possível cadastrar!');
      })
    } else {
      this.dirtyForms();
      this.notificationService.create(
        'error',
        'Campos inválidos',
        'Existem campos inválidos!'
      );
      this.status = this.STATUS.ERROR;
    }
  }

  public onIndexChange(event: number): void {
    this.index = event;
  }

  public copyLink(value: string = '') {
    this.clipboard.copy(value);
    this.notificationService.success('Link copiada!', '');
  }

  public handleCertificateUpload({file}: NzUploadChangeParam): void {
    this.oneSignalSetup.get('certificateFile')?.setValue(file.originFileObj);
  }

  public async handleOneSignalConfig(): Promise<void> {
    this.oneSignalSetupStatus = this.STATUS.LOADING;
    if (this.whitelabelDetailsForm.invalid || this.oneSignalSetup.invalid) {
      this.notificationService.error(
        'Ooops...',
        'Existem valores inválidos',
        {nzDuration: 2000}
      ).onClick.subscribe(noop)
      this.oneSignalSetupStatus = this.STATUS.ERROR;
      return Promise.resolve();
    }
    const formValues: { certificateId: string, certificateFile: File, firebaseKey: string, firebaseId: string } = {
      ...this.oneSignalSetup.value
    }
    const name = this.whitelabelDetailsForm.get('companyName')?.value;
    const apns_p12: any = await getBase64FromFile(formValues.certificateFile)
    const data: OneSignalApp = {
      name, apns_p12,
      apns_p12_password: formValues.certificateId,
      android_gcm_sender_id: formValues.firebaseId,
      gcm_key: formValues.firebaseKey,
      organization_id: environment.ONE_SIGNAL_WHITELABEL_ORG_ID
    }
    this.oneSignalService.createApp(data).subscribe(({id, authKey}) => {
      const oneSignalAppId = id
      const oneSignalRestApiKey = authKey
      const oneSignalGoogleSenderId = formValues.firebaseId
      this.whitelabelIntegrationKeysForm.setValue({oneSignalAppId, oneSignalRestApiKey, oneSignalGoogleSenderId});
      this.whitelabelIntegrationKeysForm.markAsDirty();
      this.oneSignalSetupStatus = this.STATUS.SUCCESS;
      this.notificationService.success(
        'App criado',
        'O aplicativo foi criado no OneSignal',
        {nzDuration: 2000}
      ).onClick.subscribe(noop)
    }, error => {
      this.oneSignalSetupStatus = this.STATUS.ERROR;
      this.notificationService.error(
        'App não criado',
        'Não foi possível criar o aplicativo',
        {nzDuration: 2000}
      ).onClick.subscribe(noop)
    })
  }

  private generateFileReplacements(): { from: string, to: string }[] {
    const whiteLabelName = (this.whitelabelDetailsForm.get('name') as FormControl).value;
    const whiteLabelS3Url = `https://whitelabel.econdos.com.br/${whiteLabelName}/`
    return Object.keys(awsS3Paths).map(key => {
      const from = key;
      const s3Path = awsS3Paths[key];
      const to = `${whiteLabelS3Url}${s3Path}`;
      return {from, to};
    })
  }

  private generateTextReplacements(): { files: string[], from: string, to: string }[] {
    const integrationKeysConfig = Object.keys(this.whitelabelIntegrationKeysForm.controls).reduce((acc, curr) => {
      acc[curr] = this.whitelabelIntegrationKeysForm.controls[curr].value;
      return acc;
    }, {} as any);

    const oneSignalReplacement = {
      files: ['src/app/app.component.ts'],
      from: 'this.oneSignal.startInit(\'26c111f3-a93c-48fb-a572-06567cf9ae92\', \'298024303648\')',
      to: `this.oneSignal.startInit('${integrationKeysConfig.oneSignalAppId}', '${integrationKeysConfig.oneSignalGoogleSenderId}')`
    }

    const mobilePrimaryColor = (this.whitelabelStylesForm.get('--primary') as FormControl).value
    const mobilePrimaryColorVariableReplacement = {
      files: ['src/theme/variables.scss'],
      from: '#f8c336',
      to: `${mobilePrimaryColor}`
    }
    const mobilePrimaryColorStatusBarReplacement = {
      files: ['src/pages/dashboard/dashboard.ts'],
      from: 'this.statusBar.backgroundColorByHexString(\'#eab62a\');',
      to: `this.statusBar.backgroundColorByHexString(\'${mobilePrimaryColor}\');`
    }

    const whiteLabelName = (this.whitelabelDetailsForm.get('companyName') as FormControl).value
    const appNameReplacement = {
      files: ['config.xml'],
      from: '<name>eCondos</name>',
      to: `<name>${whiteLabelName}</name>`
    }

    const appPackageName = (this.whitelabelStoreDetailsForm.get('googlePackageName') as FormControl).value
    const appPackageNameReplacement = {
      files: ['config.xml'],
      from: 'id="br.com.econdos.app"',
      to: `id="${appPackageName}"`
    }

    const supportEmail = (this.whitelabelDetailsForm.get('emailSupport') as FormControl).value
    const appSupportEmailReplacement = {
      files: ['src/pages/about/about.html'],
      from: '<a href="mailto:suporte@econdos.com.br">suporte@econdos.com.br</a>',
      to: `<a href="mailto:${supportEmail}">${supportEmail}</a>`
    }

    const businessEmail = (this.whitelabelDetailsForm.get('emailFrom') as FormControl).value
    const appBusinessEmailReplacement = {
      files: ['src/pages/about/about.html'],
      from: '<a href="mailto:contato@econdos.com.br">contato@econdos.com.br</a>',
      to: `<a href="mailto:${businessEmail}">${businessEmail}</a>`
    }

    const appAboutPagePackageNameReplacement = {
      files: ['src/pages/about/about.ts'],
      from: 'br.com.econdos.app',
      to: appPackageName
    }

    const welcomeMessage = (this.whitelabelDetailsForm.get('welcomeMessage') as FormControl).value
    const appAboutPageReplacement = {
      files: ['src/pages/welcome/welcome.html'],
      from: 'Seja bem vindo ao eCondos!',
      to: welcomeMessage
    }

    const oneSignalConstantReplacement = {
      files: ['src/app/app.component.ts'],
      from: '$ONE_SIGNAL_APP_ID',
      to: integrationKeysConfig.oneSignalAppId
    }

    return [
      oneSignalReplacement,
      mobilePrimaryColorVariableReplacement,
      mobilePrimaryColorStatusBarReplacement,
      appNameReplacement,
      appPackageNameReplacement,
      appSupportEmailReplacement,
      appBusinessEmailReplacement,
      appAboutPagePackageNameReplacement,
      appAboutPageReplacement,
      oneSignalConstantReplacement
    ]
  }

  private generateResourceReplacements(): { icon: string, splash: string, logo: string, banner: string, favIcon: string } {
    const whiteLabelName = (this.whitelabelDetailsForm.get('name') as FormControl).value;
    const whiteLabelS3Url = `https://whitelabel.econdos.com.br/${whiteLabelName}/`
    return {
      icon: `${whiteLabelS3Url}icon.png`,
      splash: `${whiteLabelS3Url}splash.png`,
      logo: `${whiteLabelS3Url}logo.png`,
      banner: `${whiteLabelS3Url}logo.png`,
      favIcon: `${whiteLabelS3Url}favIcon.png`
    }
  }
}
