import {AbstractControl, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

export const beforeDateValidator = (dateToCompareFieldName: string): ValidatorFn => {
  return (control: AbstractControl) => {
    const dateToCompare = control.root.get(dateToCompareFieldName);

    if (!dateToCompare) {
      return null;
    }

    const controlDate = moment(control.value).startOf('day');
    const compareDate = moment(dateToCompare.value).startOf('day');

    return controlDate.isBefore(compareDate) ? { isBeforeDate: true } : null;
  };
};
