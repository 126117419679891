import {Injectable} from '@angular/core';
import {Condo} from '../model/condo';
import {User} from '../model/user';
import {map} from 'rxjs/operators';
import {CondoUser} from '../model/condo-user';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {EcondosQuery} from '@api/model/query';
import * as qs from 'qs';
import {Residence} from '@api/model/residence';

@Injectable({ providedIn: 'root' })
export class CondoService {

  private endPoint = `${environment.backendUrl}condos/`;

  constructor(private http: HttpClient) {
  }

  get(query: EcondosQuery = {}) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get<Condo[]>(this.endPoint, { params });
  }

  getCondosAsSystemAdmin(query: EcondosQuery = {}) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get<any>(this.endPoint + '7ed0dd5c5c7e11eabc550242ac130003', { params, observe: 'response' }).pipe(
      map(({ body: data, headers }) => ({
          data,
          count: headers.get('count')
        })
      ));
  }

  getCondosWithCount(query: EcondosQuery = {}) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get<any>(this.endPoint + '7ed0dd5c5c7e11eabc550242ac130003', { params, observe: 'response' })
      .pipe(map(({ body: data, headers }) => ({
          data,
          count: headers.get('count')
        })
      ));
  }

  createCondo(condo: any) {
    return this.http.post(this.endPoint, condo);
  }

  deleteCondo(condoId: string) {
    return this.http.delete(this.endPoint + condoId);
  }

  getCondoById(condoId: string | number, query?: EcondosQuery) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get<Condo>(this.endPoint + condoId, { params });
  }

  updateCondo(condoId: string, condo: any) {
    return this.http.put(this.endPoint + condoId, condo);
  }

  subscribeCondo(condoId: string, subscribptionData: CondoSubscription) {
    return this.http.put(this.endPoint + condoId + '/subscribe', subscribptionData);
  }

  subscribeCondoWithSystemAdmin(condoId: string, subscribptionData: CondoSubscription) {
    return this.http.put(this.endPoint + condoId + '/subscribe/systemAdmin', subscribptionData);
  }

  getSubscriptionPrices(condoId: string): Observable<{ minimumPrice: number, defaultPrice: number }> {
    return this.http.get(this.endPoint + condoId + '/prices') as any;
  }

  getCondoUsers(condoId: string, params: EcondosQuery = {}): Observable<{ count: number, users: CondoUser[] }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(params) });
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}${condoId}/users`, options).pipe(
      map((res: any) => ({
          count: res.headers.get('count'),
          users: res.body
        })
      )
    );
  }

  getNumberOfUsersFromCondo(condoId: string): Observable<any> {
    return this.http.get(this.endPoint + condoId + '/users' + '?$count=true').pipe(map((n: any) => n[0]));
  }

  getCondoUserById(condoId: string, userId: string, queryString ?: string): Observable<User> {
    return this.http.get(this.endPoint + condoId + '/users/' + userId + (queryString || '')).pipe(
      map((users: any) => users ? users.length ? users[0] : users : null)
    );
  }

  addUserToCondo(condoId: string, user: any) {
    return this.http.post(`${this.endPoint}${condoId}/users/`, user);
  }

  removeUserFromCondo(condoId: string, userId: string) {
    return this.http.delete(`${this.endPoint}${condoId}/users/${userId}`);
  }

  updateUserFromCondo(condoId: string, userId: string, user: any) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}`, user);
  }

  addGatekeeperToCondo(condoId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}/gatekeeper`, '');
  }

  removeGatekeeperFromCondo(condoId: string, userId: string) {
    return this.http.delete(this.endPoint + condoId + '/users/' + userId + '/gatekeeper');
  }

  addJanitorToCondo(condoId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}/janitor`, '');
  }

  removeJanitorFromCondo(condoId: string, userId: string) {
    return this.http.delete(this.endPoint + condoId + '/users/' + userId + '/janitor');
  }

  addCondoUser(condoId: string, userId: string) {
    return this.http.put(`${this.endPoint}${condoId}/users/${userId}`, '');
  }

  approveUserOnCondo(condoId: string, userId: string) {
    return this.http.put(this.endPoint + condoId + '/users/' + userId + '/approve', '');
  }

  removeAdminFromCondo(condoId: string, userId: string) {
    return this.http.delete(this.endPoint + condoId + '/users/' + userId + '/admins');
  }

  addAdminToCondo(condoId: string, userId: string) {
    return this.http.put(this.endPoint + condoId + '/users/' + userId + '/admins', {});
  }

  changeCondoOwner(condoId: string, userId: string) {
    return this.http.put(this.endPoint + condoId + '/users/' + userId + '/owners', {});
  }

  getResidencesFromUser(condoId: string, userId: string, queryString?: string): Observable<Residence[]> {
    return this.http.get<Residence[]>(`${this.endPoint}${condoId}/users/${userId}/residences${(queryString || '')}`)
  }

  importUsersToCondo(condoId: string, formData: FormData) {
    return this.http.post(`${this.endPoint}${condoId}/import/`, formData);
  }

  updateCondoPlan(condoId: string, condo: {
    daysOfTrial: number,
    plan: {
      name: string,
      features: Array<string>
    }
  }) {
    return this.http.put(this.endPoint + condoId + '/plan', condo);
  }

  updateHardwareConfig(condoId: string, hardware: { licence: string, enabled: boolean }) {
    return this.http.put(this.endPoint + condoId + '/hardware', hardware);
  }

  notifyCondo(condoId: string, notification: CondoNotification) {
    return this.http.post(this.endPoint + condoId + '/notify', notification)
  }

  getDeletedCondos(query: EcondosQuery = {}) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get(this.endPoint + 'deleted', { params, observe: 'response' })
      .pipe(map(({ body: data, headers }) => ({
        data,
        count: headers.get('count')
      })))
  }

  restoreCondo(condoId: string) {
    return this.http.put(this.endPoint + condoId + '/restore', {});
  }

}

export interface CondoNotification {
  title: string;
  message: string;
  url: string;
  type: string;
}

export interface CondoSubscription {
  coupon?: string;
  name: string;
  numberOfResidences: number;
  document: { type: string, number: string, pictures?: any[] };
  phone: string;
  email: string;
  zipcode: number;
  address: string;
}
