<form nz-form [formGroup]="form" (ngSubmit)="submitForm()">
  <div nz-col nzOffset="6"><p>E-mail atual: <strong>{{user.email}}</strong></p></div>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">E-mail</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid E-mail!">
      <input nz-input formControlName="email" id="email"/>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item nz-row class="register-area">
    <nz-form-control [nzSpan]="14" [nzOffset]="6">
      <button nzSize="large" nz-button nzType="primary">Alterar email</button>
    </nz-form-control>
  </nz-form-item>
</form>
