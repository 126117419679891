<nz-page-header>
  <nz-breadcrumb *ngIf="whiteLabel" nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="../../..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="../..">
        <i nz-icon nzType="shop"></i>
        Whitelabels
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="..">
        <img [src]="whiteLabel?.favIcon" style="width: 20px" alt="ícone de {{whiteLabel?.companyName}}">
        {{whiteLabel?.companyName}}
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon [nzType]="useTerms ? 'edit' : 'plus'"></i>
      {{useTerms ? 'Editar' : 'Criar'}} Termos de Uso e Privacidade
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title>Gerenciar Termos de Uso e Política de Privacidade
    do {{whiteLabel ? 'Whitelabel' : 'eCondos'}}</nz-page-header-title>
  <nz-page-header-extra>
    <button
      nz-button
      *ngIf="useTerms"
      nzType="primary"
      [nzDanger]="useTerms.enabled"
      [nzLoading]="status === STATUS.LOADING" [disabled]="status === STATUS.LOADING"
      (click)="toggleUseTermsStatus()"
    >
      {{useTerms.enabled ? 'Desabilitar' : 'Habilitar'}}
      <i nz-icon nzType="check"></i>
    </button>
    <button
      nz-button nzType="primary" (click)="save()"
      [nzLoading]="status === STATUS.LOADING" [disabled]="status === STATUS.LOADING">
      Salvar <i nz-icon nzType="check"></i>
    </button>
  </nz-page-header-extra>
</nz-page-header>

<div class="p-3">
  <form nz-form [formGroup]="form" nzLayout="vertical">
    <nz-form-item *ngIf="useTerms">
      <nz-form-label nzSpan="8" nzRequired nzFor="updateType">Tipo de Atualização</nz-form-label>
      <nz-form-control nzSpan="8" nzErrorTip="Selecione um tipo regra de versionamento para a atualização">
        <nz-select formControlName="updateType" id="updateType">
          <nz-option nzValue="patch" nzLabel="Patch"></nz-option>
          <nz-option nzValue="minor" nzLabel="Minor"></nz-option>
          <nz-option nzValue="major" nzLabel="Major"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired nzFor="useTermsText">Termos de Uso</nz-form-label>
      <nz-form-control nzErrorTip="É necessário informa um dado ao campo">
        <editor
          id="useTermsText"
          [apiKey]="apiKey"
          formControlName="useTermsText"
          [init]="tinymceBaseConfig"
        ></editor>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired nzFor="privacyPolicyText">Política de Privacidade</nz-form-label>
      <nz-form-control nzErrorTip="É necessário informa um dado ao campo">
        <editor
          id="privacyPolicyText"
          [apiKey]="apiKey"
          formControlName="privacyPolicyText"
          [init]="tinymceBaseConfig">
        </editor>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
