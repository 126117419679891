<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="shop"></i>
      Informações financeiras
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <nz-page-header-title>
    Informações financeiras
  </nz-page-header-title>

  <nz-page-header-subtitle>
    {{ totalData || 0 }} resultados
  </nz-page-header-subtitle>

</nz-page-header>
<div class="px-4 mb-2 control-toolbar">
  <div class="mr-2 mt-2">
    <app-search-condo
      (filter)="handleCondoFilter($event)"
      (close)="closeCondoFilter()">
    </app-search-condo>
  </div>
  <div class="mr-2 mt-2">
    <app-search-whitelabel
    (onFilter)="handleWhitelabelFilter($event)"
    (onClose)="closeWhitelabelFilter()">
    </app-search-whitelabel>
  </div>
  <div class="mr-2 mt-2">
    <app-search-dropdown
      [dropdownOptions]="planOptions"
      (onFilter)="handleSearchPlanFilter($event)"
      (onClose)="closeSearchPlanFilter()">
    </app-search-dropdown>
  </div>
  <div class="mr-2 mt-2">
    <app-search-dropdown
      [dropdownOptions]="typeOptions"
      label = "Filtrar por tipo"
      (onFilter)="handleSearchTypeFilter($event)"
      (onClose)="closeSearchTypeFilter()">
    </app-search-dropdown>
  </div>
  <nz-select
    style="align-self: end"
    nzPlaceHolder="Mostrar apenas"
    [(ngModel)]="showDeactivated"
    (ngModelChange)="getData()">
    <nz-option  *ngFor="let option of [{label: 'Ativados', value: false}, {label: 'Desativados', value: true}]"
                [nzValue]="option.value"
                [nzLabel]="option.label"
                >
      {{option.label}}
    </nz-option>
  </nz-select>
</div>
<div class="px-4 mb-2" style="display: flex" [style.justify-content]="selectedRowCount > 0 ? 'space-between': 'flex-end'">
  <nz-button-group *ngIf="selectedRowCount > 0">
    <button nz-button nzType="primary" (click)="openReadjustModal()">
      Reajustar valores selecionados
    </button>
    <button nz-button nzType="primary"  *ngIf="!showDeactivated" (click)="toggleSelectedRow(false)">
      Desativar selecionados
    </button>
    <button nz-button nzType="primary"  *ngIf="showDeactivated" (click)="toggleSelectedRow(true)">
      Ativar selecionados
    </button>
  </nz-button-group>

  <nz-button-group>
    <button nz-button (click)="exportAsCsv(fields, tableData)">
      <i nz-icon nzType="file-excel" nzTheme="outline"></i>
      Excel
    </button>
    <button nz-button (click)="exportAsPDF(fields, tableData)">
      <i nz-icon nzType="file-pdf" nzTheme="outline"></i>
      PDF
    </button>
  </nz-button-group>
</div>

<div class="px-4">
  <nz-table #condosTable [nzData]="tableData" [nzFrontPagination]=false>
    <thead>
      <tr>
        <th>
          Selecionar
        </th>
        <th
          (click)="getSortedData('whiteLabel.name')"
          nz-tooltip
          nzTooltipTitle="Clique para ordenar"
        >
          WhiteLabel
          <span nz-icon nzType="info-circle" nz-tooltip="Esse WhiteLabel não tem relação com o cadastrado no condomínio e sim com o cadastrado junto a este cadastro financeiro" nzTheme="outline"></span>
          <i nz-icon nzType="{{currentSortData.field ==='whiteLabel.name' ? sortOrderIcon[currentSortData.order] : 'sort-descending'}}" nzTheme="outline"></i>
        </th>
        <th
          (click)="getSortedData('condo.name')"
          nz-tooltip
          nzTooltipTitle="Clique para ordenar"
        >
          Condomínio
          <i nz-icon nzType="{{currentSortData.field ==='condo.name' ? sortOrderIcon[currentSortData.order] : 'sort-descending'}}" nzTheme="outline"></i>
        </th>
        <th
          (click)="getSortedData('condo.createdAt')"
          nz-tooltip
          nzTooltipTitle="Clique para ordenar"
        >
          Data de criação
          <i nz-icon nzType="{{currentSortData.field ==='condo.createdAt' ? sortOrderIcon[currentSortData.order] : 'sort-descending'}}" nzTheme="outline"></i>
        </th>
        <th>Unidades</th>
        <th
          (click)="getSortedData('condo.plan.name')"
          nz-tooltip
          nzTooltipTitle="Clique para ordenar"
        >
          Plano
          <i nz-icon nzType="{{currentSortData.field ==='condo.plan.name' ? sortOrderIcon[currentSortData.order] : 'sort-descending'}}" nzTheme="outline"></i>
        </th>
        <th
          (click)="getSortedData('condo.type')"
          nz-tooltip
          nzTooltipTitle="Clique para ordenar"
        >
          Tipo
          <i nz-icon nzType="{{currentSortData.field ==='condo.type' ? sortOrderIcon[currentSortData.order] : 'sort-descending'}}" nzTheme="outline"></i>
        </th>
        <th>Dias de Trial</th>
        <th>
          Término do Trial
        </th>
        <th>Preço</th>
        <th>Data do Último Reajuste</th>
        <th>% Último Reajuste</th>
        <th>Dias para o Próximo Reajuste</th>
        <th
        (click)="getSortedData('nextAdjustmentDate')"
        nz-tooltip
        nzTooltipTitle="Clique para ordenar"
      >
        Data do Próximo Reajuste
        <i nz-icon nzType="{{currentSortData.field ==='nextAdjustmentDate' ? sortOrderIcon[currentSortData.order] : 'sort-descending'}}" nzTheme="outline"></i>
      </th>
        <th>% do Próximo Reajuste</th>
        <th>Índice de Reajuste</th>
        <th>Observações</th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let condosFincancialInfo of condosTable.data; let i = index;">
        <td>
          <label
            nz-checkbox
            [(ngModel)]="selectedRow[condosFincancialInfo._id]"
            (ngModelChange)="countSelectedRows()"
            type="checkbox"
            data-toggle="toggle"
          >
          </label>
        </td>
        <td>
          {{ condosFincancialInfo?.whiteLabel?.name || 'Não Possui' }}
        </td>
        <td>
          {{ condosFincancialInfo?.condo?.name }}
        </td>
        <td>
          {{ condosFincancialInfo?.condo?.createdAt | date:'dd/MM/yyyy'  || 'Não informada' }}
        </td>
        <td>
          {{ condosFincancialInfo.residencesCount }}
        </td>
        <td>
          {{ condosFincancialInfo?.condo?.plan?.name ? PLANS_LABELS[condosFincancialInfo.condo.plan.name] : 'Não informada' }}
        </td>
        <td>
          {{ condosFincancialInfo?.condo?.type ? TYPES_LABELS[condosFincancialInfo.condo.type] : 'Não informada'}}
        </td>
        <td>
          {{ condosFincancialInfo?.condo?.daysOfTrial || 0 }}
        </td>
        <td>
          {{ condosFincancialInfo?.endDateOfTrial }}
        </td>
        <td>
          {{ condosFincancialInfo?.price }}
        </td>
        <td>
          {{ condosFincancialInfo?.lastAdjustmentDate }}
        </td>
        <td>
          {{ condosFincancialInfo?.lastAdjustmentPercentage }}
        </td>
        <td>
          {{ condosFincancialInfo?.nextAdjustment }}
        </td>
        <td>
          {{ condosFincancialInfo?.nextAdjustmentDate }}
        </td>
        <td>
          {{ condosFincancialInfo?.nextAdjustmentPercentage }}
        </td>
        <td>
          {{ condosFincancialInfo?.adjustmentIndex }}
        </td>
        <td>
          {{ condosFincancialInfo?.obs }}
        </td>
        <td>
          <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary">
            Ações
            <i nz-icon nzType="down"></i>
          </button>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item [routerLink]="[condosFincancialInfo._id, 'edit']">
                <a>Editar</a>
              </li>
              <li nz-menu-item (click)="deleteCondosFincancialInfo(condosFincancialInfo)">
                <a>Deletar</a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <nz-pagination
    nz-row
    nzJustify="end"
    nzShowSizeChanger
    [nzTotal]="totalData"
    [nzPageIndex]="0"
    [nzPageSize]="nzPageSize"
    [nzPageSizeOptions]="[50, 100, 250, 500]"
    (nzPageIndexChange)="refreshIndexChange($event)"
    (nzPageSizeChange)="refreshSizeChange($event)"
  >
  </nz-pagination>
</div>


