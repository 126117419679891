<nz-page-header>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a [routerLink]="condoFinancialInfo ? '../../..' : '../..'">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="condoFinancialInfo ? '../..' : '..'">
        <i nz-icon nzType="shop"></i>
        Informações financeiras
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon [nzType]="condoFinancialInfo ? 'edit' : 'plus'"></i>
      {{condoFinancialInfo ? 'Editar' : 'Criar'}} cadastro financeiro
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title>
    <i nz-icon nzType="shop"></i> {{condoFinancialInfo ? 'Editar' : 'Criar'}} cadastro financeiro
  </nz-page-header-title>
  <nz-page-header-extra>
    <button nz-button nzType="primary" [nzLoading]="status === 'LOADING'" (click)="save()"
            [disabled]="status === 'LOADING'">
      Salvar <i nz-icon nzType="check"></i>
    </button>
  </nz-page-header-extra>
</nz-page-header>

<div nz-row class="p-3">
  <div nz-col nzSpan="24">
      <form nz-form [formGroup]="financialForm">
        <div nz-row [nzGutter]="24" class="mt-3">
          <div nz-col [nzSpan]="6">
            <nz-form-label nzTooltipTitle="Esse whiteLabel não tem relação com o cadastrado no condomínio">WhiteLabel
            </nz-form-label>
            <nz-form-item>
              <nz-select nzShowSearch
                         nzAllowClear
                         nzPlaceHolder="Selecione o whitelabel"
                         formControlName="whiteLabel"
                         (nzOnSearch)="onSearchWhiteLabel($event)">
                <nz-option  *ngFor="let option of whiteLabelOptions"
                            [nzValue]="option._id"
                            [nzLabel]="option.name"
                            nzCustomContent>
                  <nz-avatar nzShape="square" [nzSrc]="option.icon" [nzText]="option.companyName" [nzAlt]="option.name"></nz-avatar>
                  <span>{{ option.name }}</span>
                </nz-option>
              </nz-select>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-label>Condomínio</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Condomínio é obrigatório" nzHasFeedback>
                <nz-select *ngIf="!condoFinancialInfo; else condoInput"
                           nzShowSearch
                           nzAllowClear
                           nzPlaceHolder="Selecione o condomínio"
                           formControlName="condo"
                           (nzOnSearch)="onSearchCondo($event)">
                  <nz-option  *ngFor="let option of condoOptions"
                              [nzValue]="option._id"
                              [nzLabel]="option.name"
                              nzCustomContent>
                    <nz-avatar nzShape="square" [nzSrc]="option.pictures[0]?.url || '/assets/img/default-condo-picture.png'" [nzText]="option.name" [nzAlt]="option.name"></nz-avatar>
                    <span>{{ option.name }}</span>
                  </nz-option>
                </nz-select>
              </nz-form-control>
              <ng-template #condoInput>
                <input type="text" class="form-input" [value]="selectedCondo?.name" readonly>
              </ng-template>
            </nz-form-item>
          </div>
          <ng-container  *ngIf="selectedCondo?._id">
          <div nz-col [nzSpan]="6">
            <nz-form-label>Data de Criação</nz-form-label>
            <nz-form-item>
              <input type="text" class="form-input" [value]="createdAt" readonly>
            </nz-form-item>
          </div>
            <div nz-col [nzSpan]="6">
              <nz-form-label>Plano</nz-form-label>
              <nz-form-item>
                <nz-select
                           nzAllowClear
                           nzPlaceHolder="Selecione o Plano"
                            formControlName="planName">
                  <nz-option  *ngFor="let option of PLANS_LABELS"
                              [nzValue]="option.value"
                              [nzLabel]="option.label"
                              >
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-label>Tipo</nz-form-label>
              <nz-form-item>
                <nz-select
                  nzAllowClear
                  nzPlaceHolder="Selecione o tipo"
                  formControlName="type">
                  <nz-option  *ngFor="let option of TYPES_LABELS"
                              [nzValue]="option.value"
                              [nzLabel]="option.label"
                              >
                  </nz-option>
                </nz-select>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-label>Dias de Trial</nz-form-label>
              <nz-form-item>
                <nz-form-control nzErrorTip="Número deve ser maior que 0" nzHasFeedback>
                  <input type="number" min="0" class="form-input" formControlName="daysOfTrial">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-label>Término do Trial</nz-form-label>
              <nz-form-item>
                <input type="text" class="form-input" [value]="endDateOfTrial" readonly>
              </nz-form-item>
            </div>
          <div nz-col [nzSpan]="6">
            <nz-form-label>Unidades</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-control nzErrorTip="Número deve ser maior que 0" nzHasFeedback>
                  <nz-input-number
                    class="form-input"
                    [nzStep]="1"
                    formControlName="residencesCount"
                    nzPlaceHolder="0"
                    [nzMin]="0"> </nz-input-number>
                </nz-form-control>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-label>Preço</nz-form-label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Número deve ser maior que 0" nzHasFeedback>
                  <nz-input-number
                    class="form-input"
                    [nzStep]="1"
                    formControlName="price"
                    [nzFormatter]="currencyFormatter"
                    [nzParser]="parseCurrency"
                    [nzMin]="0"
                    nzPlaceHolder="R$ 0,00"> </nz-input-number>
                </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-label>Data do Último Reajuste</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-date-picker class="form-input" nzFormat="dd/MM/YYYY"  formControlName="lastAdjustmentDate" ></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="6">
            <nz-form-label>% do Último Reajuste</nz-form-label>
            <nz-form-item>
                <nz-form-control nzErrorTip="Número deve ser maior que 0" nzHasFeedback>
                  <nz-input-number
                    class="form-input"
                    [nzStep]="1"
                    formControlName="lastAdjustmentPercentage"
                    [nzFormatter]="formatPercent"
                    [nzParser]="parsePercent"
                    nzPlaceHolder="0%"
                    [nzMin]="0"> </nz-input-number>
              </nz-form-control>
            </nz-form-item>
          </div>
            <div nz-col [nzSpan]="6">
              <nz-form-label>Data do Próximo Reajuste</nz-form-label>
              <nz-form-item>
                <nz-form-control nzHasFeedback nzErrorTip="Data deve ser maior que hoje">
                  <nz-date-picker class="form-input" nzFormat="dd/MM/YYYY"  formControlName="nextAdjustmentDate" ></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-label>% do Próximo de Reajuste</nz-form-label>
              <nz-form-item>
                  <nz-form-control nzHasFeedback nzErrorTip="Número deve ser maior que 0">
                    <nz-input-number
                    class="form-input"
                    [nzStep]="1"
                    formControlName="nextAdjustmentPercentage"
                    [nzFormatter]="formatPercent"
                    [nzParser]="parsePercent"
                    nzPlaceHolder="0%"
                    [nzMin]="0"> </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
            <nz-form-label>Indíce de Reajuste</nz-form-label>
            <nz-form-item>
              <nz-form-control nzHasFeedback>
                <nz-form-control>
                <input type='text' formControlName="adjustmentIndex" class="form-input">
                </nz-form-control>
              </nz-form-control>
            </nz-form-item>
          </div>
            <div nz-col [nzSpan]="12">
              <nz-form-label>Observações</nz-form-label>
              <nz-form-item>
                <nz-form-control nzHasFeedback>
                  <textarea nz-input [nzAutosize]="true" formControlName="obs" placeholder="Digite aqui..."></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>
        </div>
      </form>
  </div>
</div>

