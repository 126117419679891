import { Component } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { startOfDay } from 'date-fns';
import { CondoFinancialInfoService } from '@api/service/condo-financial-info.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { formatPercent, parsePercent } from '@api/utils';
import { conditionalValidator } from '@api/util/validators';
import { beforeTodayValidator } from '../../validators/before-today.validator';

@Component({
  selector: 'app-modal-readjust-price',
  templateUrl: 'modal-readjust-price.component.html',
  styleUrls: ['./modal-readjust-price.component.scss'],
})
export class ModalReadjustPriceComponent {
  condoFinancialInfoToReadjust: string[] = [];
  onAfterSafe: (() => void) | null = null;

  loadingStatus = false;
  readonly formatPercent = formatPercent;
  readonly parsePercent = parsePercent;

  public readjustForm: FormGroup;

  constructor(
    private notificationService: NzNotificationService,
    private condoFinancialInfoService: CondoFinancialInfoService,
    private modalService: NzModalService,
    private formBuilder: FormBuilder,
  ) {
    this.readjustForm = this.formBuilder.group({
      readjustPercentage: new FormControl(
        0,
        Validators.compose([
          conditionalValidator(
            () => !this.readjustForm.get('nextReadjustmentDate')?.value,
            Validators.compose([Validators.required, Validators.min(1)])!,
          ),
          conditionalValidator(
            () => this.readjustForm.get('nextReadjustmentDate')?.value,
            Validators.min(0),
          ),
        ]),
      ),
      nextReadjustmentDate: new FormControl('', beforeTodayValidator()),
    });

    this.readjustForm
      .get('nextReadjustmentDate')!
      .valueChanges.subscribe(() => {
        this.readjustForm.get('readjustPercentage')?.updateValueAndValidity();
      });
  }

  onSubmit() {
    if (this.readjustForm.valid) {
      const { nextReadjustmentDate, readjustPercentage } =
        this.readjustForm.value;
      let nextDate = '';
      if (nextReadjustmentDate) {
        nextDate = startOfDay(nextReadjustmentDate).toISOString();
      }
      this.loadingStatus = true;
      this.condoFinancialInfoService
        .readjust(
          this.condoFinancialInfoToReadjust,
          readjustPercentage,
          nextDate,
        )
        .subscribe({
          next: () => {
            this.notificationService.create(
              'success',
              'Sucesso',
              'Cadastros reajustados com sucesso',
            );

            if (this.onAfterSafe) {
              this.onAfterSafe();
            }
            this.loadingStatus = false;
            this.closeModal();
          },
          error: (err) => {
            this.notificationService.create(
              'error',
              'Erro',
              err?.error?.message || 'Não foi possível reajustar os cadastros!',
            );
            this.loadingStatus = false;
          },
        });
    } else {
      this.readjustForm.get('readjustPercentage')?.updateValueAndValidity();
      this.readjustForm.get('nextReadjustmentDate')?.updateValueAndValidity();
    }
  }

  closeModal() {
    this.modalService.closeAll();
  }
}
