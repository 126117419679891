<div *ngIf="resetPasswordStatus === ''">
  <p>Gostaria realmente de resetar a senha do usuário {{ user?.firstName }} {{ user?.lastName }} ?</p>
  <div>
    <button nzSize="large" nz-button nzType="primary" (click)="loadOne()" (click)="resetUserPassword()" [nzLoading]="isLoadingOne">
      Resetar Senha
    </button>
  </div>
</div>

<div *ngIf="resetPasswordStatus === 'success'">
  <nz-result nzStatus="success" nzTitle="Nova senha: {{ this.newPassword }}" nzSubTitle="Nova senha já está disponivel para uso."> </nz-result>
</div>

<div *ngIf="resetPasswordStatus === 'error'">
  <nz-result nzStatus="error" nzTitle="Erro ao resetar senha." nzSubTitle="Tente novamente."> </nz-result>
</div>
