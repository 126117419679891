import { Component } from "@angular/core";

@Component({
  selector: 'app-features-page',
  templateUrl: './features-page.component.html'
})
export class FeaturesPageComponent {
  items = [
    { icon: 'star', title: 'Destaques', route: '/home/features/highlights' },
  ]
}
