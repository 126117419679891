import { CondoService } from '@api/service/condo.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formatCnpj, formatCpf } from '@api/util/formatters';
import { formatCep, formatPhone, isValidCNPJ, isValidCPF } from '@api/utils';
import { debounceTime, distinctUntilChanged, timeout } from 'rxjs/operators';
import { AddressService } from '../../service/address.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-modal-create-financial-responsible',
  templateUrl: './modal-create-financial-responsible.component.html',
  styleUrls: ['./modal-create-financial-responsible.component.scss'],
})
export class ModalCreateFinancialResponsible implements OnInit {
    @Input() condo: any;

  form: FormGroup;
  hasError: boolean = false;
  totalValue: string = "";
  document = {number: "", type: ""};

  constructor(
    private fb: FormBuilder,
    private addressService: AddressService,
    private condoService: CondoService,
    private notification: NzNotificationService)
    {

    let cpfCnpjValidator = (c: FormControl) => {
      if (c.value.length > 14) {
        return c.value && isValidCNPJ(c.value) ? null : {isValidCpfCnpj: {valid: false}};
      } else {
        return c.value && isValidCPF(c.value) ? null : {isValidCpfCnpj: {valid: false}};
      }
    };

    this.form = this.fb.group({
      name: new FormControl(null, [Validators.required]),
      cpfCnpj:  ["",([Validators.required, cpfCnpjValidator])],
      document:  [],
      phone: new FormControl(null, [Validators.required, Validators.maxLength(15), Validators.minLength(15)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      zipcode: new FormControl(null, [Validators.required, Validators.maxLength(9), Validators.minLength(9)]),
      address: new FormControl(null, [Validators.required]),
      number: new FormControl(null),
      state: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      neighborhood: new FormControl(null, [Validators.required]),
      complement: new FormControl(null),
      implantation: new FormControl(null),
      numberOfResidences: new FormControl(null),
      contractStartDate: new FormControl(null, [Validators.required]),
      unitValue: new FormControl(null),
      integrations: new FormControl(null),
      whiteLabelValue: new FormControl(null),
      whiteLabelMaintenance: new FormControl(null),
      otherValue: new FormControl(null),
      discount: new FormControl(null),
      totalValue:[],
      comments: new FormControl(null)
    });

    this.form.valueChanges.subscribe(() => {
      this.totalValueSum();
    })

    this.form.get("cpfCnpj")?.valueChanges.subscribe(v => {
      v = (v || '').replace(/\D/g, '');
      if (v.length > 11) {
        v = formatCnpj(v);
      } else {
        v = formatCpf(v);
      }
      this.form.get("cpfCnpj")?.setValue(v, {emitEvent: false});
    });

    this.form.get("phone")?.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(phone => {
      if (!phone) {
        return;
      }
      const val = phone.trim();
      this.form.get("phone")?.setValue(formatPhone(phone), {emitEvent: false});
    });

    this.form.get("zipcode")?.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe((zipcode: string) => {
      if (!zipcode) {
        return;
      }
      const val = zipcode.trim();
      this.searchzipcode(val);
      this.form.get("zipcode")?.setValue(formatCep(zipcode), {emitEvent: false});
    });

    this.timeout()
  }

  timeout(){
    setTimeout(() => {
      if(this.condo.sale){
        this.editForm()
      }
    }, 0)
  }

  ngOnInit(): void {}

  searchzipcode(code: string) {
    if (code.length < 9 || code.length > 9) {
      return;
    }
    this.addressService.getAddress(code.replace('-', '')).subscribe(
    (data: any) => {
      if (!("erro" in data)) {
        this.form.get("address")?.setValue((data.logradouro) || '');
        this.form.get("city")?.setValue(data.localidade || '');
        this.form.get("state")?.setValue(data.uf || '');
        this.form.get("neighborhood")?.setValue(data.bairro || '');
      } else {
        this.form.get("address")?.setValue('');
        this.form.get("city")?.setValue('');
        this.form.get("state")?.setValue('');
        this.form.get("neighborhood")?.setValue('');
        this.hasError = true
      }
      this.form.get("address")?.markAsTouched();
      this.form.get("city")?.markAsTouched();
      this.form.get("state")?.markAsTouched();
      this.form.get("neighborhood")?.markAsTouched();
    },
    err => {
      this.form.get("address")?.setValue('');
      this.form.get("city")?.setValue('');
      this.form.get("state")?.setValue('');
      this.form.get("neighborhood")?.setValue('');
      this.hasError = true
    })
  }

  submitForm(): void {
    if(this.form.valid){
      this.totalValueSum()
      this.form.get("totalValue")?.setValue(this.totalValue);
      if(this.form.get("cpfCnpj")?.value.length === 14){
        this.document.number = this.form.get("cpfCnpj")?.value.replace(/\D/g, '');
        this.document.type = "CPF";
      }else{
        this.document.number = this.form.get("cpfCnpj")?.value.replace(/\D/g, '');
        this.document.type = "CNPJ";
      }
      this.form.get("document")?.setValue(this.document);
      this.condoService.subscribeCondoWithSystemAdmin(this.condo._id, this.form.value).subscribe(res => {
        this.notification.create('success', 'Responsavel financeiro', "Cadastrado com sucesso" );
      },
      err => {
        this.notification.create('error', 'Responsavel financeiro', "Não Cadastrado" );
        console.log(err)
      })
    }else{
      for (const key in this.form.controls) {
        this.form.controls[key].markAsDirty();
        this.form.controls[key].updateValueAndValidity();
      }
    }
  }

  totalValueSum(){
    this.totalValue = (
    Number(this.form.get("implantation")?.value) +
    Number(this.form.get("integrations")?.value) +
    Number(this.form.get("whiteLabelValue")?.value) +
    Number(this.form.get("whiteLabelMaintenance")?.value) +
    (Number(this.form.get("unitValue")?.value) *
    Number(this.form.get("numberOfResidences")?.value)) +
    Number(this.form.get("otherValue")?.value) -
    Number(this.form.get("discount")?.value)).toFixed(2)
  }

  editForm() {
    this.form.get("name")?.setValue(this.condo.sale?.name);
    this.form.get("cpfCnpj")?.setValue(this.condo.sale.document?.number);
    this.form.get("phone")?.setValue(this.condo.sale?.phone);
    this.form.get("email")?.setValue(this.condo.sale?.email);
    this.form.get("zipcode")?.setValue(this.condo.sale?.zipcode);
    this.form.get("number")?.setValue(this.condo.sale?.number);
    this.form.get("complement")?.setValue(this.condo.sale?.complement);
    this.form.get("implantation")?.setValue(this.condo.sale?.implantation);
    this.form.get("numberOfResidences")?.setValue(this.condo.sale?.numberOfResidences);
    this.form.get("unitValue")?.setValue(this.condo.sale?.unitValue);
    this.form.get("integrations")?.setValue(this.condo.sale?.integrations);
    this.form.get("whiteLabelValue")?.setValue(this.condo.sale?.whiteLabelValue);
    this.form.get("whiteLabelMaintenance")?.setValue(this.condo.sale?.whiteLabelMaintenance);
    this.form.get("otherValue")?.setValue(this.condo.sale?.otherValue);
    this.form.get("discount")?.setValue(this.condo.sale?.discount);
    this.form.get("comments")?.setValue(this.condo.sale?.comments);
    this.form.get("contractStartDate")?.setValue(this.condo.sale?.contractStartDate);
  }
}
