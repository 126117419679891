import * as moment from "moment";
import { Condo } from "./condo";
import { Ad } from "./advertiser";
import { Partner } from "./partner";

export interface Marketplace {
  _id?: string | null;
  name: string;
  enabled: boolean;
  blocked: boolean;
  blockedReason: string;
  startDate: Date;
  endDate: Date | null;
  typeCondo: TYPE_CONDO;
  condos: any[];
  whitelabels: any[];
  partners: string[] | Partner[];
  advertisers: string[] | Ad[];
}


export enum TYPE_CONDO {
  ALL = 'ALL',
  HOUSE = 'HOUSE',
  APARTMENT = 'APARTMENT',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
}

export enum TYPE_CONDO_LABEL {
  ALL = 'Todos',
  HOUSE = 'Casa',
  APARTMENT = 'Apartamento',
  BUSINESS = 'Comercial',
  OTHER = 'Outro',
}

export const BuildMarketplace = (marketplace: Partial<Marketplace>): Marketplace => {
  return {
    _id: marketplace._id || null,
    name: marketplace.name || '',
    enabled: marketplace.enabled || false,
    blocked: marketplace.blocked || false,
    blockedReason: marketplace.blockedReason || '',
    startDate: marketplace.startDate || moment().toDate(),
    endDate: marketplace.endDate || null,
    typeCondo: marketplace.typeCondo || TYPE_CONDO.ALL,
    condos: marketplace.condos?.map(condo => condo._id) || [],
    whitelabels: marketplace.whitelabels?.map(whitelabel => whitelabel._id) || [],
    partners: marketplace.partners || [],
    advertisers: marketplace.advertisers || [],
  };
}
