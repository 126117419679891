<nz-input-group
  nzSearch
  [nzAddOnAfter]="suffixIconButton">
    <nz-select
      [(ngModel)]="searchValue"
      style="width: 155px"
      [disabled]="filtering"
      [nzPlaceHolder]="label"
    >
    <nz-option *ngFor="let option of dropdownOptions" [nzValue]="option.value" [nzLabel]="option.label"> </nz-option>
  </nz-select>
</nz-input-group>
<ng-template #suffixIconButton>
  <button *ngIf="!filtering"
    nz-button
    nzType="primary"
    nzSearch
    [disabled]="!searchValue"
    (click)="handleOnFilter()"
  >
    <i nz-icon nzType="search"></i>
  </button>
  <button *ngIf="filtering" nz-button nzDanger (click)="handleOnClose()">
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </button>
</ng-template>
