import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Condo} from '@api/model/condo';
import {catchError, tap} from 'rxjs/operators';
import {merge, noop, of} from 'rxjs';
import {generateCsv, generateReport} from '../../../util/report-generator';
import {EcondosQuery} from '@api/model/query';
import {CondoService} from '@api/service/condo.service';
import {SessionService} from '../../../service/session.service';
import {ResidenceService} from '@api/service/residences.service';
import {Component, OnInit} from '@angular/core';

interface TableField {
  value: string,
  label: string
}

@Component({
  selector: 'app-condos-page',
  templateUrl: './condos-page.component.html',
  styleUrls: ['./condos-page.component.scss']
})
export class CondosPageComponent implements OnInit {
  condos: any = [];
  nzPageSize: number;
  nzPageIndex: number;
  totalData: any;
  fields: TableField[];

  private generalSearchParameter: string = '';
  private generalSearchValue: string = '';
  private searchWhitelabelValue: string = '';
  private searchPlanValue: string = '';
  currentSortData: { field: string, order: 'asc' | 'desc' | '' } = { field: 'name', order: 'asc' };

  generalSearchOptions: { label: string, value: string }[];
  planOptions: { label: string, value: string }[] = [];
  showDeleted = false;

  constructor(
    private condoService: CondoService,
    private sessionService: SessionService,
    private residenceService: ResidenceService,
    private notification: NzNotificationService
  ) {
    this.nzPageSize = 50;
    this.nzPageIndex = 0;
    this.fields = [
      { value: 'name', label: 'Nome' },
      { value: 'address', label: 'Localização' },
      { value: 'whiteLabel', label: 'WhiteLabel' },
      { value: 'cnpj', label: 'CNPJ' },
      { value: '_id', label: 'ID' },
      { value: 'createdAt', label: 'Data de Criação' },
      { value: 'residences', label: 'Unidades' },
      { value: 'plan', label: 'Plano' }
    ];
    this.generalSearchOptions = [
      {
        value: 'name',
        label: 'Nome'
      },
      {
        value: 'id',
        label: 'Id'
      },
      {
        value: 'cnpj',
        label: 'CNPJ'
      }
    ];
    this.planOptions = [
      { value: 'FREE', label: 'FREE / TRIAL' },
      { value: 'PREMIUM', label: 'PREMIUM' }
    ]
  }

  ngOnInit(): void {
    this.getData(this.currentSortData.field);
  }

  getData(sortBy?: string) {
    const query: EcondosQuery = {
      $select: 'name cnpj createdAt plan address city state daysOfTrial whiteLabel',
      $populate: [{ path: 'whiteLabel', select: 'name' }],
      $limit: this.nzPageSize,
      $page: this.nzPageIndex
    }

    if (sortBy) {
      if (this.currentSortData.field === sortBy) {
        this.currentSortData.order === 'asc' ? this.currentSortData.order = 'desc' : this.currentSortData.order = 'asc';
      } else {
        this.currentSortData.field = sortBy;
        this.currentSortData.order = 'asc';
      }
    } else {
      this.currentSortData = { field: 'createdAt', order: 'desc' }
    }

    if (this.generalSearchParameter === 'name') {
      query.name = { $regex: this.generalSearchValue, $options: 'i' };
    }
    if (this.generalSearchParameter === 'cnpj') {
      query.cnpj = Number(this.generalSearchValue);
    }
    if (this.generalSearchParameter === 'id') {
      query._id = this.generalSearchValue;
    }

    if (this.searchPlanValue) {
      query['plan.name'] = this.searchPlanValue;
    }
    if (this.searchWhitelabelValue) {
      query['whiteLabel'] = this.searchWhitelabelValue;
    }

    query.$sort = `${this.currentSortData.order === 'desc' ? '-' : ''}${this.currentSortData.field}`;

    let request
    if (this.showDeleted) {
      query.$select = query.$select + ' deletedBy deletedAt'
      query.$populate?.push({
        path: 'deletedBy',
        select: 'firstName lastName'
      })
      request = this.condoService.getDeletedCondos(query)
    } else {
      request = this.condoService.getCondosWithCount(query)
    }

    request.subscribe(({ data: condos, count }) => {
      this.condos = condos;
      this.totalData = Number(count)
    }, err => {
      console.log(err);
    })
  }

  refreshIndexChange(value: any) {
    this.nzPageIndex = --value
    this.getData()
  }

  refreshSizeChange(value: any) {
    this.nzPageSize = value
    this.getData()
  }

  exportAsPDF(fields: TableField[], condos: Condo[]) {
    const data = this.handleData(condos);
    generateReport('Lista_De_Condominios', fields, data, this.sessionService.user);
  }

  exportAsCsv(fields: TableField[], condos: Condo[]) {
    const data = this.handleData(condos);
    const encodeUri = generateCsv(fields, data);
    const link = document.createElement('a');
    link.setAttribute('href', encodeUri);
    link.setAttribute('download', `Lista_De_Condominios.csv`);
    link.click();
  }

  handleData(condos: Condo[]) {
    return condos.map((condo) => (
      {
        ...condo,
        createdAt: new Date(condo.createdAt).toLocaleDateString(),
        plan: condo.plan?.name || '',
        whiteLabel: condo?.whiteLabel?.name || 'Nenhum'
      }
    ));
  }

  handleGeneralSearchFilter(data: any) {
    const { value, parameter } = data;
    this.generalSearchValue = value;
    this.generalSearchParameter = parameter;
    this.getData();
  }

  closeGeneralSearchFilter() {
    this.generalSearchValue = '';
    this.generalSearchParameter = '';
    this.getData();
  }

  handleSearchPlanFilter(value: string) {
    this.searchPlanValue = value;
    this.getData();
  }

  closeSearchPlanFilter() {
    this.searchPlanValue = '';
    this.getData();
  }

  handleWhitelabelFilter(value: string) {
    this.searchWhitelabelValue = value;
    this.getData()
  }

  closeWhitelabelFilter() {
    this.searchWhitelabelValue = '';
    this.getData();
  }

  compareDates(a: Condo, b: Condo) {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }

  getSortedData(field: string) {
    this.currentSortData.field = field;
    this.getData(field);
  }

  countResidencesData(condoId: string) {
    this.residenceService.getResidencesCount(condoId).subscribe(count => {
      let condo: any = this.condos.find((condo: any) => condo._id === condoId)
      if (condo) {
        condo.residences = count || 0
      }
    }, err => {
      console.log(err);
    })
  }

  countAllCondosResidences(condos: Condo[]) {
    const requests = condos.map((condo: any) =>
      this.residenceService.getResidencesCount(condo._id).pipe(
        tap((count) => condo.residences = count || 0),
        catchError(e => of(null))
      )
    )
    merge(...requests, 3).subscribe(noop, noop)
  }

  toggleShowDeleted() {
    this.showDeleted = !this.showDeleted
    this.getData()
  }

  restoreCondo(condoId: string, index: number) {
    this.condoService.restoreCondo(condoId).subscribe({
      next: () => {
        this.condos.splice(index, 1)
        this.condos = [...this.condos]
        this.notification.success('Sucesso', 'Condomínio restaurado com sucesso')
      },
      error: (err) => {
        this.notification.error('Erro', 'Não foi possível restaurar o condomínio')
        console.log(err)
      }
    })
  }
}
