import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WhiteLabel} from "@api/model/white-label";
import {CondoService} from "@api/service/condo.service";
import {Condo} from "@api/model/condo";
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { Subject } from 'rxjs';
import { ResidenceService } from '@api/service/residences.service';

interface CondoWithResidences extends Condo{
  residences: number;
}

@Component({
  selector: 'app-white-label-details-page',
  templateUrl: './white-label-details-page.component.html',
  styleUrls: ['./white-label-details-page.component.scss']
})
export class WhiteLabelDetailsPageComponent implements OnInit, OnDestroy {

  whiteLabel: WhiteLabel;
  condos: CondoWithResidences[] = [];
  searchByName = new FormControl('');
  unsubscribe: Subject<boolean> = new Subject()
  nzPageIndex: number;
  nzPageSize: number;
  totalCondos: number;
  totalResidences: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private condoService: CondoService,
    private residenceService: ResidenceService,
  ) {
    this.nzPageIndex = 0;
    this.nzPageSize = 10;
    this.whiteLabel = route.snapshot.data.whiteLabel;
    this.condos = route.snapshot.data.condos.data || [];
    this.totalCondos = Number(route.snapshot.data.condos.count) || 0;
    this.searchByName.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap( name => {
        const query: EcondosQuery = {
          $select:'name cnpj createdAt plan address city state ',
          name:{$regex:name, $options:'i'},
          whiteLabel:this.whiteLabel._id,
        }
        return this.condoService.getCondosAsSystemAdmin(query)
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(values => { this.condos = values.data })
  }

  ngOnInit(): void {
  }
    getData(condoId: string){
    this.residenceService.getResidencesCount(condoId).subscribe(count => {
      let condo = this.condos.find(condo => condo._id === condoId )
      if(condo){
        condo.residences = count
      }
    }, err => {
      console.log(err);
    })
  }

  getTotalResidences() {
    const query: EcondosQuery = {
      $count: true
    }
    query.condo = {$in: this.condos.map((condo) => condo._id)}
    
    this.residenceService.getResidencesCountAllCondos(query).subscribe(count => {
      this.totalResidences = count;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete()
  }

  getDataPagination() {
    const query: EcondosQuery = this.route.snapshot.data.condos.query;
    query.$page = this.nzPageIndex;
    query.$limit = this.nzPageSize;

    this.condoService.getCondosAsSystemAdmin(query).subscribe(({data: condos}) => {this.condos = condos});
  }

  refreshSizeChange(value: number): void {
    this.nzPageSize = value;
    this.getDataPagination();
  }

  refreshIndexChange(value: number) {
    this.nzPageIndex = --value;
    this.getDataPagination();
  }

}
