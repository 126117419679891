import {Injectable} from "@angular/core";
import {UseTermsService} from "@api/service/use-terms.service";
import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {Observable} from "rxjs";
import {UseTerms} from "@api/model/use-terms";
import {EcondosQuery} from "@api/model/query";
import {map, tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class EcondosUseTermsResolver implements Resolve<any> {
  constructor(private _router: Router,
              private useTermsService: UseTermsService) {
  }
  resolve(route: ActivatedRouteSnapshot): Observable<UseTerms> {
    const query: EcondosQuery = {
      $select: 'useTermsText privacyPolicyText version createdBy createdAt updateAt updatedBy enabled',
      isEcondos: true,
    }
    return this.useTermsService.get(query).pipe(map(terms => terms[0]));
  }

}
