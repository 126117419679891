<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="shop"></i>
      Whitelabels
    </nz-breadcrumb-item>
  </nz-breadcrumb>
    <nz-page-header-title>Whitelabels</nz-page-header-title>
  <nz-page-header-extra>
    <button nz-button nzType="primary" routerLink="create">
      <i nz-icon nzType="plus"></i> Criar whitelabel
    </button>
  </nz-page-header-extra>
</nz-page-header>

<div class="px-4">
  <nz-row class="mb-3" [nzGutter]="5">
    <nz-col nzSpan="12">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input [formControl]="searchByName" type="text" nz-input placeholder="Pesquise por nome do whitelabel" />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </nz-col>

    <nz-col nzSpan="12">
      <nz-select style="width: 100%;" nzMode="tags" nzPlaceHolder="Clique para selecionar os status" [nzMaxTagCount]="3" [formControl]="searchByStatus">
        <nz-option *ngFor="let status of listOfStatus" [nzLabel]="status.label" [nzValue]="status.value"></nz-option>
      </nz-select>
    </nz-col>

  </nz-row>
  <nz-table #whiteLabelsTable [nzData]="whiteLabels" [nzFrontPagination]="false">
    <thead>
    <tr>
      <th>Empresa</th>
      <th>Status</th>
      <th>Observações</th>
      <th>Link</th>
      <th>Ações</th>
      <th>Instaladores</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of whiteLabelsTable.nzData">
      <td>
        <img [src]="data.icon" style="max-width: 50px;">
        {{ data.companyName }}
      </td>
      <td>
        {{statusKeys[data?.status!]}}<br>
      </td>
      <td>
        {{data?.obs || 'Não informado'}}
      </td>
      <td>
        <a [href]="'https://' + data.webAppUrl" target="_blank">
          {{data.webAppUrl}}
        </a>
      </td>
      <td>
        <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzType="primary">
          Ações
          <i nz-icon nzType="down"></i>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>
              <a [routerLink]="[data._id]">Detalhes</a>
            </li>
            <li nz-menu-item>
              <a [routerLink]="[data._id, 'edit']">Editar</a>
            </li>
            <li nz-menu-item>
              <a [routerLink]="[data._id, 'use-terms']">
                Termos de Uso
              </a>
            </li>
            <li nz-menu-item>
              <a (click)="whitelabelIdToUpdate = data?._id!; updateWhiteLabelStatusModalIsVisible = !updateWhiteLabelStatusModalIsVisible">Atualizar status</a>
            </li>
            <li nz-menu-item>
              <a (click)="whitelabelIdToUpdate = data?._id!; sendTestEmailIsModalIsVisible = !sendTestEmailIsModalIsVisible">Testar e-mail</a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
      <td>
        <span *ngIf="!data?.x86Installer && !data?.x64Installer">Não configurado</span>
        <button *ngIf="data?.x86Installer" nz-button nzType="primary" (click)="copyLink(data?.x86Installer)" style="margin-right: 10px;">x86</button>
        <button *ngIf="data?.x64Installer" nz-button nzType="primary" (click)="copyLink(data?.x64Installer)">x64</button>
      </td>
    </tr>
    </tbody>
  </nz-table>
  <nz-pagination
    nz-row
    nzJustify="end"
    nzShowSizeChanger
    [nzTotal]="totalData"
    [nzPageIndex]="0"
    [nzPageSize]="nzPageSize"
    [nzPageSizeOptions]="[50, 100, 250, 500]"
    (nzPageIndexChange)="refreshIndexChange($event)"
    (nzPageSizeChange)="refreshSizeChange($event)"
  >
  </nz-pagination>
</div>

<nz-modal [(nzVisible)]="sendTestEmailIsModalIsVisible" nzTitle="Enviar teste de e-mail" (nzOnCancel)="handleSendTestEmailModalCancel()" (nzOnOk)="handleSendTestEmailModalOk()">
  <ng-container *nzModalContent>
    <nz-form-label>E-mail</nz-form-label>
    <input nz-input placeholder="ula@ula.com" [(ngModel)]="emailSendToTest" [ngModelOptions]="{standalone: true}" />
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="updateWhiteLabelStatusModalIsVisible" nzTitle="Alterar status" (nzOnCancel)="handleUpdateStatusModalCancel()" (nzOnOk)="handleUpdateStatusModalOk()">
  <ng-container *nzModalContent>
    <nz-select class="mb-2" style="width: 100%;" [(ngModel)]="newStatus">
      <nz-option nzValue="" nzLabel="Selecione um status  "></nz-option>
      <nz-option nzValue="NEW" nzLabel="Novo"></nz-option>
      <nz-option nzValue="AWAITING_RESOURCES" nzLabel="Aguardando recursos"></nz-option>
      <nz-option nzValue="IN_REVIEW" nzLabel="Em revisão"></nz-option>
      <nz-option nzValue="DONE" nzLabel="Criado"></nz-option>
    </nz-select>
    <textarea style="width: 100%;" rows="4" nz-input placeholder="Descreva alguma observação. Exemplo: Está faltando a empresa enviar o logo" [(ngModel)]="newObs"></textarea>
  </ng-container>
</nz-modal>
