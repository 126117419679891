<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-control nzHasFeedback>
      <nz-form-label>Título da notificação</nz-form-label>
      <input nz-input placeholder="Título..." formControlName="title">
    </nz-form-control>

  </nz-form-item>

  <nz-form-item>
    <nz-form-control nzHasFeedback>
      <nz-form-label>Mensagem</nz-form-label>
      <textarea nz-input placeholder="Mensagem" formControlName="message"></textarea>
    </nz-form-control>

  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzHasFeedback>
      <nz-form-label>Tipo da notificação</nz-form-label>
      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Selecione o tipo" formControlName="type">
        <nz-option nzLabel="Abertura de link" [nzValue]="'OPEN-URL'"></nz-option>
      </nz-select>
    </nz-form-control>

  </nz-form-item>

  <nz-form-item>
    <nz-form-control nzHasFeedback>
      <nz-form-label>Link</nz-form-label>
      <input nz-input placeholder="Link aqui" formControlName="url">
    </nz-form-control>

  </nz-form-item>

  <div style="display: flex; justify-content: flex-end; gap: 8px">
    <button nz-button nzType="primary" type="button" nzDanger (click)="closeModal()">Cancelar</button>
    <button nz-button nzType="primary" type="button" (click)="submit(form)">Enviar</button>
  </div>
</form>
