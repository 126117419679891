import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../api/model/user';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class SessionService {
  constructor() {

  }

  // @ts-ignore
  private _user: User;
  private setUser: BehaviorSubject<User> = new BehaviorSubject<User>(null as any);
  public userChangedEmmitter: Observable<User> = this.setUser.asObservable();
  public authorizationHeader: string = '';
  private _token: string = '';

  loadSessionFromLocalData() {
    const storedUser = localStorage.getItem('user');
    this.user = storedUser ? JSON.parse(storedUser) : null;
    return this.user;
  }

  saveSession(user: User) {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  clearSession() {
    localStorage.removeItem('user');
    this.user = null as any;
  }

  get user(): User {
    return this._user;
  }

  set user(value: User) {
    this._user = value;
    this.setUser.next(value);
  }
}
