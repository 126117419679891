import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {isCommercialSystemAdmin, User} from '@api/model/user';
import {SessionService} from "../service/session.service";

@Injectable({providedIn: 'root'})
export class CommercialSystemAdminGuard implements CanActivate {

  constructor(private sessionService: SessionService,
              private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    const user: User = this.sessionService.loadSessionFromLocalData();
    if (isCommercialSystemAdmin(user)) {
      return true;
    } else {
      this.router.navigate(['/no-permission-error']);
      return false;
    }

  }

}
