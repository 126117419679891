<nz-input-group
      nzSearch
      [nzAddOnBefore]="addOnBeforeTemplate"
      [nzAddOnAfter]="suffixIconButton">
      <input type="text"
             nz-input
             [(ngModel)]="searchValue"
             style="width: 150px"
             [disabled]="filtering" />
</nz-input-group>
<ng-template #addOnBeforeTemplate>
  <nz-select [(ngModel)]="searchParameter"
              [disabled]="filtering"
              nzPlaceHolder="Filtros"
            >
    <nz-option
      *ngFor="let option of dropdownOptions"
      [nzLabel]="option.label"
      [nzValue]="option.value">
    </nz-option>
  </nz-select>
</ng-template>
<ng-template #suffixIconButton>
  <button *ngIf="!filtering"
  nz-button
  nzType="primary"
  nzSearch
  (click)="handleOnFilter()"
  [disabled]="!searchParameter || searchValue.length < 3">
    <i nz-icon nzType="search"></i>
  </button>
  <button *ngIf="filtering" nz-button nzDanger (click)="handleOnClose()">
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </button>
</ng-template>
