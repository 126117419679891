import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environment';
import { EcondosQuery } from '@api/model/query';
import * as qs from 'qs';
import { Log } from '@api/model/log';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LogsService {
  private endPoint = `${environment.backendUrl}logs/`;

  constructor(private http: HttpClient) {}

  public get(query: EcondosQuery) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    return this.http.get<Log[]>(this.endPoint, { params });
  }

  public getHardwareLogs(query: EcondosQuery) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get<any>(`${this.endPoint}hardwares`, options).pipe(
      map(({ body: data, headers }) => {
        return {
          hardwareLogs: data,
          count: headers.get('count')
        };
      })
    );
  }

  public getHardwareErrorLogs(query: EcondosQuery) {
    const params = new HttpParams({ fromString: qs.stringify(query) });
    const options = {
      headers: new HttpHeaders(),
      params,
      observe: 'response' as 'body'
    };
    return this.http.get<any>(`${this.endPoint}hardware-errors`, options).pipe(
      map(({ body: data, headers }) => {
        return {
          hardwareLogs: data,
          count: headers.get('count')
        };
      })
    );
  }

  public getHardwareLogsCollectionList() {
    return this.http.get<string[]>(`${this.endPoint}hardwares/collectionList`);
  }
}
