import { PartnerService } from 'src/app/service/partner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, MinLengthValidator } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Partner, BuildPartner } from '@api/model/partner';
import { EcondosQuery } from '@api/model/query';
import * as moment from 'moment';


@Component({
  selector: 'app-partners-details',
  templateUrl: './partners-details.component.html',
  styleUrls: ['./partners-details.component.scss'],
})

export class PartnersComponent implements OnInit {
  validateForm!: FormGroup;
  loading = false;

  apiKey = '3kthv82id3e9ftbf6me2u6t8opgnpzm1ls2ljd107bdjs17z'
  tinymceBaseConfig = {
    menubar: false,
    branding: false,
    statusbar: false,
    language: 'pt_BR',
    height: '560',
    plugins: 'fullscreen template table hr',
    toolbar: 'undo redo | fontselect fontsizeselect formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor removeformat | fullscreen ',
  }
  STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  }
  status: number | null = null;

  constructor(private partnerService: PartnerService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder,
              private notification: NzNotificationService) {
  }

  ngOnInit(): void {
    const partnerId = this.activatedRoute.snapshot.params?.partnerId;
    this.initForm();
    if (partnerId) {
      this.loadPartner(partnerId);
    }
    
  }

  private initForm(): void {
    this.validateForm = this.fb.group({
      _id: [],
      name: [, [Validators.required]],
      url: [],
      logo: [],
      tags: this.fb.array([this.createTagControl()]),
      emails: this.fb.array([this.createEmailControl()]),
      phones: this.fb.array([this.createPhoneControl()]),
      description: [],
      order: [99],
      enabled: [true],
      enabledInWhitelabel: [true],
      whitelabels: this.fb.array([]),
      actionUrl: [],
      actionType: [],
      dataToSent: [],
      headerParams: this.fb.array([]),
      startDate: [],
      endDate: [],
      redirectViaCallback: [false],
      urlCallback: [],
      defaultTermsOfUse: [false],
      termsOfUseUrl: [],
      termsOfUseText: []
    });
  }

  private loadPartner(partnerId: string): void {
    const query: EcondosQuery = {
      $select: '_id name url logo tags description emails headerParams phones order enabled enabledInWhitelabel whitelabels actionUrl actionType dataToSent redirectViaCallback startDate endDate urlCallback defaultTermsOfUse termsOfUseUrl termsOfUseText',
    };
    this.partnerService.getPartnerById(partnerId, query).subscribe({
      next: (partner) => {
        if (partner) {
          this.validateForm.patchValue({ ...partner });

          // Atualizando os arrays do formulário
          this.validateForm.setControl(
            'tags',
            this.fb.array(partner.tags.map(tag => this.createTagControl(tag)))
          );
          this.validateForm.setControl(
            'emails',
            this.fb.array(partner.emails.map(email => this.createEmailControl(email)))
          );
          this.validateForm.setControl(
            'phones',
            this.fb.array(partner.phones.map(phone => this.createPhoneControl(phone)))
          );
          this.validateForm.setControl(
            'headerParams',
            this.fb.array(partner.headerParams.map(headerParams => this.createHeaderParamsControl(headerParams)))
          );
        }
      },
      error: () => {
        this.createNotification('error', 'Falha no processo', 'Houve um erro ao tentar carregar o parceiro!');
      }
    });
  }

  get tags(): FormArray {
    return this.validateForm.get('tags') as FormArray;
  }

  get emails(): FormArray {
    return this.validateForm.get('emails') as FormArray;
  }

  get phones(): FormArray {
    return this.validateForm.get('phones') as FormArray;
  }

  get headerParams(): FormArray {
    return this.validateForm.get('headerParams') as FormArray;
  }

  private createTagControl(value: string = ''): FormControl {
    return new FormControl(value, Validators.required);
  }

  private createEmailControl(value: string = ''): FormControl {
    return new FormControl(value, [Validators.required, Validators.email]);
  }

  private createPhoneControl(value: string = ''): FormControl {
    return new FormControl(value, [Validators.required, Validators.minLength(11), Validators.maxLength(15)]);
  }

  private createHeaderParamsControl(value: string = ''): FormControl {
    return new FormControl(value);
  }

  addTag(): void {
    (this.validateForm.get('tags') as FormArray).push(this.createTagControl());
  }

  addEmail(): void {
    (this.validateForm.get('emails') as FormArray).push(this.createEmailControl());
  }

  addPhone(): void {
    (this.validateForm.get('phones') as FormArray).push(this.createPhoneControl());
  }

  addHeaderParams(): void {
    (this.validateForm.get('headerParams') as FormArray).push(this.createHeaderParamsControl());
  }

  cancel(): void {
    this.router.navigate(['/home/partners']);
  }

  createNotification(type: string, title: string, message: string): void {
    this.notification.create(type, title, message);
  }

  submitForm(): void {
    if (this.validateForm.invalid) {
      this.validateForm.markAllAsTouched();
      return;
    }

    this.loading = true;

    const formValue: Partner = BuildPartner(this.validateForm.value);
    delete formValue.marketplace;
    formValue.startDate = moment(formValue.startDate).startOf('day').toDate();
    formValue.endDate = moment(formValue.endDate).endOf('day').toDate();
    if(!formValue._id) {
      delete formValue._id;
      this._createPartner(formValue);
    } else {
      this._updatePartner(formValue);
    }
  }

  _createPartner(partnerData: Partner): void {
    this.partnerService.createPartner(partnerData).subscribe({
      next: () => {
        this.createNotification('success', "Parceiro Criado", "Novo parceiro criado com sucesso!");
        this.router.navigate(['/home/partners']);
      },
      error: () => {
        this.createNotification('error', 'Falha no processo', 'Houve um erro ao tentar criar o parceiro!');
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  _updatePartner(partnerData: Partner): void {
    const partnerId = partnerData._id as string;
    this.partnerService.updatePartner(partnerId, partnerData).subscribe({
      next: () => {
        this.createNotification('success', "Parceiro Atualizado", "Parceiro atualizado com sucesso!");
        this.router.navigate(['/home/partners']);
      },
      error: () => {
        this.createNotification('error', 'Falha no processo', 'Houve um erro ao tentar atualizar o parceiro!');
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
}