// @ts-ignore
import * as pdfMake from 'pdfmake/build/pdfmake';
// @ts-ignore
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

export let generateReport = (title: any, fields: Array<{ label: string, value: string }>, data: any, user: any, params: {
  orientation: 'LANDSCAPE' | 'PORTRAIT'
} = {orientation: 'PORTRAIT'}) => {

  const content: any = [
    {text: title, style: 'header'},
  ];

  const tableHeader = [{text: '#', style: 'itemsTableHeader'}];
  for (const field of fields) {
    tableHeader.push({text: field.label, style: 'itemsTableHeader'})
  }

  const items: any[] = [];

  data.forEach((d: any, index: number) => {
    const line = [{text: index + 1, alignment: 'right'}];
    for (const field of fields) {
      line.push(d[field.value] || '');
    }
    items.push(line);
  });

  content.push('\n');
  content.push({
    style: 'itemsTable',
    table: {
      body: [tableHeader].concat(items)
    }
  });

  const now = new Date();

  const localeDate = `${now.toLocaleDateString()} ás ${now.toLocaleTimeString()}`;

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const pdf = pdfMake.createPdf({
    content: content,
    footer: {
      text: `Gerado através do eCondos web, por ${user.firstName} ${user.lastName} em ${localeDate}.`,
      alignment: 'center'
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 5]
      },
      itemsTable: {
        margin: [0, 5, 0, 0]
      },
      itemsTableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black'
      }
    },
    defaultStyle: {
      fontSize: 9
    },
    pageSize: 'A4',
    pageMargins: [20, 20, 20, 30],
  });
  if (params && params.orientation) {
    pdf.docDefinition.pageOrientation = params.orientation.toLowerCase();
  }
  pdf.download(`${title}.pdf`);
};

export const generateCsv = (fields: Array<{ label: string, value: string }>, data: any) => {
  let csvContent = fields.map(f => f.label).join(';');
  csvContent += '\n';

  data.forEach((item: any) => {
    const line = [];
    for (const field of fields) {
      line.push(item[field.value]);
    }
    csvContent += line.join(';') + '\n';
  });

  const encodedUri = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
  return encodedUri;
};
