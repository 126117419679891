import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MarketplaceService } from '@api/service/marketplace.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Marketplace, BuildMarketplace, TYPE_CONDO, TYPE_CONDO_LABEL } from '@api/model/marketplace';
import { EcondosQuery } from '@api/model/query';
import { WhiteLabel } from '@api/model/white-label';
import { WhiteLabelService } from '@api/service/white-label.service';
import { CondoService } from '@api/service/condo.service';
import { Condo } from '@api/model/condo';
import { STATUS, STATES, STATES_LABEL, CATEGORIES, CATEGORIES_LABEL, BuildAd, Ad } from '@api/model/advertiser';
import { BuildPartner, Partner } from '@api/model/partner';
import { PartnerService } from 'src/app/service/partner.service';
import { AdvertiserService } from '@api/service/advertiser.service';
import { NzModalService } from 'ng-zorro-antd/modal';
// import utils
import { formatPhone } from '@api/util/formatters';
import { FileService } from '@api/service/file.service';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import * as moment from 'moment';

interface Option {
  value: string;
  label: string;
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

@Component({
  selector: 'app-modal-edit-marketplace',
  templateUrl: './modal-edit-marketplace.component.html',
  styleUrls: ['./modal-edit-marketplace.component.scss'],
})
export class ModalEditMarketplaceComponent implements OnInit {
  @Input() marketplace: Marketplace = {} as Marketplace;

  marketplaceForm: FormGroup;
  partnerForm: FormGroup;
  advertiserForm: FormGroup;

  menuItems: Array<{ icon: string, title: string, step: string, needEditMode?: boolean }> = [];

  whitelabels: WhiteLabel[] = [];
  condos: Condo[] = [];

  selectedPrimaryCondo: any;
  public pictures: NzUploadFile[] = [];
  _picturesReferenceToDelete: any[] = [];
  previewImage = '';
  previewVisible = false;

  // Loading
  loading = { whitelabel: false, condos: false, isUploading: false, marketplace: false };
  editMode: boolean = false;

  // Steps
  currentStep: string = 'configurations';

  typeCondoOptions: Option[] = [];

  // Propriedades Classificados/Advertiser
  states: Option[] = [];
  categories: Option[] = [];
  statuses: Option[] = [];


  constructor(
    private marketplaceService: MarketplaceService,
    private notification: NzNotificationService,
    private whitelabelService: WhiteLabelService,
    private condoService: CondoService,
    private partnerService: PartnerService,
    private advertiserService: AdvertiserService,
    private modalService: NzModalService,
    private fileService: FileService,
    private cdr: ChangeDetectorRef
  ) {
    this.marketplaceForm = new FormGroup({
      _id : new FormControl(),
      name: new FormControl('', Validators.required),
      enabled : new FormControl(),
      blocked : new FormControl(),
      blockedReason : new FormControl(),
      typeCondo : new FormControl(),
      startDate : new FormControl(),
      endDate : new FormControl(),
      condos : new FormControl(),
      whitelabels : new FormControl(),
      partners : new FormControl(),
      advertisers : new FormControl(),
    });

    this.partnerForm = new FormGroup({
      _id : new FormControl(),
      name: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
      logo: new FormControl('', Validators.required),
      tags: new FormArray([this.createPartnerTagControl()]),
      emails: new FormArray([this.createPartnerEmailControl()]),
      phones: new FormArray([this.createPartnerPhoneControl()]),
      description: new FormControl('', Validators.required),
      order: new FormControl('', Validators.required),
      enabled: new FormControl(),
      enabledInWhitelabel: new FormControl(),
      whitelabels: new FormControl(),
      actionUrl: new FormControl('', Validators.required),
      actionType: new FormControl(),
      dataToSent: new FormControl(),
      headerParams: new FormArray([]),
      redirectViaCallback: new FormControl(),
      urlCallback: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
      defaultTermsOfUse: new FormControl(),
      termsOfUseUrl: new FormControl('', Validators.required),
      termsOfUseText: new FormControl(),
      marketplace: new FormControl(),
    });

    this.advertiserForm = new FormGroup({
      _id : new FormControl(),
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      price: new FormControl(),
      state: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])),
      public: new FormControl('', Validators.required),
      pictures: new FormControl(null),
      type: new FormControl(),
      status: new FormControl(),
      city: new FormControl(),
      uf: new FormControl(),
      region: new FormControl(),
      marketplace: new FormControl(),
    });

  }
  
  ngOnInit(): void {
    this.marketplaceForm.setValue(BuildMarketplace(this.marketplace));
    this.marketplaceForm.get('whitelabels')?.setValue(this.marketplace.whitelabels?.map(whitelabels => whitelabels._id)[0]?.toString());
    this.marketplaceForm.get('condos')?.setValue(this.marketplace.condos?.map(condo => condo._id));
    this.loading.marketplace = true;
    if(this.marketplace._id){
      this.loadMarketPlaceData(this.marketplace._id);
    }
    this.initializeMenuItems();
    this.loadInitialData();
    this.initializeOptions();

    this.editMode = Boolean(this.marketplace._id);
  }

  loadMarketPlaceData(marketplaceId: string): void {
    const query: EcondosQuery = {
      $select: 'name enabled blocked blockedReason startDate endDate typeCondo condos partners advertisers',
      $populate: [
        { path: 'whitelabels', select: 'name ' },
        { path: 'partners', select: 'name url startDate endDate logo tags emails phones description order enabled enabledInWhitelabel whitelabels actionUrl actionType dataToSent headerParams redirectViaCallback urlCallback startDate endDate defaultTermsOfUse termsOfUseUrl termsOfUseText' },
        { path: 'advertisers', select: 'title description price state category phone public pictures type status city uf region marketplace condo',
          populate: { path: 'pictures', select: 'url thumbnail type format name' },
        },
        { path: 'condos', select: 'name ' }
      ],
      $sort: 'order'
    }
    this.loading.marketplace = true;
    this.marketplaceService.getMarketplaceById(marketplaceId, query).subscribe(
      (res: Marketplace) => {
        this.marketplace = res;
        this.marketplaceForm.setValue(BuildMarketplace(res));
        this.marketplaceForm.get('whitelabels')?.setValue(res.whitelabels?.map(whitelabels => whitelabels._id)[0]?.toString());
        this.marketplaceForm.get('condo')?.setValue(res.condos?.map(condo => condo._id));
        this.editMode = true;
        this.loadInitialData();
      },
      (error) => {
        this.notification.create('error', 'Marketplace', 'Erro ao buscar Marketplace');
      },
      () => {
        this.loading.marketplace = false;
      }
    );
  }

  askToRemove(data: any, type: string): void {
    this.modalService.confirm({
      nzTitle: 'Deseja realmente remover?',
      nzContent: `Essa ação não poderá ser desfeita.`,
      nzOkText: 'Entendo e quero remover',
      nzOnOk: () => {
        switch (type) {
          case 'partner':
            this.deletePartner(data as Partner);
            break;
          case 'advertiser':
            this.deleteAdvertiser(data as Ad);
            break;
        }
      }
    });
  }

  currentActiveCondos(condoId: string): string{
    const currentCondo = this.condos.filter((condo) => condo._id === condoId);
    return currentCondo[0]?.name || 'Todos';
  }

  currentActiveWhitelabel(): string{
    const currentWhitelabel = this.whitelabels.filter((whitelabel) => whitelabel._id === this.marketplaceForm.get('whitelabel')?.value);
    return currentWhitelabel[0]?.companyName || 'Nenhum selecionado';
  }

  showCurrentState(ad: Ad): string {
    return this.states.find((states) => states.value === ad.state)?.label || '';
  }

  showCurrentCategory(ad: Ad): string {
    return this.categories.find((cat) => cat.value === ad.category)?.label || '';
  }

  private initializeMenuItems(): void {
    this.menuItems = [
      { icon: 'dashboard', title: 'Configurações Gerais', step: 'configurations' },
      { icon: 'cluster', title: 'Configurações de Classificados', step: 'advertiser', needEditMode: true },
      { icon: 'contacts', title: 'Configurações de Parceiros', step: 'partners', needEditMode: true },
      { icon: 'file-search', title: 'Resumo', step: 'summary', needEditMode: true },
    ];
  }

  private loadInitialData(): void {
    this.getWhitelabels();

    this.loading.marketplace = false;
    this.loadPartnersForm(this.marketplace.partners);
    this.loadAdvertiserForm(this.marketplace.advertisers as Ad);
  }

  getCondos() :void {
    this.getAllCondos();
  }

  private initializeOptions(): void {
    this.states = this.mapEnumToOptions(STATES, STATES_LABEL);
    this.categories = this.mapEnumToOptions(CATEGORIES, CATEGORIES_LABEL);
    this.statuses = this.mapEnumToOptions(STATUS, {
      OPEN: 'Aberto',
      CLOSED: 'Fechado',
      CANCELED: 'Cancelado'
    });
    this.typeCondoOptions = this.mapEnumToOptions(TYPE_CONDO, TYPE_CONDO_LABEL);
  }

  private mapEnumToOptions(enumObj: any, labelsObj: any): Option[] {
    return Object.keys(enumObj).map(key => ({
      value: enumObj[key],
      label: labelsObj[key]
    }));
  }

  private populateMarketplaceId(): void {
    this.partnerForm.get('marketplace')?.setValue(this.marketplaceForm.get('_id')?.value);
    this.advertiserForm.get('marketplace')?.setValue(this.marketplaceForm.get('_id')?.value);
  }

  loadPartnersForm(data = {}): void {
    this.partnerForm.patchValue(BuildPartner(data));
  }

  loadAdvertiserForm(data: Ad = {}): void {
    this.pictures = [];
    this.advertiserForm.patchValue(BuildAd(data))
    if (Array.isArray(data.pictures) && data.pictures.length > 0) {
      this.pictures = data.pictures.map((pic: any) => {
        return {
          uid: pic._id,    // ID da imagem para o componente
          _id: pic._id,
          name: pic.name,
          status: 'done',
          url: pic.url,
        };
      });
    }
  }

  getAllCondos(): void {
    const query: EcondosQuery = {
      $select: 'name residencesVoter deleted type',
      deleted: false
    }

    const whiteLabelSelected = this.marketplaceForm.get('whitelabels')?.value;
    const isEcondos = this.whitelabels.find(whitelabel => whitelabel._id === whiteLabelSelected)?.companyName === 'eCondos';
    const typeCondo = this.marketplaceForm.get('typeCondo')?.value;
    
    if (!whiteLabelSelected || whiteLabelSelected.length === 0) {
      return
    }

    if(typeCondo !== 'ALL') {
      query.type = typeCondo;
    }

    if (!isEcondos) {
      query.whiteLabel = whiteLabelSelected;
    }
    
    this.loading.condos = true;
    
    this.condoService.get(query).subscribe(condos => {
      this.loading.condos = false;
      this.condos = condos;
      const _condos = this.marketplaceForm.get('condos')?.value;
      const newCondos = _condos.filter((condo: any) => this.condos.find((c) => c._id === condo));
      this.marketplaceForm.get('condos')?.setValue(newCondos);
    }, err => {
      console.log(err);
      this.notification.create('error', 'Busca', 'Não foi possível buscar condomínios');
    });
  }

  getWhitelabels() {
    const query: EcondosQuery = {
      $select: 'companyName',
      $limit: 500
    }
    this.loading.whitelabel = true;
    this.whitelabelService.get(query).subscribe(whitelabels => {
      this.whitelabels = whitelabels;
      this.getAllCondos();
    }, err => {
      console.log(err);
      this.notification.create('error', 'Busca', 'Não foi possível buscar white labels');
    },
    () => {
      this.loading.whitelabel = false;
    });
  }

  goToStep(current: any): void {
    if (!this.editMode && current.needEditMode && current.step !== this.currentStep) {
      this.notification.create('error', 'Erro', 'É obrigatório preencher as informações anteriores');
      return;
    }
    this.currentStep = current.step;
  }

  marketplaceFormSave(): void {
    this.marketplaceForm.markAllAsTouched();
    if (this.marketplaceForm.invalid) {
      this.notification.create('error', 'Erro', 'Preencha todos os campos obrigatórios');
      return;
    }
    const startDate = moment(this.marketplaceForm.value.startDate).startOf('day').toISOString();
    const endDate = moment(this.marketplaceForm.value.endDate).startOf('day').toISOString();
    this.marketplaceForm.get('startDate')?.setValue(startDate);
    this.marketplaceForm.get('endDate')?.setValue(endDate);
    if(this.editMode) {
      this.updateMarketplace();
    } else {  
      this.createMarketplace();
    }
  }

  createMarketplace(): void {
    const marketplace = this.marketplaceForm.value;
    delete marketplace._id;

    this.marketplaceService.createMarketplace(marketplace).subscribe(
      (res: any) => {
        this.marketplace = res;
        this.notification.create('success', 'Marketplace', 'Marketplace Criado');
        // Atualiza o id do marketplace nos forms de parceiros e anunciantes
        this.marketplaceForm.get('_id')?.setValue(res._id);
        this.populateMarketplaceId();
        this.editMode = true;
      },
      (error) => {
        this.notification.create('error', 'Marketplace', 'Erro ao criar Marketplace');
      }
    );
  }

  updateMarketplace(): void {
    const marketplace = this.marketplaceForm.value;
    this.marketplaceService.updateMarketplace(marketplace._id, marketplace).subscribe(
      (res: any) => {
        this.notification.create('success', 'Marketplace', 'Marketplace Alterado');
        this.loadMarketPlaceData(marketplace._id);
      },
      (error) => {
        this.notification.create('error', 'Marketplace', 'Erro ao alterar Marketplace');
      }
    );
  }

  cancelPartners(): void {
    this.loadPartnersForm();
  }

  handlePartnerForm(): void {
    this.partnerForm.markAllAsTouched();
    if (this.partnerForm.invalid) {
      this.notification.create('error', 'Erro', 'Preencha todos os campos obrigatórios');
      return;
    }
    this.populateMarketplaceId();
    if (this.partnerForm.value._id) {
      this.updatePartner();
    } else {
      this.createPartner();
    }
  }

  editPartner(partner: any): void {
    this.currentStep = 'partners';
    this.loadPartnersForm(partner);
  }

  createPartner(): void {
    const partner = this.partnerForm.value;
    delete partner._id;
    partner.pictures = (this.pictures.length && this.pictures) || null
    this.partnerService.createPartner(partner).subscribe(
      (res: any) => {
        this.notification.create('success', 'Parceiro', 'Parceiro Criado');
        this.partnerForm.get('_id')?.setValue(res._id);
        this.marketplaceForm.get('partners')?.setValue([...this.marketplaceForm.get('partners')?.value, this.partnerForm.value]);
        this.marketplaceFormSave();
        this.loadPartnersForm();
        this.pictures = [];
        this.loadMarketPlaceData(this.marketplace._id as string);
      },
      (error) => {
        this.notification.create('error', 'Parceiro', 'Erro ao criar Parceiro');
      }
    );
  }

  updatePartner(): void {
    const partner = this.partnerForm.value;
    this.partnerService.updatePartner(partner._id, partner).subscribe(
      (res: any) => {
        this.notification.create('success', 'Parceiro', 'Parceiro Alterado');
        this.loadPartnersForm();

        const partner = this.marketplaceForm.get('partners')?.value;
        const index = partner.findIndex((ad: any) => ad._id === this.partnerForm.get('_id')?.value);
        partner[index] = this.partnerForm.value;
        this.marketplaceForm.get('partners')?.setValue(partner);
        this.loadMarketPlaceData(this.marketplace._id as string);
      },
      (error) => {
        this.notification.create('error', 'Parceiro', 'Erro ao alterar Parceiro');
      }
    );
  }

  deletePartner(partner: Partner): void {
    const partnerId = partner._id || this.partnerForm.get('_id')?.value;
    this.partnerService.deletePartner(partnerId).subscribe(
      (res: any) => {
        this.notification.create('success', 'Parceiro', 'Parceiro Deletado');
        // Atualiza a lista de parceiros do marketplace
        const partnersArray = this.marketplaceForm.get('partners')?.value;
        this.marketplaceForm.get('partners')?.setValue(partnersArray.filter((partner: any) => partner._id !== partnerId));
        this.marketplaceFormSave();
        this.loadPartnersForm();
        this.loadMarketPlaceData(this.marketplace._id as string);
      },
      (error) => {
        this.notification.create('error', 'Parceiro', 'Erro ao deletar Parceiro');
      }
    );
  }

  handleAdvertiserForm(): void {
    this.advertiserForm.markAllAsTouched();
    if (this.advertiserForm.invalid) {
      this.notification.create('error', 'Erro', 'Preencha todos os campos obrigatórios');
      return;
    }
    this.populateMarketplaceId();
    const advertiser = this.advertiserForm.value;
    advertiser.price = this.priceReplace(advertiser.price);
    advertiser.pictures = this.pictures.filter((pic) => pic._id && pic.status === 'done').map((pic) => pic._id);

    if (this.advertiserForm.value._id) {
      this.updateAdvertiser(advertiser);
    } else {
      this.createAdvertiser(advertiser);
    }
  }

  createAdvertiser(advertiser: Ad): void {
    delete advertiser._id;

    this.advertiserService.createAdvertiser({advertiser, condoId: this.selectedPrimaryCondo}).subscribe(
      (res: any) => {
        this.notification.create('success', 'Anúncio', 'Anúncio Criado');
        this.advertiserForm.get('_id')?.setValue(res._id);
        this.marketplaceForm.get('advertisers')?.setValue([...this.marketplaceForm.get('advertisers')?.value, this.advertiserForm.value]);
        this.marketplaceFormSave();
        this.loadAdvertiserForm();
        this.loadMarketPlaceData(this.marketplace._id as string);
      },
      (error) => {
        this.notification.create('error', 'Anúncio', 'Erro ao criar Anúncio');
      }
    );
  }

  editAdvertiser(advertiser: Ad): void {
    this.currentStep = 'advertiser';
    this.selectedPrimaryCondo = this.condos.find((condo) => condo._id === advertiser.condo)?._id;
    this.loadAdvertiserForm(advertiser);
  }

  updateAdvertiser(advertiser: Ad): void {
    if(!advertiser._id){
      this.notification.create('error', 'Anunciante', 'Erro ao alterar Anunciante');
      return;
    }
    this.advertiserService.updateAdvertiser({advertiserId: advertiser._id, advertiser, condoId: this.selectedPrimaryCondo}).subscribe(
      (res: any) => {
        this.notification.create('success', 'Anunciante', 'Anunciante Alterado');
        this.loadAdvertiserForm();
        
        const advertiser = this.marketplaceForm.get('advertisers')?.value;
        const index = advertiser.findIndex((ad: any) => ad._id === this.advertiserForm.get('_id')?.value);
        advertiser[index] = this.advertiserForm.value;
        this.marketplaceForm.get('advertisers')?.setValue(advertiser);
        this.loadMarketPlaceData(this.marketplace._id as string);
      },
      (error) => {
        this.notification.create('error', 'Anunciante', 'Erro ao alterar Anunciante');
      }
    );
  }

  deleteAdvertiser(advertiser: Ad): void {
    const advertiserId = advertiser._id || this.advertiserForm.get('_id')?.value;
    const condoId = advertiser.condo || '';
    this.advertiserService.deleteAdvertiser({advertiserId, condoId}).subscribe(
      (res: any) => {
        this.notification.create('success', 'Anunciante', 'Anunciante Deletado');
        // Atualiza a lista de anunciantes do marketplace
        const advertiserArray = this.marketplaceForm.get('advertisers')?.value;
        this.marketplaceForm.get('advertisers')?.setValue(advertiserArray.filter((partner: any) => partner._id !== advertiserId));

        this.marketplaceFormSave();
        this.loadAdvertiserForm();
      },
      (error) => {
        this.notification.create('error', 'Anunciante', 'Erro ao deletar Anunciante');
      }
    );
  }

  onPhoneInput(event: any): void {
    const value = event.target.value;
    event.target.value = formatPhone(value);
  }

  priceReplace(price: any): void {
    return (price &&
                parseFloat(
                  price
                    .toString()
                    .replace(/[^\.\,0-9]/g, '')
                    .replace('.', '')
                    .replace(',', '.')
                )) ||
              0;
  }

  /**
   * 
   * Partners Form Methods
   * 
   */
  

  get partnerTags(): FormArray {
    return this.partnerForm.get('tags') as FormArray;
  }

  get partnerEmails(): FormArray {
    return this.partnerForm.get('emails') as FormArray;
  }

  get partnerPhones(): FormArray {
    return this.partnerForm.get('phones') as FormArray;
  }

  get partnerHeaderParams(): FormArray {
    return this.partnerForm.get('headerParams') as FormArray;
  }

  private createPartnerTagControl(value: string = ''): FormControl {
    return new FormControl(value, Validators.required);
  }

  private createPartnerEmailControl(value: string = ''): FormControl {
    return new FormControl(value, [Validators.required, Validators.email]);
  }

  private createPartnerPhoneControl(value: string = ''): FormControl {
    return new FormControl(value, [Validators.required, Validators.minLength(11), Validators.maxLength(15)]);
  }

  private createPartnerHeaderParamsControl(value: string = ''): FormControl {
    return new FormControl(value);
  }

  _addPartnerTag(): void {
    (this.partnerForm.get('tags') as FormArray).push(this.createPartnerTagControl());
  }

  _addPartnerEmail(): void {
    (this.partnerForm.get('emails') as FormArray).push(this.createPartnerEmailControl());
  }

  _addPartnerPhone(): void {
    (this.partnerForm.get('phones') as FormArray).push(this.createPartnerPhoneControl());
  }

  _addPartnerHeaderParams(): void {
    (this.partnerForm.get('headerParams') as FormArray).push(this.createPartnerHeaderParamsControl());
  }

  /**
   * Upload 
   */
  public handleUploadPictures({file}: NzUploadChangeParam): void {
    this.advertiserForm.get('pictures')?.setValue(file.originFileObj);
  }
  
  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  public handleUploadImage(info: NzUploadChangeParam): void {
    if (info.file.status === 'uploading') {
      this.loading.isUploading = true;
      const formData = new FormData();
      formData.append('file', info.file.originFileObj as Blob, info.file.name);
      info.file.status = 'success';
      this.fileService.uploadFilesFromFormData(formData).subscribe(
        (res) => {
          info.file.status = 'done';
          info.file._id = res[0]._id;
        },
        (error) => {
          info.file.status = 'error';
          this.modalService.error({
            nzTitle: 'Erro ao fazer upload',
            nzContent: 'Não foi possível fazer o upload do arquivo',
          });
        },
        () => {
          this.loading.isUploading = false;
        }
      );
    }
  }
}
