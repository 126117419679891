import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';
import { CondoFinancialInfoService } from '@api/service/condo-financial-info.service';

@Injectable({ providedIn: 'root' })
export class CreateCondoFinancialInfoResolver implements Resolve<any> {
  constructor(private condoFinancialInfo: CondoFinancialInfoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const _id = route.params.condoFinancialInfoId;
    const query: EcondosQuery = {
      $select:
        'whiteLabel condo residencesCount price lastAdjustmentDate lastAdjustmentPercentage nextAdjustmentDate nextAdjustmentPercentage adjustmentIndex obs',
      $populate: [
        { path: 'whiteLabel', select: 'name' },
        {
          path: 'condo',
          select: 'name timeZone type plan daysOfTrial whiteLabel createdAt'
        },
      ],
    };
    return this.condoFinancialInfo.getById(_id, query).pipe(map((res) => res));
  }
}
