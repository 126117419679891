import * as moment from "moment";

export interface Partner {
  _id?: string | null;
  name: string;
  url: string;
  logo: string;
  tags: string[];
  emails: string[];
  phones: string[];
  description: string;
  order?: number;
  enabled: boolean;
  enabledInWhitelabel?: boolean;
  whitelabels?: string[]; // Assuming IDs as strings
  actionUrl?: string;
  actionType?: 'GET' | 'POST' | 'OPTIONS' | 'PUT';
  dataToSent?: string; // Assuming dataToSent is an object
  headerParams: string[];
  redirectViaCallback?: boolean;
  urlCallback?: string;
  startDate?: Date;
  endDate?: Date;
  defaultTermsOfUse?: boolean;
  termsOfUseUrl?: string;
  termsOfUseText?: string;
  marketplace?: string; // Assuming marketplace is an ID
}

export const BuildPartner = (partner: Partial<Partner>): Partner => {
  return {
    _id: partner._id || null,
    name: partner.name || '',
    url: partner.url || '',
    logo: partner.logo || '',
    tags: partner.tags || [''],
    emails: partner.emails || [''],
    phones: partner.phones || [''],
    description: partner.description || '',
    order: partner.order || undefined,
    enabled: partner.enabled || true,
    enabledInWhitelabel: partner.enabledInWhitelabel || false,
    whitelabels: partner.whitelabels || [],
    actionUrl: partner.actionUrl || '',
    actionType: partner.actionType || 'GET',
    dataToSent: partner.dataToSent || '',
    headerParams: partner.headerParams || [],
    redirectViaCallback: partner.redirectViaCallback || false,
    urlCallback: partner.urlCallback || '',
    startDate: partner.startDate || moment().toDate(),
    endDate: partner.endDate || moment().add(3, 'year').toDate(),
    defaultTermsOfUse: partner.defaultTermsOfUse || false,
    termsOfUseUrl: partner.termsOfUseUrl || '',
    termsOfUseText: partner.termsOfUseText || '',
    marketplace: partner.marketplace || '',
  };
}