import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '@api/service/user.service';
import {EcondosQuery} from '@api/model/query';
import {CondoService} from '@api/service/condo.service';

@Injectable({providedIn: 'root'})
export class DashboardResolver implements Resolve<any> {

  constructor(private _router: Router,
              private condoService: CondoService,
              private userService: UserService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const condosQuery: EcondosQuery = {
      $count: true,
      'plan.name': 'PREMIUM'
    };
    const usersQuery: EcondosQuery = {
      $count: true
    }

    return forkJoin([
      this.userService.getAllUsers(usersQuery),
      this.condoService.get(condosQuery).pipe(map(([count]) => count))
    ]).pipe(
      map(([users, condos]) => ({users, condos}))
    )
  }
}
