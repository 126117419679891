<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="../../">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="shop"></i>
      Logs
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="shop"></i>
      Condomínios
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title>Logs do condomínio</nz-page-header-title>
  <nz-page-header-extra>
    <nz-range-picker
      class="mr-3"
      [nzFormat]="'dd/MM/yyyy'"
      nz
      [(ngModel)]="selectedRangeDate"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="getData(1)"></nz-range-picker>
    <nz-select
      [(ngModel)]="selectedSortBy"
      nzPlaceHolder="Ordenar por"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="getData(1)">
      <nz-option nzValue="-date" nzLabel="Mais recente"></nz-option>
      <nz-option nzValue="date" nzLabel="Mais antigo"></nz-option>
    </nz-select>
  </nz-page-header-extra>
  <nz-page-header-content>
    <div class="control-toolbar" nz-row nzJustify="end" nzAlign="middle" nzGutter="15">
      <nz-col>
        <nz-select nzPlaceHolder="Selecione o tipo da requisição" nzAllowClear [formControl]="searchRequestMethod">
          <nz-option nzValue="GET" nzLabel="Consultar"></nz-option>
          <nz-option nzValue="POST" nzLabel="Adicionar"></nz-option>
          <nz-option nzValue="PUT" nzLabel="Editar"></nz-option>
          <nz-option nzValue="DELETE" nzLabel="Deletar"></nz-option>
        </nz-select>
      </nz-col>

      <nz-col>
        <input type="text" nz-input placeholder="Busque por rota" [formControl]="searchRouteUrl" />
      </nz-col>

      <nz-col>
        <app-search-condo (filter)="handleCondoFilter($event)" (close)="closeCondoFilter()"> </app-search-condo>
      </nz-col>

      <nz-col>
        <nz-button-group nzSize="default">
          <button nz-button nzType="primary" [disabled]="nzPageIndex < 2" (click)="goToPreviousPage()">
            <i nz-icon nzType="left"></i>
            Voltar
          </button>
          <button nz-button nzType="primary" [disabled]="!canGoToNextPage" (click)="goToNextPage()">
            Próximo
            <i nz-icon nzType="right"></i>
          </button>
        </nz-button-group>
      </nz-col>
    </div>
  </nz-page-header-content>
</nz-page-header>

<div class="px-2">
  <nz-table #logsTable [nzData]="logs" [nzFrontPagination]="false">
    <thead>
      <tr>
        <th>Condomínio</th>
        <th>Usuário</th>
        <th>Data</th>
        <th>Tipo</th>
        <th>Rota</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of logsTable.data">
        <td>
          <a *ngIf="log.condo" [routerLink]="['/home/condos', log.condo]">{{ log.condo }}</a> <span *ngIf="!log.condo">-</span>
        </td>
        <td>
          <a *ngIf="log.user" [routerLink]="['/home/users', log.user]">{{ log.user }}</a> <span *ngIf="!log.user">-</span>
        </td>
        <td>{{ log.date | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
        <td>{{ log.method }}</td>
        <td>{{ log.url }}</td>
        <td>{{ log.status }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>

<div nz-row [nzJustify]="'end'">
  <nz-button-group nzSize="default">
    <button nz-button nzType="primary" [disabled]="nzPageIndex < 2" (click)="goToPreviousPage()">
      <i nz-icon nzType="left"></i>
      Voltar
    </button>
    <button nz-button nzType="primary" [disabled]="!canGoToNextPage" (click)="goToNextPage()">
      Próximo
      <i nz-icon nzType="right"></i>
    </button>
  </nz-button-group>
</div>
