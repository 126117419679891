<!-- Cabeçalho da página -->
<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a [routerLink]="['/home/dashboard']">
        <em nz-icon nzType="home"></em>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <em nz-icon nzType="contacts"></em>
      Marketplaces
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</nz-page-header>

<!-- Botão que leva a página de criação -->
<div class="create-button">

  <button
    nz-button
    [nzType]="'primary'"
    (click)="showModalEdit()"
    [nzSize]="'large'"
  >

    <span>Criar Novo Marketplace</span>

  </button>

</div>
<!--Tabela para dados dos Marketplaces-->
<nz-table
  #basicTable
  [nzData]="marketplaces"
  [nzScroll]="{ x: '1200px' }"
  [nzHideOnSinglePage]="true"
  [nzPageSize]="10"
  nzBordered
>

  <thead>

    <tr>
      <th nzWidth="150px" scope="col-name">Nome</th>
      <th nzWidth="220px" scope="col-name">Status</th>
      <th nzWidth="75px"scope="col-action">Ações</th>
    </tr>

  </thead>

  <tbody>

    <tr *ngFor="let data of basicTable.data">

      <td>
        <a
          (click)="showModalEdit(data)"
          nz-tooltip
          nzTooltipTitle="Acessar site"
          style="font-size: 1rem;"
        >
          {{data.name}}
        </a>
      </td>


      <td>
        <p>
          {{ data.enabled? 'Ativo' : 'Inativo' }}
          <br> Data de início: {{ data.startDate | date: 'dd/MM/yyyy' }}
          <br> Data de finalização: {{ data.endDate  | date: 'dd/MM/yyyy' }}</p>        
      </td>

      <td class="actions-marketplace">
        <em nz-icon
          nzType="delete"
          nzTheme="outline"
          style="font-size: 1.2rem; color: #ff0000; margin-right:15px"
          (click)="showModal(data._id!)"
          nz-tooltip
          nzTooltipTitle="Excluir parceiro"
        >
        </em>

        <!-- <nz-divider nzType="horizontal"></nz-divider> -->

        <em nz-icon
          nzType="edit"
          nzTheme="outline"
          style="font-size: 1.2rem; color: #0000cd;"
          (click)="showModalEdit(data)"
          nz-tooltip
          nzTooltipTitle="Editar marketplace"
        >
        </em>
      </td>
      <!-- Links para seção de edição ou exclusão do marketplace selecionado -->

    </tr>

  </tbody>
</nz-table>

<!-- Modal para confirmar a exclusão do marketplace -->
<nz-modal [(nzVisible)]="isVisible" nzTitle="Confirmar" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <p>Tem certeza de que deseja excluir este marketplace? Ao excluir o marketplace todos os anúncios e parcerias também serão apagados.</p>
  </ng-container>
</nz-modal>
