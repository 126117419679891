import {Component, OnInit} from '@angular/core';
import {WhiteLabel} from "@api/model/white-label";
import {ActivatedRoute, Router} from "@angular/router";
import {UseTerms} from "@api/model/use-terms";
import {UseTermsService} from "@api/service/use-terms.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {conditionalValidator} from "@api/util/validators";
import {SessionService} from "../../../service/session.service";
import {User} from "@api/model/user";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {timeout} from "rxjs/operators";
import {WhiteLabelService} from "@api/service/white-label.service";

@Component({
  selector: 'app-use-terms-page',
  templateUrl: './use-terms-page.component.html',
  styleUrls: ['./use-terms-page.component.scss']
})
export class UseTermsPageComponent implements OnInit {
  isEcondos: boolean;
  whiteLabel: WhiteLabel | null;
  useTerms: UseTerms;
  form: FormGroup;
  user: User;

  apiKey = '3kthv82id3e9ftbf6me2u6t8opgnpzm1ls2ljd107bdjs17z'
  tinymceBaseConfig = {
    menubar: false,
    branding: false,
    statusbar: false,
    language: 'pt_BR',
    height: '560',
    plugins: 'fullscreen template table hr',
    toolbar: 'undo redo | fontselect fontsizeselect formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | forecolor backcolor removeformat | fullscreen ',
  }
  STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  }
  status: number | null = null;


  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private useTermsService: UseTermsService,
    private whiteLabelService: WhiteLabelService,
    private sessionService: SessionService,
    private router: Router,
    private notificationService: NzNotificationService) {


    const whiteLabel = route.snapshot.data.whiteLabel;
    if (whiteLabel) {
      this.isEcondos = false;
      this.whiteLabel = whiteLabel;
      console.log({whiteLabel})
      this.useTerms = route.snapshot.data.useTerms;
    } else {
      this.isEcondos = true;
      this.useTerms = route.snapshot.data.econdos
      this.whiteLabel = null;
    }

    this.user = this.sessionService.user;

    this.form = this.fb.group({
      updateType: ['patch', conditionalValidator(() => !!this.useTerms, Validators.required)],
      useTermsText: ['', Validators.required],
      privacyPolicyText: ['', Validators.required]
    })
    if (this.useTerms) this.setFormValues(this.useTerms);
  }

  ngOnInit(): void {
  }

  setFormValues(useTerms: UseTerms) {
    this.form.get('useTermsText')?.setValue(useTerms.useTermsText || '');
    this.form.get('privacyPolicyText')?.setValue(useTerms.privacyPolicyText || '');
  }

  save() {
    if (this.form.valid) {
      const action = this.buildUseTerms();
      this.status = this.STATUS.LOADING;
      action.pipe(timeout(10000)).subscribe(() => {
        this.status = this.STATUS.SUCCESS;
        this.notificationService.create('success', 'Termos de Uso e Privacidade', 'Item salvo com sucesso');
        this.router.navigate(['home', this.whiteLabel ? 'whitelabels' : 'dashboard']);
      }, () => {
        this.notificationService.create('error', 'Error', 'Não foi possível realizar a operação. Tente novamente')
        this.status = this.STATUS.ERROR;
      })
    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty()
          control.updateValueAndValidity({onlySelf: true})
        }
      })
    }
  }

  toggleUseTermsStatus() {
    if (this.useTerms) {
      const value = !(this.useTerms.enabled as boolean);
      const id: string = this.useTerms._id as string;
      this.status = this.STATUS.LOADING;
      this.useTermsService.toggleStatus(id).subscribe(() => {
        this.notificationService.create(
          'success', 'Status alterado com sucesso', 'Termo de uso e privacidade ' +
          (value ? 'habilitado' : 'desabilitado') + ' com sucesso')
        this.useTerms.enabled = value;
        this.status = this.STATUS.SUCCESS
      }, () => {
        this.notificationService.create('error', 'Erro', 'Não foi possível realizar a operação. Tente novamente')
        this.status = this.STATUS.ERROR;
      })
    } else {
      this.notificationService.create('error', 'Erro', 'É necessário que exista um termo de uso')
    }
  }

  private buildUseTerms() {
    const data = {
      useTermsText: this.form.get('useTermsText')?.value,
      privacyPolicyText: this.form.get('privacyPolicyText')?.value
    }

    if (this.useTerms) {
      const updateType = this.form.get('updateType')?.value || 'patch';
      const updatedBy = this.user;
      const id: string = this.useTerms._id as string;

      return this.useTermsService.update(id, {
        updateType,
        updatedUseTerms: {...data, updatedBy}
      })
    } else {
      const createdBy = this.user;
      const whiteLabel: string | null = this.whiteLabel ? this.whiteLabel?._id as string : null;
      const isEcondos = !this.whiteLabel;

      return this.useTermsService.create({
        ...data,
        createdBy,
        ...(whiteLabel && {whiteLabel}),
        ...(isEcondos && {isEcondos})
      })
    }
  }
}
