import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {conditionalValidator} from '@api/util/validators';
import {beforeDateValidator} from '../../../../../validators/before-date.validator';
import {Highlight, HIGHLIGHT_PRESENTATION_MODE_INFO} from '@api/model/highlight';
import {FileService} from '@api/service/file.service';
import {readFileAsDataURL} from '../../../../../util/read-file-as-data-url';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {EcondosQuery} from '@api/model/query'
import {Subject} from 'rxjs';
import {HighlightsService, SaveHighlightDTO} from '@api/service/highlights.service';
import {takeUntil, timeout} from 'rxjs/operators';
import * as moment from 'moment';
import {WhiteLabel} from '@api/model/white-label';
import {ActivatedRoute, Router} from '@angular/router';
import {urlValidator} from '../../../../../validators/url.validator';

type Status = 'LOADING' | 'SUCCESS' | 'ERROR';

@Component({
  selector: 'app-create-highlight-page',
  templateUrl: './create-highlight-page.component.html',
  styleUrls: ['./create-highlight-page.component.scss']
})
export class CreateHighlightPageComponent implements OnInit, OnDestroy {
  highlightId: Highlight['_id'] | null = null;
  highlight: Highlight | null = null;

  fetchHighlightQuery: EcondosQuery = {
    $select: 'title description picture presentationMode link linkLabel startDate endDate position isActive whiteLabels',
    $populate: [
      { path: 'picture', select: 'url thumbnail type format name' },
      { path: 'whiteLabels', select: 'name companyName icon' }
    ]
  };

  fetchHighlightStatus: Status | null = null;
  submitFormStatus: Status | null = null;

  highlightForm = new FormGroup({
    title: new FormControl('', Validators.required),
    description: new FormControl(
      '',
      conditionalValidator(
        () => this.presentationMode.value === 'TEXT_ONLY' || this.presentationMode.value === 'TEXT_AND_PICTURE',
        Validators.required
      )
    ),
    picture: new FormControl(
      null,
      conditionalValidator(
        () => this.presentationMode.value === 'PICTURE_ONLY' || this.presentationMode.value === 'TEXT_AND_PICTURE',
        Validators.required
      )
    ),
    presentationMode: new FormControl('TEXT_AND_PICTURE'),
    link: new FormControl(
      '',
      conditionalValidator(() => !!this.link.value, urlValidator)
    ),
    linkLabel: new FormControl(''),
    whiteLabels: new FormControl([], [Validators.required, Validators.minLength(1)]),
    startDate: new FormControl(new Date(), Validators.required),
    endDate: new FormControl(
      null,
      conditionalValidator(() => !!this.endDate.value, beforeDateValidator('startDate'))
    ),
    position: new FormControl(1, Validators.min(1)),
    isActive: new FormControl(true)
  });

  title = this.highlightForm.get('title') as FormControl;
  description = this.highlightForm.get('description') as FormControl;
  picture = this.highlightForm.get('picture') as FormControl;
  presentationMode = this.highlightForm.get('presentationMode') as FormControl;
  whiteLabels  = this.highlightForm.get('whiteLabels') as FormControl;
  startDate = this.highlightForm.get('startDate') as FormControl;
  endDate = this.highlightForm.get('endDate') as FormControl
  position = this.highlightForm.get('position') as FormControl
  link = this.highlightForm.get('link') as FormControl

  presentationModeOptions = HIGHLIGHT_PRESENTATION_MODE_INFO;

  isUploadingPicture = false;
  highlightUploadedPicture: string | null = null;

  selectedWhiteLabels: WhiteLabel[] = [];

  unsubscribe$ = new Subject();

  constructor(
    private highlightsService: HighlightsService,
    private fileService: FileService,
    private notificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.highlightForm.controls.presentationMode.valueChanges.subscribe(() => {
      this.highlightForm.controls.description.updateValueAndValidity();
      this.highlightForm.controls.picture.updateValueAndValidity();
    });

    this.highlightForm.controls.link.valueChanges.subscribe(value => {
      const linkLabel = this.highlightForm.controls.linkLabel.value;

      if (value && !linkLabel) {
        this.highlightForm.controls.linkLabel.setValue('Saiba mais', { emitEvent: false });
      } else if (!value) {
        this.highlightForm.controls.linkLabel.setValue('', { emitEvent: false });
      }
    });

    this.highlightForm.controls.startDate.valueChanges.subscribe(() => {
      this.endDate.updateValueAndValidity();
    });

    this.highlightForm.controls.position.valueChanges.subscribe(value => {
      this.position.setValue(value.toString().replace(/\D/, ''), { emitEvent: false });
    });
  }

  ngOnInit() {
    this.highlightId = this.activatedRoute.snapshot.params.highlightId;

    if (this.highlightId) {
      this.fetchHighlight();
    } else {
      this.fetchHighlightStatus = 'SUCCESS';
    }
  }

  ngOnDestroy() {
    if (this.unsubscribe$) {
      this.unsubscribe$.next(null);
      this.unsubscribe$.complete();
    }
  }

  fetchHighlight() {
    if (!this.highlightId) {
      return;
    }

    this.fetchHighlightStatus = 'LOADING';

    this.highlightsService
      .getHighlightById(this.highlightId, this.fetchHighlightQuery)
      .pipe(timeout(20_000), takeUntil(this.unsubscribe$))
      .subscribe({
        next: highlight => {
          this.highlight = highlight;
          this.populateHighlightForm();
          this.fetchHighlightStatus = 'SUCCESS';
        },
        error: (err: any) => {
          const errorMessage = err?.originalError?.message || 'Não foi possível baixar os dados do destaque. Tente novamente, por favor.';
          this.notificationService.error('Erro', errorMessage);
          this.fetchHighlightStatus = 'ERROR';
        }
      });
  }

  populateHighlightForm() {
    this.highlightForm.controls.title.setValue(this.highlight?.title);
    this.highlightForm.controls.description.setValue(this.highlight?.description);
    this.highlightForm.controls.presentationMode.setValue(this.highlight?.presentationMode);
    this.highlightForm.controls.link.setValue(this.highlight?.link);
    this.highlightForm.controls.linkLabel.setValue(this.highlight?.linkLabel);
    this.highlightForm.controls.startDate.setValue(this.highlight?.startDate);
    this.highlightForm.controls.endDate.setValue(this.highlight?.endDate);
    this.highlightForm.controls.isActive.setValue(this.highlight?.isActive);
    this.highlightForm.controls.position.setValue(this.highlight?.position);

    if (this.highlight?.whiteLabels?.length) {
      const whiteLabelsIds = this.highlight.whiteLabels.map(whiteLabel => whiteLabel._id);
      this.highlightForm.controls.whiteLabels.setValue(whiteLabelsIds);
      this.selectedWhiteLabels = this.highlight.whiteLabels;
    }

    if (this.highlight?.picture) {
      this.highlightForm.controls.picture.setValue(this.highlight?.picture);
      this.highlightUploadedPicture = this.highlight?.picture.url || '';
    }
  }

  handleRemoveSelectedPicture() {
    this.highlightForm.controls.picture.setValue(null);
    this.highlightUploadedPicture = null;
  }

  handlePictureInputChange(event: any) {
    const files = event.target.files;

    if (files?.length) {
      this.handleUploadPicture(files.item(0));
    }
  }

  handleUploadPicture(file: File) {
    this.isUploadingPicture = true;

    const formData = new FormData();
    formData.append(file.name || 'file', file);

    this.fileService.uploadFilesFromFormData(formData).subscribe({
      next: async ([uploadedPicture]) => {
        this.picture.setValue(uploadedPicture);

        const imageUrl = await readFileAsDataURL(file);
        this.highlightUploadedPicture = imageUrl;

        this.isUploadingPicture = false;
      },
      error: err => {
        this.handleRemoveSelectedPicture();
        this.isUploadingPicture = false;
      }
    });
  }

  handleSubmit() {
    if (this.highlightForm.invalid) {
      this.highlightForm.markAllAsTouched();
      this.notificationService.warning('Formulário inválido', 'Preencha todos os campos obrigatórios');
      return;
    }

    this.submitFormStatus = 'LOADING';

    const startDate = moment(this.highlightForm.value.startDate).startOf('day').toISOString();

    let endDate: string | null = null;

    if (this.highlightForm.value.endDate) {
      endDate = moment(this.highlightForm.value.endDate).endOf('day').toISOString();
    }

    const data: SaveHighlightDTO = {
      title: this.highlightForm.value.title,
      description: this.highlightForm.value.description,
      presentationMode: this.highlightForm.value.presentationMode,
      picture: this.highlightForm.value.picture?._id || null,
      link: this.highlightForm.value.link,
      linkLabel: this.highlightForm.value.linkLabel,
      whiteLabels: this.highlightForm.value.whiteLabels,
      startDate,
      endDate,
      position: this.highlightForm.value.position,
      isActive: this.highlightForm.value.isActive
    };

    const requestObservable = this.highlight
      ? this.highlightsService.updateHighlight(this.highlight._id, data)
      : this.highlightsService.createHighlight(data);

    requestObservable.pipe(timeout(10_000)).subscribe({
      next: () => {
        this.notificationService.success('Sucesso', 'Destaque cadastrado com sucesso.');
        this.submitFormStatus = 'SUCCESS';
        this.router.navigate(['home', 'features', 'highlights']);
      },
      error: err => {
        const errorMessage = err?.originalError?.message || 'Não foi possível salvar o destaque. Tente novamente, por favor.';
        this.notificationService.error('Erro', errorMessage);
      }
    });
  }

  handleSelectWhiteLabel(whiteLabel: WhiteLabel) {
    const selectedWhiteLabels: Array<WhiteLabel['_id']> = this.whiteLabels.value;
    const isWhiteLabelAlreadySelected = selectedWhiteLabels.includes(whiteLabel._id);

    if (!isWhiteLabelAlreadySelected) {
      this.whiteLabels.setValue([...selectedWhiteLabels, whiteLabel._id]);
      this.selectedWhiteLabels = [...this.selectedWhiteLabels, whiteLabel];
    }
  }

  handleRemoveSelectedWhiteLabel(whiteLabelToRemove: WhiteLabel) {
    const selectedWhiteLabels: Array<WhiteLabel['_id']> = this.whiteLabels.value.filter(
      (whiteLabelId: string) => whiteLabelId !== whiteLabelToRemove._id
    );

    this.whiteLabels.setValue(selectedWhiteLabels);

    this.selectedWhiteLabels = this.selectedWhiteLabels.filter(
      whiteLabel => whiteLabel._id !== whiteLabelToRemove._id
    );
  }

  handleIncreasePosition() {
    const currentPosition = this.position.value;
    this.position.setValue(currentPosition + 1, { emitEvent: false });
  }

  handleDecreasePosition() {
    const currentPosition = this.position.value;

    if (currentPosition > 1) {
      this.position.setValue(currentPosition - 1, { emitEvent: false });
    }
  }

  onBack() {
    this.router.navigate(['home', 'features', 'highlights']);
  }
}
