<nz-card class="p-4" *ngIf="fetchHighlightStatus === 'LOADING'">
  <nz-spin nzTip="Carregando destaque..."></nz-spin>
</nz-card>

<nz-card class="p-4" *ngIf="fetchHighlightStatus === 'ERROR'">
  <div style="text-align: center">
    <h2 nz-typography nzType="danger">Erro ao carregar destaque</h2>
    <h4 nz-typography>Ocorreu um erro ao carregar o destaque. Tente novamente, por favor.</h4>
    <button nz-button nzType="primary" (click)="fetchHighlight()">Tentar novamente</button>
  </div>
</nz-card>

<app-highlight-card class="w-full" [highlight]="highlight" *ngIf="fetchHighlightStatus === 'SUCCESS' && highlight"></app-highlight-card>
