import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Marketplace} from '../model/marketplace';
import {EcondosQuery} from "@api/model/query";
import {environment} from '@environment';

import * as qs from 'qs';

@Injectable({providedIn: 'root'})
export class MarketplaceService {
  //endpoint do backend usado para as funções
  private endPoint = `${environment.backendUrl}marketplaces/`;

  constructor(private http: HttpClient) {}

  //retorna uma lista dos marketplaces
  getMarketplaces(params?: EcondosQuery): Observable<{ count: number, marketplaces: Marketplace[] }> {
    const httpParams = new HttpParams({fromString: qs.stringify(params)});
    return this.http.get(`${this.endPoint}`, {
      params: httpParams,
      observe: 'response' as 'body'
    }).pipe(map((res: any) => ({
        count: res.headers.get('count'),
        marketplaces: res.body
      })
      )
    )
  }

  //retorna um marketplace específico com o id fornecido
  getMarketplaceById(partnerId: string, params?: EcondosQuery): Observable<Marketplace> {
    const httpParams = new HttpParams({fromString: qs.stringify(params)});
    return this.http.get(this.endPoint + partnerId,{
      params: httpParams,
      observe: 'response' as 'body'
    }).pipe(map((res: any) => (
      res.body
    )
    ))
  }

  //cria um novo marketplace com a informação enviada e retorna a informação dele
  createMarketplace(partner: Marketplace){
    return this.http.post(this.endPoint, partner)
  }

  //atualiza a informação do marketplace de Id fornecido com a informação fornecida, e retorna o usuário atualizado
  updateMarketplace(partnerId: string, partner: Marketplace){
    return this
    .http
    .put(this.endPoint + partnerId, partner)
  }

  //exclui o usuário com id fornecido retornando nada.
  deleteMarketplace(partnerId: string){
    return this
      .http
      .delete(this.endPoint + partnerId)
  }
}
