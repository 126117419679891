<nz-input-group nzSearch [nzAddOnAfter]="showSearchButton || isSearching ? nzAddOnAfterTemplate : undefined">
  <input
    type="text"
    placeholder="Busque por uma whitelabel"
    nz-input
    [(ngModel)]="inputValue"
    (input)="onChange($event)"
    [nzAutocomplete]="auto"
    [disabled]="filtering"
  />
  <nz-autocomplete #auto>
    <nz-auto-option
      *ngFor="let option of options$ | async"
      [nzValue]="option._id"
      [nzLabel]="option.name"
      (selectionChange)="handleSelectionChange($event, option)"
    >
      <nz-avatar nzShape="square" [nzSrc]="option.icon" [nzText]="option.companyName[0]" [nzAlt]="option.name"></nz-avatar>
      <span>{{ option.name }}</span>
    </nz-auto-option>
  </nz-autocomplete>
</nz-input-group>

<ng-template #nzAddOnAfterTemplate>
  <nz-spin nzSimple nzSize="small" *ngIf="isSearching"></nz-spin>

  <button
    *ngIf="!filtering && showSearchButton"
    nz-button
    nzType="primary"
    nzSearch
    (click)="handleSearch()"
    [disabled]="inputValue.length < 3 || !itemSelected"
  >
    <i nz-icon nzType="search"></i>
  </button>

  <button *ngIf="filtering" nz-button nzDanger>
    <i nz-icon nzType="close" nzTheme="outline" (click)="handleOnClose()"></i>
  </button>
</ng-template>
