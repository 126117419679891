<article
  class="highlight"
  [class.highlight--text-only]="highlight?.presentationMode === 'TEXT_ONLY'"
  [class.highlight--picture-only]="highlight?.presentationMode === 'PICTURE_ONLY'"
  [class.highlight--text-and-picture]="highlight?.presentationMode === 'TEXT_AND_PICTURE'">
  <img [src]="highlight?.picture?.url || highlight?.picture?.thumbnail" *ngIf="highlight?.picture" />

  <main>
    <h3>{{ highlight?.title }}</h3>
    <p *ngIf="highlight?.description">{{ highlight?.description }}</p>
  </main>
</article>
