import { Component, OnDestroy, OnInit } from '@angular/core';
import { Highlight } from '@api/model/highlight';
import { Subject } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query'
import {HighlightsService} from '@api/service/highlights.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

type Status = 'LOADING' | 'SUCCESS' | 'ERROR';

@Component({
  selector: 'app-modal-preview-highlight',
  templateUrl: 'modal-preview-highlight.component.html'
})
export class ModalPreviewHighlightComponent implements OnInit, OnDestroy {
  highlightId: Highlight['_id'] | null = null;

  highlight: Highlight | null = null;
  fetchHighlightStatus: Status = 'LOADING';

  fetchHighlightQuery: EcondosQuery = {
    $select: 'title description picture presentationMode',
    $populate: [{ path: 'picture', select: 'url thumbnail type format name' }]
  };

  unsubscribe$ = new Subject();

  constructor(
    private highlightsService: HighlightsService,
    private notificationService: NzNotificationService
  ) {}

  ngOnInit(): void {
    if (this.highlightId) {
      this.fetchHighlight();
    } else {
      this.fetchHighlightStatus = 'ERROR';
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.next(null);
      this.unsubscribe$.complete();
    }
  }

  fetchHighlight() {
    if (!this.highlightId) {
      return;
    }

    this.fetchHighlightStatus = 'LOADING';

    this.highlightsService
      .getHighlightById(this.highlightId, this.fetchHighlightQuery)
      .pipe(timeout(20_000), takeUntil(this.unsubscribe$))
      .subscribe({
        next: highlight => {
          this.highlight = highlight;
          this.fetchHighlightStatus = 'SUCCESS';
        },
        error: err => {
          const errorMessage = err?.originalError?.message || 'Não foi possível baixar os dados do destaque. Tente novamente, por favor.';
          this.notificationService.error('Erro', errorMessage);
          this.fetchHighlightStatus = 'ERROR';
        }
      });
  }
}
