import {Component} from '@angular/core';

@Component({
  templateUrl: 'no-permission-error-page.component.html'
})
export class NoPermissionErrorPageComponent {

  constructor() {
  }


}
