import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit {
  @Input() dropdownOptions: {label: string, value:string}[] = [];
  @Input() label: string = 'Filtre por plano';

  @Output() onClose = new EventEmitter();
  @Output() onFilter = new EventEmitter<string>();

  searchValue: string = '';
  filtering: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  handleOnClose() {
    this.filtering = false;
    this.searchValue = '';
    this.onClose.emit();
  }

  handleOnFilter() {
    this.filtering = true;
    this.onFilter.emit(this.searchValue);
  }


}
