import { Component, Input, OnInit } from '@angular/core';
import { CondoUser } from '@api/model/condo-user';
import { UserService } from '@api/service/user.service';
import { EcondosQuery } from '@api/model/query';
import { ActivatedRoute } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalEditEmailComponent } from '../../../components/modal-edit-email/modal-edit-email.component';
import { ModalResetUserPasswordComponent } from '../../../components/modal-reset-user-password/modal-reset-user-password.component';

@Component({
  selector: 'app-user-details-page',
  templateUrl: './user-details-page.component.html',
  styleUrls: ['./user-details-page.component.scss'],
})
export class UserDetailsPageComponent implements OnInit {
  @Input() userIdModal?: string;

  constructor(private userService: UserService, public route: ActivatedRoute, private ModalService: NzModalService) {}

  ngOnInit(): void {}

  showModalUpdateEmail(user?: CondoUser): void {
    const modal: NzModalRef = this.ModalService.create({
      nzTitle: 'Editar Email',
      nzContent: ModalEditEmailComponent,
      nzComponentParams: { user },
      nzFooter: [{ label: 'Close', type: 'primary', onClick: () => modal.destroy() }],
    });
  }

  showModalResetPassword(user?: CondoUser): void {
    const modal: NzModalRef = this.ModalService.create({
      nzTitle: 'Resetar Senha',
      nzContent: ModalResetUserPasswordComponent,
      nzComponentParams: { user },
      nzFooter: [{ label: 'Close', type: 'default', onClick: () => modal.destroy() }],
    });
  }
}
