import {Component, OnInit} from '@angular/core';
import {CondoUser} from "@api/model/condo-user";
import {UserService} from "@api/service/user.service";
import {EcondosQuery} from "@api/model/query";
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {

  users: CondoUser[] = [];
  searchByIdAndEmail = new FormControl('');
  page = 1;

  constructor(private userService: UserService,
      private notification: NzNotificationService) {
    this.searchByIdAndEmail.valueChanges.pipe(
        debounceTime(1400),
        distinctUntilChanged()
      ).subscribe(values => {
      this.getDataByIdAndEmail(values)
    })
  }

  ngOnInit(): void {

    const query: EcondosQuery = {
      $select: 'firstName lastName picture email',
    }
    this.getData(query);
  }

  getData(query: EcondosQuery) {
    this.userService.getAllUsers(query).subscribe(users => {
      this.users = users;
      this.page = 1;
    }, err => {
      this.notification.create('error',
      'Falha ao buscar dados',
      'Verifique os dados e tente novamente'
    )
    })
  }

  getDataByIdAndEmail(value: string){
    const query: EcondosQuery = {
      $select: 'firstName lastName picture email',
    }
    if (value.length == 24 && !value.includes('@')) {
      query._id = {$in: value} ;
    } else {
      query.email = {$regex: `^${value.toLowerCase()}`} ;
    }
    this.getData(query)
  }

  changePage(page: number){
    this.page = page
  }
}
