import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from "rxjs/operators";
import { UserService } from '@api/service/user.service';
import { EcondosQuery } from '@api/model/query';

@Injectable({providedIn: 'root'})
export class UserResolver implements Resolve<any> {

  constructor(private _router: Router,
              private userService: UserService) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<any> {
      const query: EcondosQuery = {
        $select: 'firstName lastName phones picture email createdBy isSystemAdmin ids residencesUser systemAdminAccessLevel condos condosOwner condosAdmin condosRequester condosGatekeeper condosJanitor condosLawyer',
        $populate: [
          { path: 'picture', select: 'thumbnail url type format name' },
          { path: 'picture', select: 'thumbnail url type format name' },
          { path: 'residencesUser', select: 'identification condo block number address name type' },
          { path: 'createdBy', select: 'firstName lastName' },
          { path: 'condos', select: 'name' },
          { path: 'condosOwner', select: 'name' },
          { path: 'condosAdmin', select: 'name' },
          { path: 'condosRequester', select: 'name' },
          { path: 'condosGatekeeper', select: 'name' },
          { path: 'condosJanitor', select: 'name' },
          { path: 'condosLawyer', select: 'name' },
      ]};

    const id = route.params.userId;
    return this.userService.getUser(id, query)
  }
}
