import { Component, OnInit, EventEmitter } from '@angular/core';
import { BuildMarketplace, Marketplace } from '@api/model/marketplace';
import { MarketplaceService } from '@api/service/marketplace.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EcondosQuery } from '@api/model/query';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { formatPhone } from '@api/utils'
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ModalEditMarketplaceComponent } from 'src/app/components/modal-edit-marketplace/modal-edit-marketplace.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PartnerService } from 'src/app/service/partner.service';
import { AdvertiserService } from '@api/service/advertiser.service';
import { Ad } from '@api/model/advertiser';

@Component({
  selector: 'app-marketplace-page',
  templateUrl: './marketplace-page.component.html',
  styleUrls: ['./marketplace-page.component.scss']
})
export class MarketplacePageComponent implements OnInit {

  marketplaces: Marketplace[] = [];
  isVisible = false;
  //id do parceiro para exclusão
  marketplaceId: string = "";

  constructor(
    private marketplaceService: MarketplaceService,
    private router: Router,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private activeRoute: ActivatedRoute,
    private partnerService: PartnerService,
    private advertiserService: AdvertiserService,
  ) {

  }

  ngOnInit(): void {
    if (this.activeRoute.snapshot.url[0]?.path=== 'new') {
      this.showModalEdit();
    }
    this.loadMarketplaces();
  }

  loadMarketplaces(): void {
    const query: EcondosQuery = {
      $select: 'name enabled blocked blockedReason startDate endDate typeCondo condos partners advertisers',
      $populate: [
        {path: 'advertisers', select: 'condo'},
      ],
      $sort: 'order'
    }
    
    this.marketplaceService.getMarketplaces(query).subscribe(response => {
      this.marketplaces = response.marketplaces;
    })
  }

  goToCreateMarketplace(): void{
    this.router.navigate(['/home/marketplaces/new']);
  }

  showModalEdit(marketplace: Marketplace = BuildMarketplace({})): void {
    const modal: NzModalRef = this.modalService.create({
      // nzTitle: 'Editar Email',
      nzContent: ModalEditMarketplaceComponent,
      nzComponentParams: { marketplace },
      nzWidth: '80%',
      nzCentered: true,
      nzMaskClosable: false,
      nzFooter: [{ label: 'Fechar', type: 'primary', onClick: () => {
        this.loadMarketplaces()
        modal.destroy()
      } }],
      nzOnCancel: () => {
        this.loadMarketplaces()
        modal.destroy()
      }
    });
  }

  //mostra o modal para exclusão de parceiro e defino o id do parceiro selecionado
  showModal(marketplaceId: string): void {
    this.marketplaceId = marketplaceId;
    this.isVisible = true;
  }

  //Cria uma mensagem de notificação
  createNotification(type: string, title: string, message: string): void {
    this.notification.create(
      type,
      title,
      message
    );
  }

  //handlers para modal de exclusão
  handleOk(): void {
    const marketplace = this.marketplaces.find(marketplace => marketplace._id === this.marketplaceId);
    console.log(marketplace);
    this.marketplaces = this.marketplaces.filter(marketplace => marketplace._id !== this.marketplaceId);

    marketplace?.partners?.forEach((partner: any) => {
      if(!partner)
        return;
      this.deletePartner(partner);
    })

    marketplace?.advertisers?.forEach((advertiser) => {
      this.deleteAdvertiser(advertiser as Ad)
    })

    this.deleteMarketplace();
  }

  deleteMarketplace(): void {
    this.marketplaceService.deleteMarketplace(this.marketplaceId).subscribe( (result: any) => {
      this.createNotification('success', "Parceiro Deletado", "Parceiro foi deletado com sucesso!");
      this.marketplaces = this.marketplaces.filter(marketplace => marketplace._id !== this.marketplaceId);
      this.isVisible = false;
      this.marketplaceId = "";
    }, (err: any) => {
      this.createNotification('error', 'Falha no processo', 'Houve um erro ao tentar deletar o parceiro!')
      console.log(err)
    });
  }

  deletePartner(partnerId: string): void {
    this.partnerService.deletePartner(partnerId).subscribe( (result: any) => {
      console.log(result)
    }, (err: any) => {
      this.createNotification('error', 'Falha no processo', 'Houve um erro ao tentar deletar o parceiro!')
      console.log(err)
    });
  }

  deleteAdvertiser(advertiser: Ad): void {
      const condoId = advertiser.condo || ''
      const advertiserId = advertiser._id || ''
      if(!advertiserId || !condoId)
        return;
      this.advertiserService.deleteAdvertiser({ advertiserId, condoId }).subscribe( (result: any) => {
        console.log(result)
      }, (err: any) => {
        this.createNotification('error', 'Falha no processo', 'Houve um erro ao tentar deletar o classificados!')
        console.log(err)
      });
    }

  handleCancel(): void {
    this.isVisible = false;
  }
}
