import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PLANS_LABELS, TYPES_LABELS } from '@api/model/condo';
import { generateCsv, generateReport } from '../../../util/report-generator';
import { EcondosQuery } from '@api/model/query';
import { SessionService } from '../../../service/session.service';
import { Component, OnInit } from '@angular/core';
import { CondoFinancialInfoService } from '@api/service/condo-financial-info.service';
import { CondoFinancialInfo } from '@api/model/condo-financial-info';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { formatCurrency } from '@api/utils';
import { ModalReadjustPriceComponent } from '../../../components/modal-readjust-price/modal-readjust-price.component';
import { addDays, differenceInDays, format, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

interface TableField {
  value: string;
  label: string;
}

interface TableData
  extends Omit<
    CondoFinancialInfo,
    'nextAdjustmentPercentage' | 'lastAdjustmentPercentage' | 'price'
  > {
  nextAdjustmentPercentage: string;
  lastAdjustmentPercentage: string;
  price: string;
  nextAdjustment: number;
  endDateOfTrial: string;
}

@Component({
  selector: 'app-condo-financial-info-page',
  templateUrl: './condo-financial-info-page.component.html',
  styleUrls: ['./condo-financial-info-page.component.scss'],
})
export class CondoFinancialInfoPageComponent implements OnInit {
  tableData: TableData[] = [];
  nzPageSize: number;
  nzPageIndex: number;
  totalData = 0;
  fields: TableField[];

  sortOrderIcon = {
    asc: 'up',
    desc: 'down',
  };

  selectedRow: { [key: string]: boolean } = {};
  selectedRowCount = 0;

  private searchWhitelabelValue: string = '';
  private searchCondoValue: string = '';
  private searchPlanValue: string = '';
  private searchTypeValue: string = '';
  currentSortData: { field: string; order: 'asc' | 'desc' } = {
    field: 'name',
    order: 'asc',
  };

  generalSearchOptions: { label: string; value: string }[];
  planOptions: { label: string; value: string }[] = [];
  typeOptions: { label: string; value: string }[] = [];

  showDeactivated = false;

  PLANS_LABELS = PLANS_LABELS;
  TYPES_LABELS = TYPES_LABELS;

  constructor(
    private sessionService: SessionService,
    private condoFinancialInfoService: CondoFinancialInfoService,
    private modal: NzModalService,
    private notificationService: NzNotificationService,
  ) {
    this.nzPageSize = 50;
    this.nzPageIndex = 0;
    this.fields = [
      { value: 'whiteLabel', label: 'WhiteLabel' },
      { value: 'condo', label: 'Condomínio' },
      { value: 'createdAt', label: 'Data de criação' },
      { value: 'residencesCount', label: 'Unidades' },
      { value: 'plan', label: 'Plano' },
      { value: 'type', label: 'Tipo' },
      { value: 'daysOfTrial', label: 'Dias de Trial' },
      { value: 'endDateOfTrial', label: 'Término do Trial' },
      { value: 'price', label: 'Preço' },
      { value: 'lastAdjustmentDate', label: 'Data Último Reajuste' },
      {
        value: 'lastAdjustmentPercentage',
        label: '% Último Reajuste',
      },
      {
        value: 'nextAdjustmentPercentage',
        label: '% Próximo Reajuste',
      },
      { value: 'nextAdjustmentDate', label: 'Data Próximo Reajuste' },
      { value: 'adjustmentIndex', label: 'Índice de Reajuste' },
      { value: 'obs', label: 'Observações' },
    ];
    this.generalSearchOptions = [
      {
        value: 'name',
        label: 'Nome',
      },
      {
        value: 'id',
        label: 'Id',
      },
      {
        value: 'cnpj',
        label: 'CNPJ',
      },
    ];
    this.planOptions = Object.keys(PLANS_LABELS).map((key) => ({
      label: PLANS_LABELS[key as keyof typeof PLANS_LABELS],
      value: key,
    }));
    this.typeOptions = Object.keys(TYPES_LABELS).map((key) => ({
      label: TYPES_LABELS[key as keyof typeof TYPES_LABELS],
      value: key,
    }));
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(sortBy?: string) {
    this.resetSelectedRow();
    // Não pode ter condo e whiteLabel no select
    const query: EcondosQuery = {
      $select:
        'residencesCount price lastAdjustmentDate lastAdjustmentPercentage nextAdjustmentDate nextAdjustmentPercentage adjustmentIndex obs',
      $populate: [
        { path: 'whiteLabel', select: 'name' },
        {
          path: 'condo',
          select: 'name createdAt daysOfTrial whiteLabel timeZone type plan',
        },
      ],
      $limit: this.nzPageSize,
      $page: this.nzPageIndex,
      isActive: true,
    };

    if (this.showDeactivated) {
      query.isActive = false;
    }

    if (sortBy) {
      if (this.currentSortData.field === sortBy) {
        this.currentSortData.order === 'asc'
          ? (this.currentSortData.order = 'desc')
          : (this.currentSortData.order = 'asc');
      } else {
        this.currentSortData.field = sortBy;
        this.currentSortData.order = 'asc';
      }
    } else {
      this.currentSortData = { field: 'createdAt', order: 'desc' };
    }
    if (this.searchPlanValue) {
      query['condo.plan.name'] = this.searchPlanValue;
    }
    if (this.searchTypeValue) {
      query['condo.type'] = this.searchTypeValue;
    }
    if (this.searchWhitelabelValue) {
      query['whiteLabel'] = this.searchWhitelabelValue;
    }
    if (this.searchCondoValue) {
      query.condo = this.searchCondoValue;
    }

    query.$sort = `${this.currentSortData.order === 'desc' ? '-' : ''}${this.currentSortData.field}`;

    this.condoFinancialInfoService.get(query).subscribe(
      ({ condoFinancialInfo, count }) => {
        this.tableData = condoFinancialInfo.map((data) => {
          const createdAt = toZonedTime(
            data.condo.createdAt,
            data.condo.timeZone,
          );
          const endDateOfTrial =
            format(addDays(createdAt, data.condo.daysOfTrial), 'dd/MM/yyyy') ||
            '-';
          const lastAdjustmentDate = data.lastAdjustmentDate
            ? format(
                toZonedTime(data.lastAdjustmentDate, data.condo.timeZone),
                'dd/MM/yyyy',
              )
            : '-';
          const nextAdjustmentDate = data.nextAdjustmentDate
            ? format(
                toZonedTime(data.nextAdjustmentDate, data.condo.timeZone),
                'dd/MM/yyyy',
              )
            : '-';
          const lastAdjustmentPercentage = data.lastAdjustmentPercentage
            ? `${data.lastAdjustmentPercentage + ' %'}`
            : '-';
          const nextAdjustmentPercentage = data.nextAdjustmentPercentage
            ? `${data.nextAdjustmentPercentage + ' %'}`
            : '-';
          const nextAdjustment = data.nextAdjustmentDate
            ? differenceInDays(
                toZonedTime(data.nextAdjustmentDate, data.condo.timeZone),
                toZonedTime(startOfDay(new Date()), data.condo.timeZone),
              )
            : 0;
          const price = data.price ? formatCurrency(data.price) : '-';
          return {
            ...data,
            endDateOfTrial,
            nextAdjustmentDate,
            lastAdjustmentPercentage,
            nextAdjustmentPercentage,
            lastAdjustmentDate,
            nextAdjustment,
            price,
          };
        });

        this.totalData = Number(count);
      },
      (err) => {
        console.log(err);
      },
    );
  }

  deleteCondosFincancialInfo(condosFincancialInfo: TableData) {
    this.modal.confirm({
      nzTitle: 'Você tem certeza?',
      nzContent: `Deseja realmente excluir o cadastro de informações financeiras do condomínio ${condosFincancialInfo.condo.name}?`,
      nzOkText: 'Prosseguir',
      nzCancelText: 'Cancelar',
      nzOnOk: () => {
        this.condoFinancialInfoService
          .delete(condosFincancialInfo._id)
          .subscribe({
            next: () => {
              this.notificationService.create(
                'success',
                'Sucesso',
                'Cadastro deletado com sucesso',
              );
              this.tableData = this.tableData.filter(
                (data) => data._id !== condosFincancialInfo._id,
              );
            },
            error: (res) => {
              this.notificationService.create(
                'error',
                'Erro',
                res?.error?.message || 'Não foi possível deletar!',
              );
            },
          });
      },
    });
  }

  resetSelectedRow(): void {
    Object.keys(this.selectedRow).forEach((key) => {
      this.selectedRow[key] = false;
    });
    this.selectedRowCount = 0;
  }

  toggleSelectedRow(isActive: boolean) {
    let condoFinancialInfoIds: string[] = [];
    this.tableData.forEach((data) => {
      if (this.selectedRow[data._id]) {
        condoFinancialInfoIds.push(data._id);
      }
    });

     const observable = isActive
       ? this.condoFinancialInfoService.activate(condoFinancialInfoIds)
       :  this.condoFinancialInfoService.deactivate(condoFinancialInfoIds);

    observable.subscribe({
      next: () => {
        this.tableData = this.tableData.filter(
          (data) => !condoFinancialInfoIds.includes(data._id),
        );
        this.notificationService.create(
          'success',
          'Sucesso',
          `Cadastro(s) ${isActive ? 'ativado(s)' : 'desativado(s)'} com sucesso`,
        );
      },
      error: (err) => {
        this.notificationService.create(
          'error',
          'Erro',
          err?.error?.message ||
            `Não foi possível ${isActive ? 'ativar' : 'desativar'} os cadastros!`,
        );
      },
    });
  }

  countSelectedRows() {
    this.selectedRowCount = 0;
    this.selectedRowCount = Object.keys(this.selectedRow)
      .filter(key => this.selectedRow[key])
      .length;

  }

  openReadjustModal(): void {
    let condoFinancialInfoToReadjust: string[] = [];
    this.tableData.forEach((data) => {
      if (this.selectedRow[data._id]) {
        condoFinancialInfoToReadjust.push(data._id);
      }
    });

    if (!condoFinancialInfoToReadjust.length) {
      this.notificationService.create(
        'warning',
        'Aviso',
        'Seleciona ao menos um item da tabela',
      );
      return;
    }

    const onAfterSafe = () => {
      this.getData();
    };

    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Ajuste de preço',
      nzContent: ModalReadjustPriceComponent,
      nzComponentParams: { condoFinancialInfoToReadjust, onAfterSafe },
      nzWidth: 320,
      nzFooter: null,
      nzBodyStyle: { padding: '0' },
      nzClosable: false,
    });
  }

  refreshIndexChange(value: any) {
    this.nzPageIndex = --value;
    this.getData();
  }

  refreshSizeChange(value: any) {
    this.nzPageSize = value;
    this.getData();
  }

  exportAsPDF(fields: TableField[], tableData: TableData[]) {
    const data = this.handleData(tableData);
    generateReport(
      'Cadastros_Financeiros_Condomínios',
      fields,
      data,
      this.sessionService.user,
      { orientation: 'LANDSCAPE' },
    );
  }

  exportAsCsv(fields: TableField[], tableData: TableData[]) {
    const data = this.handleData(tableData);
    const encodeUri = generateCsv(fields, data);
    const link = document.createElement('a');
    link.setAttribute('href', encodeUri);
    link.setAttribute('download', `Cadastros_Financeiros_Condomínios.csv`);
    link.click();
  }

  handleData(tableData: TableData[]) {
    return tableData.map((data) => ({
      ...data,
      condo: data.condo.name,
      createdAt: new Date(data.condo.createdAt).toLocaleDateString(),
      plan: PLANS_LABELS[data.condo.plan.name] || '',
      whiteLabel: data?.whiteLabel?.name || 'Nenhum',
      type: TYPES_LABELS[data.condo.type],
      daysOfTrial: data.condo.daysOfTrial,
    }));
  }

  handleSearchPlanFilter(value: string) {
    this.searchPlanValue = value;
    this.getData();
  }

  closeSearchPlanFilter() {
    this.searchPlanValue = '';
    this.getData();
  }

  handleSearchTypeFilter(value: string) {
    this.searchTypeValue = value;
    this.getData();
  }

  closeSearchTypeFilter() {
    this.searchTypeValue = '';
    this.getData();
  }

  handleWhitelabelFilter(value: string) {
    this.searchWhitelabelValue = value;
    this.getData();
  }

  closeWhitelabelFilter() {
    this.searchWhitelabelValue = '';
    this.getData();
  }

  handleCondoFilter(value: string) {
    this.searchCondoValue = value;
    this.getData();
  }

  closeCondoFilter() {
    this.searchCondoValue = '';
    this.getData();
  }

  getSortedData(field: string) {
    this.currentSortData.field = field;
    this.getData(field);
  }
}
