<!-- Cabeçalho da página -->
<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a [routerLink]="['/home/dashboard']">
        <em nz-icon nzType="home"></em>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <em nz-icon nzType="contacts"></em>
      Parceiros
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</nz-page-header>

<!-- Botão que leva a página de criação -->
<div class="create-button">

  <button
    nz-button
    [nzType]="'primary'"
    (click)="goToCreatePartner()"
    [nzSize]="'large'"
  >

    <span>Criar Novo Parceiro</span>

  </button>

</div>
<!--Tabela para dados dos parceiros-->
<nz-table
  #basicTable
  [nzData]="partners"
  [nzScroll]="{ x: '1200px' }"
  [nzHideOnSinglePage]="true"
  [nzPageSize]="10"
  nzBordered
>

  <thead>

    <tr>
      <th nzWidth="120px" scope="col-logo">Logo</th>
      <th nzWidth="150px" scope="col-name">Nome</th>
      <th nzWidth="120px" scope="col-tags">Tags</th>
      <th nzWidth="140px" scope="col-name">Telefones</th>
      <th nzWidth="180px" scope="col-name">Emails</th>
      <th class="col-description" scope="col-description">Descrição</th>
      <th nzWidth="220px" scope="col-name">Status</th>
      <th nzWidth="75px"scope="col-action">Ações</th>
    </tr>

  </thead>

  <tbody>

    <tr *ngFor="let data of basicTable.data">

      <td>
        <div class='partner-logo' >
          <img src={{data.logo}} alt="Logo da empresa com link">
        </div>
      </td>

      <td>
        <a href={{data.url}}
          target="_blank"
          nz-tooltip
          nzTooltipTitle="Acessar site"
          style="font-size: 1rem;"
        >
          {{data.name}}
        </a>
      </td>

      <td>
        <nz-tag nzColor="warning" *ngFor="let tag of data.tags" style="margin-top: 5px;">
        {{tag}}
        </nz-tag>
      </td>

      <td>
        <nz-list>
          <nz-list-item *ngFor="let phone of data.phones">
            {{phone}}
          </nz-list-item>
        </nz-list>
      </td>

      <td>
        <nz-list>
          <nz-list-item *ngFor="let email of data.emails">
            {{email}}
          </nz-list-item>
        </nz-list>
      </td>

      <td class="description-partners">
        <p>{{data.description}}</p>
      </td>

      <td>
        <p>
          {{ data.enabled? 'Ativo' : 'Inativo' }}    
          <br> {{ data.enabledInWhitelabel ? 'Ativo em whitelabel' : 'Inativo em whitelabel' }}
          <br> Data de início: {{ data.startDate | date: 'dd/MM/yyyy' }}
          <br> Data de finalização: {{ data.endDate  | date: 'dd/MM/yyyy' }}</p>        
      </td>

      <td class="actions-partners">
        <em nz-icon
          nzType="delete"
          nzTheme="outline"
          style="font-size: 1.2rem; color: #ff0000;"
          (click)="showModal(data._id!)"
          nz-tooltip
          nzTooltipTitle="Excluir parceiro"
        >
        </em>

        <nz-divider nzType="horizontal"></nz-divider>

        <em nz-icon
          nzType="edit"
          nzTheme="outline"
          style="font-size: 1.2rem; color: #0000cd;"
          [routerLink]="['/home/partners', data._id, 'edit']"
          nz-tooltip
          nzTooltipTitle="Editar parceiro"
        >
        </em>
      </td>
      <!-- Links para seção de edição ou exclusão do parceiro selecionado -->

    </tr>

  </tbody>
</nz-table>

<!-- Modal para confirmar a exclusão do parceiro -->
<nz-modal [(nzVisible)]="isVisible" nzTitle="Confirmar" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <p>Tem certeza de que deseja excluir este parceiro?</p>
  </ng-container>
</nz-modal>
