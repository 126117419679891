import { Condo } from './condo';
import { File } from './file';
import { User } from './user';
import { WhiteLabel } from './white-label';

export type Highlight = {
  _id: string;
  condo: Condo | null;
  whiteLabels: WhiteLabel[];
  title?: string;
  description?: string;
  picture?: File;
  presentationMode: 'TEXT_ONLY' | 'TEXT_AND_PICTURE' | 'PICTURE_ONLY';
  link?: string;
  linkLabel?: string;
  startDate: Date;
  endDate?: Date;
  position: number;
  clicksCount: number;
  isActive: boolean;
  createdBy: User;
  createdAt: Date;
  updatedBy?: User;
  updatedAt?: Date;
};

export const BuildHighlight = (data: Partial<Highlight>): Highlight => {
  return {
    _id: typeof data === 'string' ? data : data._id || '',
    condo: data.condo || null,
    whiteLabels: data.whiteLabels || [],
    title: data.title,
    description: data.description,
    picture: data.picture,
    presentationMode: data.presentationMode || 'TEXT_AND_PICTURE',
    link: data.link,
    linkLabel: data.linkLabel,
    startDate: new Date(data.startDate!),
    endDate: data.endDate && new Date(data.endDate),
    position: data.position ?? 1,
    clicksCount: data.clicksCount ?? 0,
    isActive: data.isActive ?? true,
    createdBy: data.createdBy!,
    createdAt: new Date(data.createdAt!),
    updatedBy: data.updatedBy,
    updatedAt: data.updatedAt && new Date(data.updatedAt)
  };
};

export const HIGHLIGHT_PRESENTATION_MODE_INFO: Record<Highlight['presentationMode'], Record<'label' | 'description', string>> = {
  TEXT_ONLY: {
    label: 'Somente texto',
    description: 'Somente o título e descrição serão exibidos no destaque.'
  },
  PICTURE_ONLY: {
    label: 'Somente imagem',
    description: 'Somente a imagem será exibida no destaque.'
  },
  TEXT_AND_PICTURE: {
    label: 'Texto e imagem',
    description: 'A imagem será alocado no fundo do destaque e ambos o título e descrição serão exibidos por cima.'
  }
};
