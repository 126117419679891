<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a [routerLink]="['/home/dashboard']">
        <em nz-icon nzType="home"></em>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="['/home/partners']">
        <em nz-icon nzType="contacts"></em> Parceiros
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      {{
      validateForm.get("_id")?.value ?? ""
      ? "Atualizar Parceiro"
      : "Criar Parceiro"
      }}
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</nz-page-header>

<div class="form-container">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <nz-row>
      <!-- Coluna esquerda -->
      <div nz-col [nzXs]="{ span: 20, offset: 2 }" [nzSm]="{ span: 20, offset: 2 }" [nzMd]="{ span: 20, offset: 2 }"
        [nzLg]="{ span: 10, offset: 0 }" [nzXl]="{ span: 10, offset: 0 }">
        <!-- Nome da Empresa -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired nzFor="name">Nome da empresa</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Informe nome da empresa parceira!">
            <input nz-input formControlName="name" id="name" />
          </nz-form-control>
        </nz-form-item>

        <!-- URL -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired nzFor="url">Site da empresa</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Informe o site da empresa parceira!">
            <input nz-input formControlName="url" id="url" />
          </nz-form-control>
        </nz-form-item>

        <!-- Logo -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired nzFor="logo">Logo da empresa</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Informe a URL da logo da empresa parceira!">
            <input nz-input formControlName="logo" id="logo" />
          </nz-form-control>
        </nz-form-item>

        <!-- Descrição -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired nzFor="description">Descrição da empresa</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Informe uma descrição da empresa parceira!">
            <textarea nz-input formControlName="description" id="description" rows="3"></textarea>
          </nz-form-control>
        </nz-form-item>

        <!-- Ordem -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired nzFor="order">Ordem de exibição no menu</nz-form-label>
          <nz-form-control [nzSpan]="24" nzErrorTip="Informe a ordem de exibição no menu!">
            <input type="number" nz-input formControlName="order" id="order" />
          </nz-form-control>
        </nz-form-item>
      </div>

      <!-- Coluna direita -->
      <div nz-col
      [nzXs]="{ span: 20, offset: 2 }"
      [nzSm]="{ span: 20, offset: 2 }"
      [nzMd]="{ span: 20, offset: 2 }"
        [nzLg]="{ span: 10, offset: 2 }"
        [nzXl]="{ span: 10, offset: 2 }">
        <!-- Tags -->
        <ng-container formArrayName="tags">
          <nz-form-item *ngFor="let _ of tags?.controls; let i = index; let first = first">
            <nz-form-label [nzSpan]="24" nzRequired nzFor="tags" *ngIf="i === 0">
              Tags da empresa
            </nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Informe ao menos uma tag para a empresa parceira!">
              <input class="w-90 mr-10" nz-input [formControlName]="i" />
              <em nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="tags.removeAt(i)"></em>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <nz-form-item>
          <nz-form-control nzSpan="16">
            <button nz-button nzType="dashed" class="add-button" type="button" (click)="addTag()">
              <em nz-icon nzType="plus"></em> Adicionar tag
            </button>
          </nz-form-control>
        </nz-form-item>

        <!-- Emails -->
        <ng-container formArrayName="emails">
          <nz-form-item *ngFor="let _ of emails?.controls; let i = index; let first = first">
            <nz-form-label [nzSpan]="24" nzRequired nzFor="emails" *ngIf="i === 0">
              Emails da empresa
            </nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Informe ao menos um email válido para a empresa parceira!">
              <input nz-input class="w-90 mr-10" [formControlName]="i" />
              <em nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="emails.removeAt(i)"></em>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <nz-form-item>
          <nz-form-control nzSpan="16">
            <button nz-button nzType="dashed" class="add-button" type="button" (click)="addEmail()">
              <em nz-icon nzType="plus"></em> Adicionar email
            </button>
          </nz-form-control>
        </nz-form-item>

        <!-- Telefones -->
        <ng-container formArrayName="phones">
          <nz-form-item *ngFor="let _ of phones?.controls; let i = index; let first = first">
            <nz-form-label [nzSpan]="24" nzRequired nzFor="phones" *ngIf="i === 0">
              Telefones da empresa
            </nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Informe ao menos um telefone válido para a empresa parceira!">
              <input nz-input class="w-90 mr-10" [formControlName]="i" />
              <em nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="phones.removeAt(i)"></em>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <nz-form-item>
          <nz-form-control nzSpan="16">
            <button nz-button nzType="dashed" class="add-button" type="button" (click)="addPhone()">
              <em nz-icon nzType="plus"></em> Adicionar telefone
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </nz-row>
    <hr />
    <nz-row>
      <!-- Coluna Esquerda 2a parte -->
      <div nz-col
        [nzXs]="{ span: 20, offset: 2 }"
        [nzSm]="{ span: 20, offset: 2 }"
        [nzMd]="{ span: 20, offset: 2 }"
        [nzLg]="{ span: 10, offset: 0 }"
        [nzXl]="{ span: 10, offset: 0 }">
        
        <!-- URL de Ação -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="actionUrl">URL de Ação</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="actionUrl" id="actionUrl" />
          </nz-form-control>
        </nz-form-item>
        
        <!-- Parâmetros de header -->
        <ng-container formArrayName="headerParams">
          <nz-form-item *ngFor="let _ of headerParams?.controls; let i = index; let first = first">
            <nz-form-label [nzSpan]="24"  nzFor="headerParams" *ngIf="i === 0">
              Adicionar Parâmetros de Header
            </nz-form-label>
            <nz-form-control [nzSpan]="24" nzErrorTip="Informe ao menos um email válido para a empresa parceira!">
              <input nz-input class="w-90 mr-10" [formControlName]="i" />
              <em nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="headerParams.removeAt(i)"></em>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <nz-form-item>
          <nz-form-control nzSpan="16">
            <button nz-button nzType="dashed" class="add-button" type="button" (click)="addHeaderParams()">
              <em nz-icon nzType="plus"></em> Adicionar Parâmetros de Header
            </button>
          </nz-form-control>
        </nz-form-item>

        <!-- Tipo de Ação -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="actionType">Tipo de Ação</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-radio-group formControlName="actionType" id="actionType">
              <label nz-radio nzValue="GET">GET</label>
              <label nz-radio nzValue="POST">POST</label>
              <label nz-radio nzValue="OPTIONS">OPTIONS</label>
              <label nz-radio nzValue="PUT">PUT</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>

        <!-- Data JSON de envio -->
        <nz-form-item *ngIf="validateForm.get('actionType')?.value !== 'GET'">
          <nz-form-label [nzSpan]="24" nzFor="dataToSent">Data JSON de envio</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <textarea nz-input formControlName="dataToSent" id="dataToSent" rows="5"></textarea>
          </nz-form-control>
        </nz-form-item>
        

        <!-- Redirect via retorno de api? -->
        <nz-form-item>
          <nz-form-control [nzSpan]="24">
            <label nz-checkbox formControlName="redirectViaCallback">Redirect via parâmetro de retorno do request? (externo)</label>
          </nz-form-control>
        </nz-form-item>


        <!-- Redirect de url via callback -->
        <nz-form-item *ngIf="validateForm.get('redirectViaCallback')?.value === true">
          <nz-form-label [nzSpan]="24" nzFor="urlCallback">Redirect de URL (callback)</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="urlCallback" id="urlCallback" />
          </nz-form-control>
        </nz-form-item>

        <!-- Termos de uso padrão -->
        <nz-form-item>
          <nz-form-control [nzSpan]="24">
            <label nz-checkbox formControlName="defaultTermsOfUse">Termos de uso padrão econdos? (Verificar disponibilidade)</label>
          </nz-form-control>
        </nz-form-item>

        <!-- URL Termos de Uso -->
        <nz-form-item *ngIf="validateForm.get('defaultTermsOfUse')?.value !== true">
          <nz-form-label [nzSpan]="24" nzFor="termsOfUseUrl">URL dos Termos de Uso</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="termsOfUseUrl" id="termsOfUseUrl" />
          </nz-form-control>
        </nz-form-item>

        <!-- Termos de uso customizado -->
        <nz-form-item *ngIf="validateForm.get('defaultTermsOfUse')?.value !== true">
          <nz-form-label [nzSpan]="24" nzFor="termsOfUseText">Texto de termos de uso customizado</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <editor
              id="termsOfUseText"
              [apiKey]="apiKey"
              formControlName="termsOfUseText"
              [init]="tinymceBaseConfig">
            </editor>
          </nz-form-control>
        </nz-form-item>

        </div>
        <div nz-col
        [nzXs]="{ span: 20, offset: 2 }"
        [nzSm]="{ span: 20, offset: 2 }"
        [nzMd]="{ span: 20, offset: 2 }"
        [nzLg]="{ span: 10, offset: 2 }"
        [nzXl]="{ span: 10, offset: 2 }">

        <!-- Ativado -->
        <nz-form-item>
          <nz-form-control [nzSpan]="24">
            <label nz-checkbox formControlName="enabled">Ativar Parceiro?</label>
          </nz-form-control>
        </nz-form-item>

        <!-- Ativar em WhiteLabel -->
        <nz-form-item>
          <nz-form-control [nzSpan]="24">
            <label nz-checkbox formControlName="enabledInWhitelabel">Ativar em WhiteLabel?</label>
          </nz-form-control>
        </nz-form-item>
        
        <!-- Data de Início -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="startDate">Data de início</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-date-picker formControlName="startDate"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <!-- Data de Fim -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="endDate">Data de fim</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-date-picker formControlName="endDate"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <!-- Botões de Ação -->
        <nz-form-item>
          <nz-form-control nzSpan="24">
            <button nz-button nzType="default" class="action-button" (click)="cancel()">
              Cancelar
            </button>
            <button nz-button nzType="primary" class="action-button" [disabled]="!validateForm.valid">
              {{
              validateForm.get("_id")?.value ?? ""
              ? "Atualizar Parceiro"
              : "Criar Parceiro"
              }}
            </button>
          </nz-form-control>
        </nz-form-item>

        <p>Instruções de uso:</p>
        <p>Em qualquer campo você poderá utilizar variáveis que colocará os dados do cliente, lembrando que só funcionará se estiverem entre pipes |.</p>
        <p>Campos de usuário disponível:</p>
        <ul>
          <li>|cpf|</li>
          <li>|rg|</li>
          <li>|phone|</li>
          <li>|email|</li>
          <li>|fullName|</li>
          <li>|firstName|</li>
          <li>|lastName|</li>
          <li>|maritalStatus|</li>
          <li>|birthdate|</li>
          <li>|userId|</li>
        </ul>
        <p>Campos de condomínio disponível:</p>
        <ul>
          <li>|condoId|</li>
          <li>|country|</li>
          <li>|state|</li>
          <li>|city|</li>
          <li>|neighborhood|</li>
          <li>|address|</li>
          <li>|street|</li>
          <li>|number|</li>
          <li>|cep|</li>
          <li>|zipCode|</li>
        </ul>
        <p>Redirect de URL (callback), será inserido por meio de chaves "{{ '{}' }}", isso sinalizando que o funcionamento se dará apenas para retorno de url via api do parceiro.</p>
        <p>Ex: O retorno do POST da api do parceiro é: {{ '{"url": "http://www.url.com.br"}'}}</p>
        <p>Então, você deverá inserir a url de retorno da seguinte forma: {{ '{url}'}}</p>
      </div>
    </nz-row>
  </form>
</div>