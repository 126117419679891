<div nz-row>
  <div nz-col nzSpan="8">
    <ul nz-menu *ngFor="let item of menuItems" >
      <li nz-menu-item (click)="goToStep(item)" [nzSelected]="currentStep === item.step" [nzDisabled]="item.needEditMode && !editMode">
        <i nz-icon nzType="{{item.icon}}" class="anticon anticon-{{item.icon}}"></i>
        {{item.title}}
      </li>
    </ul>
  </div>
  <nz-spin nz-col nzSpan="16" [nzSpinning]="loading.marketplace" [nzTip]="'Carregando...'"
  >
    <div nz-col>
      <!-- Configurações Iniciais -->
      <ng-container *ngIf="currentStep === 'configurations' || currentStep === ''">
        <nz-card nzTitle="Configurações Gerais" [nzBordered]="false">
          <form [formGroup]="marketplaceForm">
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzRequired nzFor="name">Nome</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Inclua o nome do anúncio">
                <input nz-input formControlName="name" id="name" nzRequired placeholder="Inclua o nome do anúncio" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="enabled">Ativar</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <label nz-checkbox formControlName="enabled">Está ativo?</label>
              </nz-form-control>
            </nz-form-item>
            
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="whitelabels">Whitelabels</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <nz-select formControlName="whitelabels" id="whitelabels" nzPlaceHolder="Selecione os whitelabels" (ngModelChange)="getCondos()">
                  <nz-option *ngFor="let whitelabel of whitelabels" [nzLabel]="whitelabel.companyName" [nzValue]="whitelabel._id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="typeCondo">Tipo de Condomínio</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <nz-select formControlName="typeCondo" id="typeCondo" nzPlaceHolder="Selecione o tipo de condomínio" (ngModelChange)="getCondos()">
                  <nz-option *ngFor="let typeCondoOption of typeCondoOptions" [nzLabel]="typeCondoOption.label" [nzValue]="typeCondoOption.value" ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="condos">Aparecer em</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <nz-select formControlName="condos" id="condos" nzMode="multiple" nzPlaceHolder="Selecione os condomínios">
                  <nz-option *ngFor="let condo of condos" [nzLabel]="condo.name" [nzValue]="condo._id"></nz-option>
                </nz-select>
                <small>Selecione os condomínios que o anúncio irá aparecer, em caso de nenhum selecionado, aparecerá em todos os condomínios.</small>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="marketplaceForm.get('blocked')?.value === true">
              <nz-form-label [nzSpan]="6" nzFor="blocked">Bloqueado</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <label nz-checkbox formControlName="blocked"  [disabled]="marketplaceForm.get('blocked')?.value">Está bloqueado?</label>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="marketplaceForm.get('blocked')?.value === true">
              <nz-form-label [nzSpan]="6" nzFor="blockedReason">Razão de bloqueio</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Máximo de 500 caracteres">
                <textarea nz-input formControlName="blockedReason" id="blockedReason" [disabled]="marketplaceForm.get('blocked')?.value" rows="3" placeholder="Razão para bloqueio"></textarea>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="startDate">Início dos anúncios</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Por favor, selecione uma data">
                <nz-date-picker nzFormat="dd/MM/yyyy" formControlName="startDate" id="startDate"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="endDate">Data final</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <nz-date-picker nzFormat="dd/MM/yyyy" formControlName="endDate" id="endDate"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control [nzSpan]="14" [nzOffset]="6">
                <button nz-button nzType="primary" (click)="marketplaceFormSave()" *ngIf="!editMode" [disabled]="!marketplaceForm.valid">Salvar</button>
                <button nz-button nzType="primary" (click)="marketplaceFormSave()" *ngIf="editMode" [disabled]="!marketplaceForm.valid">Atualizar</button>
              </nz-form-control>
            </nz-form-item>
          </form>
        </nz-card>

      </ng-container>
      <!-- /Configurações Iniciais -->
      <!-- Parceiros -->
      <ng-container *ngIf="currentStep === 'partners'">
        <nz-card nzTitle="Parcerias" [nzBordered]="false">
          <form nz-form [formGroup]="partnerForm">
            <nz-row>
              <!-- Coluna esquerda -->
              <div nz-col [nzXs]="{ span: 20, offset: 2 }" [nzSm]="{ span: 20, offset: 2 }" [nzMd]="{ span: 20, offset: 2 }"
                [nzLg]="{ span: 10, offset: 0 }" [nzXl]="{ span: 10, offset: 0 }">
                <!-- Nome da Empresa -->
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzRequired nzFor="name">Nome da empresa</nz-form-label>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Informe nome da empresa parceira!">
                    <input nz-input formControlName="name" id="name" />
                  </nz-form-control>
                </nz-form-item>
        
                <!-- URL -->
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzRequired nzFor="url">Site da empresa</nz-form-label>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Informe o site da empresa parceira!">
                    <input nz-input formControlName="url" id="url" />
                  </nz-form-control>
                </nz-form-item>
        
                <!-- Logo -->
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzRequired nzFor="logo">Logo da empresa</nz-form-label>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Informe a URL da logo da empresa parceira!">
                    <input nz-input formControlName="logo" id="logo" />
                  </nz-form-control>
                </nz-form-item>
        
                <!-- Descrição -->
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzRequired nzFor="description">Descrição da empresa</nz-form-label>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Informe uma descrição da empresa parceira!">
                    <textarea nz-input formControlName="description" id="description" rows="3"></textarea>
                  </nz-form-control>
                </nz-form-item>
        
                <!-- Ordem -->
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzRequired nzFor="order">Ordem de exibição no menu</nz-form-label>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Informe a ordem de exibição no menu!">
                    <input type="number" nz-input formControlName="order" id="order" />
                  </nz-form-control>
                </nz-form-item>
              </div>
        
              <!-- Coluna direita -->
              <div nz-col
              [nzXs]="{ span: 20, offset: 2 }"
              [nzSm]="{ span: 20, offset: 2 }"
              [nzMd]="{ span: 20, offset: 2 }"
                [nzLg]="{ span: 10, offset: 2 }"
                [nzXl]="{ span: 10, offset: 2 }">
                <!-- Tags -->
                <ng-container formArrayName="tags">
                  <nz-form-item *ngFor="let _ of partnerTags?.controls; let i = index; let first = first">
                    <nz-form-label [nzSpan]="24" nzRequired nzFor="tags" *ngIf="i === 0">
                      Tags da empresa
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Informe ao menos uma tag para a empresa parceira!">
                      <input class="w-90 mr-10" nz-input [formControlName]="i" />
                      <em nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="partnerTags.removeAt(i)"></em>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <nz-form-item>
                  <nz-form-control nzSpan="16">
                    <button nz-button nzType="dashed" class="add-button" type="button" (click)="_addPartnerTag()">
                      <em nz-icon nzType="plus"></em> Adicionar tag
                    </button>
                  </nz-form-control>
                </nz-form-item>
        
                <!-- Emails -->
                <ng-container formArrayName="emails">
                  <nz-form-item *ngFor="let _ of partnerEmails?.controls; let i = index; let first = first">
                    <nz-form-label [nzSpan]="24" nzRequired nzFor="emails" *ngIf="i === 0">
                      Emails da empresa
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Informe ao menos um email válido para a empresa parceira!">
                      <input nz-input class="w-90 mr-10" [formControlName]="i" />
                      <em nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="partnerEmails.removeAt(i)"></em>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <nz-form-item>
                  <nz-form-control nzSpan="16">
                    <button nz-button nzType="dashed" class="add-button" type="button" (click)="_addPartnerEmail()">
                      <em nz-icon nzType="plus"></em> Adicionar email
                    </button>
                  </nz-form-control>
                </nz-form-item>
        
                <!-- Telefones -->
                <ng-container formArrayName="phones">
                  <nz-form-item *ngFor="let _ of partnerPhones?.controls; let i = index; let first = first">
                    <nz-form-label [nzSpan]="24" nzRequired nzFor="phones" *ngIf="i === 0">
                      Telefones da empresa
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Informe ao menos um telefone válido para a empresa parceira!">
                      <input nz-input class="w-90 mr-10" (input)="onPhoneInput($event)" placeholder="(XX) XXXXX-XXXX" [formControlName]="i" />
                      <em nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="partnerPhones.removeAt(i)"></em>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <nz-form-item>
                  <nz-form-control nzSpan="16">
                    <button nz-button nzType="dashed" class="add-button" type="button" (click)="_addPartnerPhone()">
                      <em nz-icon nzType="plus"></em> Adicionar telefone
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </nz-row>
            <hr />
            <nz-row>
              <!-- Coluna Esquerda 2a parte -->
              <div nz-col
                [nzXs]="{ span: 20, offset: 2 }"
                [nzSm]="{ span: 20, offset: 2 }"
                [nzMd]="{ span: 20, offset: 2 }"
                [nzLg]="{ span: 10, offset: 0 }"
                [nzXl]="{ span: 10, offset: 0 }">
                
                <!-- URL de Ação -->
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzRequired nzFor="actionUrl">URL de Ação</nz-form-label>
                  <nz-form-control [nzSpan]="24">
                    <input nz-input nzRequired formControlName="actionUrl" id="actionUrl" />
                  </nz-form-control>
                </nz-form-item>
                
                <!-- Parâmetros de header -->
                <ng-container formArrayName="headerParams">
                  <nz-form-item *ngFor="let _ of partnerHeaderParams?.controls; let i = index; let first = first">
                    <nz-form-label [nzSpan]="24"  nzFor="headerParams" *ngIf="i === 0">
                      Adicionar Parâmetros de Header
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Informe ao menos um email válido para a empresa parceira!">
                      <input nz-input class="w-90 mr-10" [formControlName]="i" />
                      <em nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="partnerHeaderParams.removeAt(i)"></em>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <nz-form-item>
                  <nz-form-control nzSpan="16">
                    <button nz-button nzType="dashed" class="add-button" type="button" (click)="_addPartnerHeaderParams()">
                      <em nz-icon nzType="plus"></em> Adicionar Parâmetros de Header
                    </button>
                  </nz-form-control>
                </nz-form-item>
        
                <!-- Tipo de Ação -->
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="actionType">Tipo de Ação</nz-form-label>
                  <nz-form-control [nzSpan]="24">
                    <nz-radio-group formControlName="actionType" id="actionType">
                      <label nz-radio nzValue="GET">GET</label>
                      <label nz-radio nzValue="POST">POST</label>
                      <label nz-radio nzValue="OPTIONS">OPTIONS</label>
                      <label nz-radio nzValue="PUT">PUT</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
        
                <!-- Data JSON de envio -->
                <nz-form-item *ngIf="partnerForm.get('actionType')?.value !== 'GET'">
                  <nz-form-label [nzSpan]="24" nzFor="dataToSent">Data JSON de envio</nz-form-label>
                  <nz-form-control [nzSpan]="24">
                    <textarea nz-input formControlName="dataToSent" id="dataToSent" rows="5"></textarea>
                  </nz-form-control>
                </nz-form-item>
                
        
                <!-- Redirect via retorno de api? -->
                <nz-form-item>
                  <nz-form-control [nzSpan]="24">
                    <label nz-checkbox formControlName="redirectViaCallback">Redirect via parâmetro de retorno do request? (externo)</label>
                  </nz-form-control>
                </nz-form-item>
        
        
                <!-- Redirect de url via callback -->
                <nz-form-item *ngIf="partnerForm.get('redirectViaCallback')?.value === true">
                  <nz-form-label [nzSpan]="24" nzFor="urlCallback">Redirect de URL (callback)</nz-form-label>
                  <nz-form-control [nzSpan]="24">
                    <input nz-input formControlName="urlCallback" id="urlCallback" />
                  </nz-form-control>
                </nz-form-item>
        
                <!-- Termos de uso padrão -->
                <!-- <nz-form-item>
                  <nz-form-control [nzSpan]="24">
                    <label nz-checkbox formControlName="defaultTermsOfUse">Termos de uso padrão econdos? (Verificar disponibilidade)</label>
                  </nz-form-control>
                </nz-form-item> -->
        
                <!-- URL Termos de Uso -->
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzRequired nzFor="termsOfUseUrl">URL dos Termos de Uso</nz-form-label>
                  <nz-form-control [nzSpan]="24">
                    <input nz-input nzRequired formControlName="termsOfUseUrl" id="termsOfUseUrl" />
                  </nz-form-control>
                </nz-form-item>
        
                </div>
                <div nz-col
                [nzXs]="{ span: 20, offset: 2 }"
                [nzSm]="{ span: 20, offset: 2 }"
                [nzMd]="{ span: 20, offset: 2 }"
                [nzLg]="{ span: 10, offset: 2 }"
                [nzXl]="{ span: 10, offset: 2 }">
        
                <!-- Ativado -->
                <!-- Botões de Ação -->
                <nz-row style="gap:16px">
                  <button nz-button nzType="default" class="action-button" (click)="cancelPartners()">
                    Cancelar
                  </button>
                  <button nz-button nzType="primary" class="action-button" [disabled]="!partnerForm.valid" (click)="handlePartnerForm()">
                    {{
                    partnerForm.get("_id")?.value ?? ""
                    ? "Atualizar Parceiro"
                    : "Criar Parceiro"
                    }}
                  </button>
                </nz-row>
                <p>Instruções de uso:</p>
                <p>Em qualquer campo você poderá utilizar variáveis que colocará os dados do cliente, lembrando que só funcionará se estiverem entre pipes |.</p>
                <p>Campos de usuário disponível:</p>
                <ul>
                  <li>|cpf|</li>
                  <li>|rg|</li>
                  <li>|phone|</li>
                  <li>|email|</li>
                  <li>|fullName|</li>
                  <li>|firstName|</li>
                  <li>|lastName|</li>
                  <li>|maritalStatus|</li>
                  <li>|birthdate|</li>
                  <li>|userId|</li>
                </ul>
                <p>Campos de condomínio disponível:</p>
                <ul>
                  <li>|condoId|</li>
                  <li>|country|</li>
                  <li>|state|</li>
                  <li>|city|</li>
                  <li>|neighborhood|</li>
                  <li>|address|</li>
                  <li>|street|</li>
                  <li>|number|</li>
                  <li>|cep|</li>
                  <li>|zipCode|</li>
                </ul>
                <p>Redirect de URL (callback), será inserido por meio de chaves "{{ '{}' }}", isso sinalizando que o funcionamento se dará apenas para retorno de url via api do parceiro.</p>
                <p>Ex: O retorno do POST da api do parceiro é: {{ '{"url": "http://www.url.com.br"}'}}</p>
                <p>Então, você deverá inserir a url de retorno da seguinte forma: {{ '{url}'}}</p>
              </div>
            </nz-row>
          </form>
        </nz-card>
      </ng-container>
      <!-- /Parceiros -->
      <!-- Classificados -->
      <ng-container *ngIf="currentStep === 'advertiser'">
        <nz-card nzTitle="Configurações do Anúncio" [nzBordered]="false">
          <form [formGroup]="advertiserForm">
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzRequired nzFor="title">Título</nz-form-label>
              <nz-form-control [nzSpan]="14" nzRequired nzErrorTip="Inclua o título do anúncio">
                <input nz-input formControlName="title" id="title" required placeholder="Inclua o título do anúncio" />
              </nz-form-control>
            </nz-form-item>
        
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzRequired nzFor="description">Descrição</nz-form-label>
              <nz-form-control [nzSpan]="14" nzRequired nzErrorTip="Inclua a descrição do anúncio">
                <textarea nz-input formControlName="description" id="description" required rows="3" placeholder="Inclua a descrição do anúncio"></textarea>
              </nz-form-control>
            </nz-form-item>
        
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzRequired nzFor="price">Valor</nz-form-label>
              <nz-form-control [nzSpan]="14" nzRequired nzErrorTip="Inclua o valor">
                <nz-input-group nzAddOnBefore="R$">
                  <input nz-input type="tel" formControlName="price" id="price" required placeholder="R$ 0,00">
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            
        
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="state">Estado</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Por favor, selecione o estado">
                <nz-radio-group formControlName="state" id="state">
                  <label nz-radio-button *ngFor="let state of states" [nzValue]="state.value">
                    {{ state.label }}
                  </label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
        
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="category">Categoria</nz-form-label>
              <nz-form-control [nzSpan]="14" nzErrorTip="Por favor, selecione a categoria">
                <nz-select formControlName="category" id="category" nzPlaceHolder="Selecione a categoria">
                  <nz-option *ngFor="let category of categories" [nzLabel]="category.label" [nzValue]="category.value"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
        
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzRequired nzFor="phone">Telefone</nz-form-label>
              <nz-form-control [nzSpan]="14" nzRequired nzErrorTip="Por favor, inclua um número de telefone válido">
                <input nz-input formControlName="phone" id="phone" (input)="onPhoneInput($event)" placeholder="(XX) XXXX-XXXXX" />
              </nz-form-control>
            </nz-form-item>
        
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzFor="public">Público</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <nz-switch formControlName="public" nzCheckedChildren="Público" nzUnCheckedChildren="Privado"></nz-switch>
                <p>Anúncios públicos são visiveis para usuários de outros(as) condomínios.</p>
              </nz-form-control>
            </nz-form-item>

            <nz-upload
              nzListType="picture-card"
              [(nzFileList)]="pictures"
              [nzPreview]="handlePreview"
              (nzChange)="handleUploadImage($event)"
            >
              <div>
                <span nz-icon nzType="plus"></span>
                <div style="margin-top: 8px">Upload</div>
              </div>
            </nz-upload>

            <!-- Upload em andamento -->

            <nz-modal
              [nzVisible]="previewVisible"
              [nzContent]="modalContent"
              [nzFooter]="null"
              (nzOnCancel)="previewVisible = false"
            >
              <ng-template #modalContent>
                <img [src]="previewImage" [ngStyle]="{ 'width': '100%' }" />
              </ng-template>
            </nz-modal>
        
            <nz-form-item>
              <nz-form-label [nzSpan]="6" nzRequired nzFor="condo">Condomínio Primário</nz-form-label>
              <nz-form-control [nzSpan]="14">
                <nz-select [(ngModel)]="selectedPrimaryCondo" [nzDisabled]="advertiserForm.get('_id')?.value" [ngModelOptions]="{standalone: true}" id="condo" nzPlaceHolder="Selecione o condomínio">
                  <nz-option *ngFor="let condo of condos" [nzLabel]="condo.name" [nzValue]="condo._id"></nz-option>
                </nz-select>
                <p>Condomínio primário é o condomínio que o anúncio será salvo.</p>
                <p *ngIf="advertiserForm.get('_id')?.value || selectedPrimaryCondo">Para alterar o condomínio primário, é necessário excluir o anúncio e criar um novo.</p>
                <nz-alert nzType="warning" nzMessage="Classificados criado em um condomínio que não pertence mais ao anúncio, para altera-lo é necessário excluir e criar um novo." *ngIf="advertiserForm.get('_id')?.value && !selectedPrimaryCondo"></nz-alert>              
              </nz-form-control>
            </nz-form-item>
            
            <nz-divider></nz-divider>
            <nz-row style="gap:16px">
              <button nz-button (click)="loadAdvertiserForm()">Limpar formulário</button>
              <button nz-button nzType="primary" (click)="handleAdvertiserForm()" [disabled]="!advertiserForm.valid || !selectedPrimaryCondo || loading.isUploading">
                <span *ngIf="loading.isUploading">Upload de imagem em andamento</span>
                <span *ngIf="!loading.isUploading">Salvar</span>
              </button>
            </nz-row>
          </form>
        </nz-card>
        
      </ng-container>

      <!-- Resumo -->
      <ng-container *ngIf="currentStep === 'summary'">
        <nz-card nzTitle="Resumo" [nzBordered]="false">
          <!-- Resumo de configurações Gerais -->
          <nz-card nzType="inner" nzTitle="Título: {{ marketplaceForm.get('name')?.value }} ( {{ marketplaceForm.get('enabled')?.value ? 'Ativo' : 'Inativo' }} )">
            <span *ngIf="marketplaceForm.get('enabledInWhitelabel')?.value === true">
              WhiteLabel Ativo:
              <nz-tag>{{ currentActiveWhitelabel() }}</nz-tag>
            </span>
            <span *ngIf="marketplaceForm.get('enabled')?.value === true">
              Condomínios Ativos:
              <nz-tag *ngFor="let condo of marketplaceForm.get('condos')?.value">{{ currentActiveCondos(condo) }}</nz-tag>
            </span>
          </nz-card>
          <!-- /Resumo de configurações Gerais -->
          <nz-divider></nz-divider>
          <!-- Resumo de Parceiros -->
          <h4 nz-typography>Parceiros</h4>
          <!-- Add grid com todos os parceiros do marketplaceform-->
          <nz-row class="gap-16">
            <nz-col *ngFor="let partner of marketplaceForm.get('partners')?.value" nzSpan="6">
              <nz-card nzType="inner" nzTitle="{{ partner.name }}">
                <p class="m-0 two-line-ellipsis">Data de Início: {{ partner.startDate | date }}</p>
                <p class="m-0 two-line-ellipsis">Data de Fim: {{ partner.endDate | date }}</p>
                <p class="m-0 two-line-ellipsis">{{ partner.enabled ? 'Ativo' : 'Inativo' }}</p>
                <p class="m-0 two-line-ellipsis">Ordem no sistema: {{ partner.order }}</p>
                <p class="m-0 two-line-ellipsis">Tags: <nz-tag *ngFor="let tag of partner.tags">{{ tag }}</nz-tag></p>
                <p class="m-0 two-line-ellipsis" [title]="partner.description">Descritivo: {{ partner.description }}</p>
                <nz-avatar [nzSrc]="partner.logo" nzSize="large"></nz-avatar>
                <nz-divider></nz-divider>
                <button nz-button nzType="primary" nzSize="small" (click)="editPartner(partner)">Editar</button>
                <button nz-button nzType="default" nzSize="small" (click)="askToRemove(partner, 'partner')">Excluir</button>
              </nz-card>
            </nz-col>
          </nz-row>

          <nz-divider></nz-divider>
          <!-- Resumo de Classificados -->
          <h4 nz-typography>Classificados</h4>
          <!-- Add grid com todos os parceiros do marketplaceform-->
          <nz-row class="gap-16">
            <nz-col *ngFor="let advertiser of marketplaceForm.get('advertisers')?.value" nzSpan="6" >
              <nz-card nzType="inner" nzTitle="{{ advertiser.title }}">
                <p class="m-0">Descrição: {{ advertiser.description }}</p>
                <p class="m-0">Valor do anúncio: {{ advertiser.price | currency:'BRL' : 'symbol' : '1.2-2' }}</p>
                <p class="m-0">Estado: {{ showCurrentState(advertiser) }}</p>
                <p class="m-0">Categoria: {{ showCurrentCategory(advertiser) }}</p>
                <p class="m-0">Público: {{ advertiser.public ? 'Sim' : 'Não' }}</p>
                <p class="m-0">Imagens: <nz-avatar *ngFor="let picture of advertiser.pictures" [nzSrc]="picture.url" nzSize="small"></nz-avatar></p>
                <nz-divider></nz-divider>
                <button nz-button nzType="primary" nzSize="small" (click)="editAdvertiser(advertiser)">Editar</button>
                <button nz-button nzType="default" nzSize="small" (click)="askToRemove(advertiser, 'advertiser')">Excluir</button>
              </nz-card>
            </nz-col>
          </nz-row>
        </nz-card>
      </ng-container>
      <!-- /Resumo -->
    </div>
  </nz-spin>
</div>