import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {CondoService} from "@api/service/condo.service";
import {EcondosQuery} from "@api/model/query";

@Injectable({providedIn: 'root'})
export class CondoDetailsResolver implements Resolve<any> {

  constructor(private _router: Router,
    private condoService: CondoService) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<any> {
      const stateData = this._router.getCurrentNavigation()?.extras.state
      if (stateData?.condos) {
        return of(stateData.condos)
      } else {
        const condoId = route.params.condoId;
        const query: EcondosQuery = {
          $select: 'name cnpj createdAt createdBy plan address city state daysOfTrial hikvision controlId utech intelbras linear type pictures whiteLabel sale selfConfig',
          $populate: [{ path: 'pictures', select: 'thumbnail url type format name' }, { path: 'createdBy', select: 'firstName lastName' }],
        };
        return this.condoService.getCondoById(condoId, query);
    }
  }
}
