export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  isSystemAdmin: boolean;
  systemAdminAccessLevel?: 'MASTER' | 'COMMERCIAL',
  token: string;
  picture: any;
}


export const isMasterSystemAdmin = (user: User) => {
  return user.isSystemAdmin && user.systemAdminAccessLevel === 'MASTER';
}

export const isCommercialSystemAdmin = (user: User) => {
  const isMaster = isMasterSystemAdmin(user)
  const isCommercial = user.isSystemAdmin && user.systemAdminAccessLevel === 'COMMERCIAL'
  return isMaster || isCommercial

}
