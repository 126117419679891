<div class="readjust-modal-body">
  <form nz-form [formGroup]="readjustForm" class="form-input">
      <nz-form-label>Porcentagem de reajuste</nz-form-label>
    <nz-form-item>
      <nz-form-control class="form-input" nzErrorTip="Este campo deve ser maior que 0 se o campo de data estiver vazio ou igual a 0 para alterar somente a data." nzHasFeedback>
        <nz-input-number class="form-input" formControlName="readjustPercentage" [nzFormatter]="formatPercent" [nzParser]="parsePercent"></nz-input-number>
      </nz-form-control>
    </nz-form-item>
        <nz-form-label>Data do próximo reajuste</nz-form-label>
    <nz-form-item>
      <nz-form-control  class="form-input" nzErrorTip="Este campo deve ser maior que hoje." nzHasFeedback>
      <nz-date-picker class="form-input"  nzFormat="dd/MM/YYYY" formControlName="nextReadjustmentDate"
                        ></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>

  <nz-button-group class="modal-buttons">
    <button (click)="closeModal()" nz-button [disabled]="loadingStatus" class="ant-btn">
      Cancelar
    </button>
    <button (click)="onSubmit()" nz-button [nzLoading]="loadingStatus" class="ant-btn ant-btn-primary">
      Aplicar
    </button>
  </nz-button-group>
