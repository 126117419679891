<form class="financial-responsible-form" nz-form [formGroup]="form" (ngSubmit)="submitForm()">

  <h2>Dados Pessoais</h2>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-label  nzRequired nzFor="text">Nome </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Nome é obrigatório" nzHasFeedback>
          <input nz-input formControlName="name" id="name"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-label  nzRequired nzFor="number">CPF / CNPJ </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="documento invalido" nzHasFeedback>
          <input nz-input formControlName="cpfCnpj" id="cpfCnpj"/>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-label  nzRequired nzFor="number">Telefone </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="telefone é obrigatório" nzHasFeedback>
          <input nz-input formControlName="phone" id="phone"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-label  nzRequired nzFor="email">Email </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="email invalido" nzHasFeedback>
          <input nz-input formControlName="email" id="email"/>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-label  nzRequired nzFor="number">Cep </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="cep é obrigatório" nzHasFeedback>
          <input nz-input formControlName="zipcode" id="zipcode"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-label  nzRequired nzFor="text">Endereço </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Endereço é obrigatório" nzHasFeedback>
          <input nz-input formControlName="address" id="address"/>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-label  nzRequired nzFor="text">Bairro </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="bairro é obrigatório" nzHasFeedback>
          <input nz-input formControlName="neighborhood" id="neighborhood"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-form-label nzFor="text">Complemento </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" >
          <input nz-input formControlName="complement" id="complement"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Numero </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" >
          <input nz-input formControlName="number" id="number"/>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-label  nzRequired nzFor="text">Cidade </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Cidade é obrigatório" nzHasFeedback>
          <input nz-input formControlName="city" id="city"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-form-label  nzRequired nzFor="state">Estado </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Estado é obrigatório" nzHasFeedback>
          <input nz-input formControlName="state" id="state"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-form-label nzRequired nzFor="text">Início do Contrato</nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Informe uma data de início" nzHasFeedback>
          <nz-date-picker formControlName="contractStartDate" id="contractStartDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <h2>Dados Financeiros</h2>

  <div nz-row [nzGutter]="24">
    <div nz-col nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Implantação </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" >
          <input nz-input formControlName="implantation" id="implantation"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Total de Unidades </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" >
          <input nz-input formControlName="numberOfResidences" id="numberOfResidences"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Valor da Unidade </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" >
          <input nz-input formControlName="unitValue" id="unitValue"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Integração </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" >
          <input nz-input formControlName="integrations" id="integrations"/>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Whitelabel </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" >
          <input nz-input formControlName="whiteLabelValue" id="whiteLabelValue"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Manutenção </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" >
          <input nz-input formControlName="whiteLabelMaintenance" id="whiteLabelMaintenance"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Outros Valores </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24"  >
          <input nz-input formControlName="otherValue" id="otherValue"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Desconto </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <input nz-input formControlName="discount" id="discount"/>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

    <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="6">
      <nz-form-label nzFor="number">Valor Final </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <input [(ngModel)]="totalValue" [ngModelOptions]="{standalone: true}" nz-input id="totalValue"/>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-label nzFor="text">Observações </nz-form-label>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <textarea nz-input formControlName="comments" id="comments"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <nz-form-item nz-row class="register-area">
    <nz-form-control [nzSpan]="24" [nzOffset]="9">
      <button nzSize="large" nz-button nzType="primary" >Enviar formulario</button>
    </nz-form-control>
  </nz-form-item>
</form>
