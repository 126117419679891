import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {EcondosQuery} from '../model/query';
import * as qs from 'qs';
import {environment} from '@environment';
import {CondoFinancialInfo} from '@api/model/condo-financial-info';

@Injectable({providedIn: 'root'})
export class CondoFinancialInfoService {

  private endPoint = `${environment.backendUrl}condo-financial-info`;

  constructor(private http: HttpClient) {
  }

  get(params: EcondosQuery = {}): Observable<{ count: number, condoFinancialInfo: CondoFinancialInfo[] }> {
    const httpParams = new HttpParams({fromString: qs.stringify(params)});
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get(`${this.endPoint}`, options).pipe(map((res: any) => ({
        count: res.headers.get('count'),
        condoFinancialInfo: res.body as CondoFinancialInfo[]
      })
      )
    );
  }

  create(condoFinancialInfo: CondoFinancialInfo) {
    return this.http.post(`${this.endPoint}`, condoFinancialInfo);

  }

  update(condoFinancialInfoId: string, condoFinancialInfo: CondoFinancialInfo) {
    return this.http.put(`${this.endPoint}/${condoFinancialInfoId}`, condoFinancialInfo);
  }

  delete(condoFinancialInfoId: string) {
    return this.http.delete(`${this.endPoint}/${condoFinancialInfoId}`);
  }

  getById( condoFinancialInfoId: string, params: EcondosQuery = {}): Observable<CondoFinancialInfo> {
    const httpParams = new HttpParams({fromString: qs.stringify(params)});
    const options = {
      headers: new HttpHeaders(),
      params: httpParams
    };
    return this.http.get(`${this.endPoint}/${condoFinancialInfoId}`, options) as Observable<CondoFinancialInfo>;
  }

  activate(condoFinancialInfos: string[]) {
    return this.http.put(`${this.endPoint}/activate`, {condoFinancialInfos});
  }

  deactivate(condoFinancialInfos: string[])  {
    return this.http.put(`${this.endPoint}/deactivate`, {condoFinancialInfos});
  }

  readjust(condoFinancialInfos: string[], adjustmentValue: number, nextAdjustmentDate?: string) {
    return this.http.put(`${this.endPoint}/readjust`, { condoFinancialInfos, adjustmentValue, nextAdjustmentDate });
  }

}
