import {CondosPageComponent} from './home/children/condos-page/condos-page.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from './login-page/login-page.component';
import {DashboardPageComponent} from './home/children/dashboard-page/dashboard-page.component';
import {LoggedGuard} from './guards/logged.guard';
import {WhiteLabelsPageComponent} from './home/children/whitelabels-page/white-labels-page.component';
import {WhiteLabelDetailsPageComponent} from './home/children/white-label-details-page/white-label-details-page.component';
import {WhiteLabelResolver} from './resolver/white-label.resolver';
import {CondosResolver} from './resolver/condos.resolver';
import {UsersPageComponent} from './home/children/users-page/users-page.component';
import {NotFoundPageComponent} from './not-found-page/not-found-page.component';
import {UserDetailsPageComponent} from './home/children/user-details-page/user-details-page.component';
import {UserResolver} from './resolver/user.resolver';
import {DashboardResolver} from './home/children/dashboard-page/dashboard.resolver';
import {CondosDetailsPageComponent} from './home/children/condos-details-page/condos-details-page.component';
import {CondoDetailsResolver} from './resolver/condo-details.resolver';
import {CreateWhiteLabelComponent} from './home/children/create-white-label-page/create-white-label-page';
import {CreateWhiteLabelResolver} from './resolver/create-white-label.resolver';
import {HomeComponent} from './home/home.component';
import { PartnersPageComponent } from './home/children/partners/partners-page/partners-page.component';
import { PartnersComponent } from './home/children/partners/partners-details/partners-details.component';
import { MarketplacePageComponent } from './home/children/marketplace/marketplace-page/marketplace-page.component';
import { ContractsPageComponent } from './home/children/contracts-page/contracts-page.component';
import { CondoLogsPageComponent } from './home/children/logs-page/condo-logs-page/condo-logs-page.component';
import {UseTermsPageComponent} from "./home/children/use-terms-page/use-terms-page.component";
import {UseTermsResolver} from "./resolver/use-terms.resolver";
import {EcondosUseTermsResolver} from "./resolver/econdos-use-terms.resolver";
import { FeaturesPageComponent } from './home/children/features-page/features-page.component';
import { HighlightsPageComponent } from './home/children/features-page/children/highlights-page/highlights-page.component';
import {
  CreateHighlightPageComponent
} from './home/children/features-page/children/create-highlight-page/create-highlight-page.component';
import {
  CreateCondoFinancialInfoPageComponent
} from './home/children/create-condo-financial-info-page/create-condo-financial-info-page';
import {
  CondoFinancialInfoPageComponent
} from './home/children/condo-financial-info-page/condo-financial-info-page.component';
import {CreateCondoFinancialInfoResolver} from './resolver/create-condo-financial-info.resolver';
import {CommercialSystemAdminGuard} from './guards/commercialSystemAdmin.guard';
import {NoPermissionErrorPageComponent} from './no-permission-error-page/no-permission-error-page.component';
import {HardwareLogsPageComponent} from './home/children/logs-page/hardware-logs-page/hardware-logs-page.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent, canActivate: [LoggedGuard],
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {
        path: 'dashboard',
        component: DashboardPageComponent,
        resolve: {data: DashboardResolver}
      },
      {
        path: 'partners',
        component: PartnersPageComponent
      },
      {
        path: 'partners/new',
        component: PartnersComponent
      },
      {
        path: 'partners/:partnerId/edit',
        component: PartnersComponent
      },
      {
        path: 'marketplace',
        children: [
          {
            path: '',
            component: MarketplacePageComponent
          },
          {
            path: 'new',
            component: MarketplacePageComponent
          }
        ]
      },
      {
        path: 'whitelabels', component: WhiteLabelsPageComponent
      },
      {
        path: 'whitelabels/create',
        component: CreateWhiteLabelComponent
      },
      {
        path: 'whitelabels/:whiteLabelId',
        component: WhiteLabelDetailsPageComponent,
        resolve: {
          whiteLabel: WhiteLabelResolver,
          condos: CondosResolver
        }
      },
      {
        path: 'whitelabels/:whiteLabelId/edit',
        component: CreateWhiteLabelComponent,
        resolve: {
          whiteLabel: CreateWhiteLabelResolver,
        }
      },
      {
        path: 'whitelabels/:whiteLabelId/use-terms',
        component: UseTermsPageComponent,
        resolve: {
          whiteLabel: WhiteLabelResolver,
          useTerms: UseTermsResolver
        }
      },
      {
        path: 'users', component: UsersPageComponent
      },
      {
        path: 'users/:userId', component: UserDetailsPageComponent,
        resolve: {user: UserResolver},
        runGuardsAndResolvers: 'paramsChange'
      },
      {
        path: 'condos', component: CondosPageComponent
      },
      {
        path: 'condos/:condoId',
        component: CondosDetailsPageComponent,
        resolve: {
          condos: CondoDetailsResolver
        }
      },{
        path: 'contracts', component: ContractsPageComponent
      },
      {
        path: 'logs/condos', component: CondoLogsPageComponent
      },
      {
        path: 'logs/hardwares', component: HardwareLogsPageComponent
      },
      {
        path: 'features',
        component: FeaturesPageComponent
      },
      {
        path: 'features/highlights',
        component: HighlightsPageComponent
      },
      {
        path: 'features/highlights/create',
        component: CreateHighlightPageComponent
      },
      {
        path: 'features/highlights/:highlightId/edit',
        component: CreateHighlightPageComponent
      },
      {
        path: 'use-terms',
        component: UseTermsPageComponent,
        resolve: {
          econdos: EcondosUseTermsResolver
        }
      },
      {
        path: 'condoFinancialInfo',
        component: CondoFinancialInfoPageComponent, canActivate: [CommercialSystemAdminGuard]
      },
      {
        path: 'condoFinancialInfo/create',
        component: CreateCondoFinancialInfoPageComponent, canActivate: [CommercialSystemAdminGuard]
      },
      {
        path: 'condoFinancialInfo/:condoFinancialInfoId/edit',
        component: CreateCondoFinancialInfoPageComponent,
        resolve: {
          condoFinancialInfo: CreateCondoFinancialInfoResolver,
        }, canActivate: [CommercialSystemAdminGuard]
      }
    ]
  },
  {path: 'login', component: LoginPageComponent},
  {path: 'notFound', component: NotFoundPageComponent, canActivate: [LoggedGuard]},
  {path: 'no-permission-error', component: NoPermissionErrorPageComponent, canActivate: [LoggedGuard]},
  // Redirect for not found routes - SEMPRE DEVE SER A ÚLTIMA ROTA
  {path: '**', redirectTo: '/notFound'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
