import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {WhiteLabel} from "@api/model/white-label";
import {WhiteLabelService} from "@api/service/white-label.service";
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {EcondosQuery} from "@api/model/query";
import {NzOptionSelectionChange} from "ng-zorro-antd/auto-complete";

@Component({
  selector: 'app-search-whitelabel',
  templateUrl: './search-whitelabel.component.html',
  styleUrls: ['./search-whitelabel.component.scss']
})
export class SearchWhitelabelComponent implements OnInit {
  @Input() showSearchButton = true;
  @Input() clearInputAfterSelect = false;

  @Output() onFilter = new EventEmitter<string>();
  @Output() selectWhiteLabel = new EventEmitter<WhiteLabel>();
  @Output() onClose = new EventEmitter<any>();

  inputValue: string = '';

  options$: Observable<WhiteLabel[]>;
  inputSubject = new Subject<string>();
  filtering: boolean = false;
  isSearching = false;
  itemSelected: boolean = false;

  constructor(private whitelabelService: WhiteLabelService) {
    this.options$ = this.inputSubject.pipe(
      debounceTime(1000),
      filter((qs) => qs.length >= 3),
      switchMap((queryString) => {
        const query: EcondosQuery = {
          $select:
            'name icon companyName',
          $limit: 50,
          name: { $regex: queryString, $options: 'i' },
        };

        this.isSearching = true;

        return this.whitelabelService.get(query).pipe(tap(() => {
          this.isSearching = false;
        }));
      })
    );
  }

  ngOnInit(): void {}

  onChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.inputSubject.next(value);
  }

  handleSearch() {
    this.filtering = true;
    this.onFilter.emit(this.inputValue);
  }

  clearInput() {
    this.inputValue = '';
    this.filtering = false;
    this.itemSelected = false;
    this.inputSubject.next('');
  }

  handleOnClose() {
    this.clearInput();
    this.onClose.emit();
  }

  handleSelectionChange(event: NzOptionSelectionChange, whiteLabel: WhiteLabel) {
    const { isUserInput, source } = event;

    if (isUserInput) {
      this.itemSelected = true;
      this.inputValue = source.nzValue;
      this.selectWhiteLabel.emit(whiteLabel);

      if (this.clearInputAfterSelect) {
        this.clearInput();
      }
    }
  }
}
