<nz-page-header *ngIf="!userIdModal" class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="../..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="..">Usuários</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="user"></i> Usuário
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title>Usuários</nz-page-header-title>
</nz-page-header>

<div class="px-4">
  <ng-container *ngIf="route.data | async as data">
    <div nz-row class="space-align-container">
      <div nz-col>
        <nz-avatar [nzSize]="64" nzIcon="user" [nzSrc]="data.user?.picture?.url"></nz-avatar>
      </div>
      <div nz-col nzSpan="18" nz-scape nzAlign="center" class="space-align-block">
        <h2>{{ data.user?.firstName }} {{ data.user?.lastName }}</h2>
      </div>
    </div>
    <hr />
    <div>
      <div nz-row>
        <div nz-col>
          <label>User:</label>
          <span>
            {{ data.user?._id }}
          </span>
        </div>
        <div nz-col style="margin-left: auto" *ngIf="!userIdModal">
          <button nz-button [nzType]="'primary'" (click)="showModalUpdateEmail(data.user)">
            <p>Editar Email</p>
          </button>
          <button style="margin-left: 5px" nz-button [nzType]="'primary'" (click)="showModalResetPassword(data.user)">
            <p>Resetar Senha</p>
          </button>
        </div>
      </div>

      <div>
        <label>Email:</label>
        <span>
          {{ data.user?.email }}
        </span>
      </div>
      <div *ngFor="let doc of data.user?.ids">
        <label>{{ doc?.type }}:</label>
        <span>
          {{ doc?.number }}
        </span>
      </div>
      <div *ngIf="data.user?.phones?.length">
        <label>Telefone:</label>
        <span>
          {{ data.user?.phones }}
        </span>
      </div>
      <div>
        <label>System Admin:</label>
        <span>
          {{ data.user?.isSystemAdmin ? 'Sim' : 'Não' }}
        </span>
      </div>
      <div *ngIf="data.user?.createdBy">
        <label>CreatedBy:</label>
        <button nz-button nzSize="small" [nzType]="'primary'" [routerLink]="['/home/users/', data.user?.createdBy._id]">
          <p>{{ data.user?.createdBy?.fullName }}</p>
        </button>
      </div>
    </div>

    <div *ngIf="data.user?.residencesUser?.length" style="margin: 5px">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Residences User'">
          <nz-collapse>
            <nz-collapse-panel *ngFor="let residence of data.user?.residencesUser; let i = index" [nzHeader]="'Residencia ' + i">
              <div>
                <label>Residence id: </label>
                <span>{{ residence._id }}</span>
              </div>
              <div>
                <label>Condo id: </label>
                <span>{{ residence.condo }}</span>
              </div>
              <div>
                <label>Residence identification: </label>
                <span>{{ residence.identification }}</span>
              </div>
              <div>
                <label>Residence address: </label>
                <span>{{ residence.address }}</span>
              </div>
              <div>
                <label>Residence number: </label>
                <span>{{ residence.number }}</span>
              </div>
              <div>
                <label>Residence block: </label>
                <span>{{ residence.block }}</span>
              </div>
              <div>
                <label>Residence type: </label>
                <span>{{ residence.type }}</span>
              </div>
              <div>
                <label>Residence dependents quantity: </label>
                <span>{{ residence.qtDependents }}</span>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div *ngIf="data.user?.condos?.length" style="margin: 5px">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Condômino'">
          <nz-collapse>
            <nz-collapse-panel *ngFor="let condo of data.user?.condos" [nzHeader]="condo.name">
              <label>Condo id: </label>
              <span>{{ condo._id }}</span>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div *ngIf="data.user?.condosAdmin?.length" style="margin: 5px">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Administrador'">
          <nz-collapse>
            <nz-collapse-panel *ngFor="let condo of data.user?.condosAdmin" [nzHeader]="condo.name">
              <label>Condo id: </label>
              <span>{{ condo._id }}</span>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div *ngIf="data.user?.condosOwner?.length" style="margin: 5px">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Síndico'">
          <nz-collapse>
            <nz-collapse-panel *ngFor="let condo of data.user?.condosOwner" [nzHeader]="condo.name">
              <label>Condo id: </label>
              <span>{{ condo._id }}</span>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div *ngIf="data.user?.condosGatekeeper?.length" style="margin: 5px">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Porteiro'">
          <nz-collapse>
            <nz-collapse-panel *ngFor="let condo of data.user?.condosGatekeeper" [nzHeader]="condo.name">
              <label>Condo id: </label>
              <span>{{ condo._id }}</span>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div *ngIf="data.user?.condosJanitor?.length" style="margin: 5px">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Zelador'">
          <nz-collapse>
            <nz-collapse-panel *ngFor="let condo of data.user?.condosJanitor" [nzHeader]="condo.name">
              <label>Condo id: </label>
              <span>{{ condo._id }}</span>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div *ngIf="data.user?.condosRequester?.length" style="margin: 5px">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Condos Requester'">
          <nz-collapse>
            <nz-collapse-panel *ngFor="let condo of data.user?.condosRequester" [nzHeader]="condo.name">
              <label>Condo id: </label>
              <span>{{ condo._id }}</span>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div *ngIf="data.user?.condosLawyer?.length" style="margin: 5px">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Advogado'">
          <nz-collapse>
            <nz-collapse-panel *ngFor="let condo of data.user?.condosLawyer" [nzHeader]="condo.name">
              <label>Condo id: </label>
              <span>{{ condo._id }}</span>
            </nz-collapse-panel>
          </nz-collapse>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </ng-container>
</div>
