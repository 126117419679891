import { Component, Input, OnInit } from '@angular/core';
import { CondoUser } from '@api/model/condo-user';
import { UserService } from '@api/service/user.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-reset-user-password',
  templateUrl: './modal-reset-user-password.component.html',
  styleUrls: ['./modal-reset-user-password.component.scss'],
})
export class ModalResetUserPasswordComponent implements OnInit {
  @Input() user!: CondoUser;
  isLoadingOne = false;
  resetPasswordStatus: string;
  newPassword: number;

  constructor(private userService: UserService, private ModalService: NzModalService) {
    this.resetPasswordStatus = '';
    this.newPassword = 0;
  }

  ngOnInit(): void {}

  resetUserPassword(): void {
    this.userService.resetPassword(this.user?._id).subscribe(
      (res) => {
        (this.newPassword = res.password), (this.resetPasswordStatus = 'success');
      },
      (error) => {
        this.resetPasswordStatus = 'error';
      }
    );
  }

  loadOne(): void {
    this.isLoadingOne = true;
    setTimeout(() => {
      this.isLoadingOne = false;
    }, 2000);
  }
}
