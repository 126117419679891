import { Component, OnDestroy, OnInit } from "@angular/core";
import { Highlight, HIGHLIGHT_PRESENTATION_MODE_INFO } from "@api/model/highlight";
import { EcondosQuery } from "@api/model/query";
import { HighlightsService } from "@api/service/highlights.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Subject } from "rxjs";
import { takeUntil, timeout } from "rxjs/operators";
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {
  ModalPreviewHighlightComponent
} from '../../components/modal-preview-highlight/modal-preview-highlight.component';

type Status = 'LOADING' | 'SUCCESS' | 'ERROR';

@Component({
  selector: 'app-highlights-page',
  templateUrl: './highlights-page.component.html',
  styleUrls: ['./highlights-page.component.scss']
})
export class HighlightsPageComponent implements OnInit, OnDestroy {
  highlights: Highlight[] = [];
  nzPageSize = 10;
  nzPageIndex = 0;
  totalData = 0;
  status: Status = 'LOADING';

  initialQuery: EcondosQuery = {
    $select: 'title presentationMode startDate endDate position isActive clicksCount',
    $sort: '-createdAt'
  };

  HIGHLIGHT_PRESENTATION_MODE_INFO = HIGHLIGHT_PRESENTATION_MODE_INFO;
  presentationModeColor: Record<keyof typeof HIGHLIGHT_PRESENTATION_MODE_INFO, string> = {
    TEXT_ONLY: 'blue',
    PICTURE_ONLY: 'green',
    TEXT_AND_PICTURE: 'cyan'
  };

  unsubscribe$: Subject<boolean> = new Subject()

  constructor(
    private highlightsService: HighlightsService,
    private notificationService: NzNotificationService,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.next(true);
      this.unsubscribe$.complete()
    }
  }

  getData() {
    const query = this.initialQuery;

    query.$page = this.nzPageIndex;
    query.$limit = this.nzPageSize;

    this.status = 'LOADING';

    this.highlightsService
      .getHighlights(query)
      .pipe(timeout(20_000), takeUntil(this.unsubscribe$))
      .subscribe({
        next: response => {
          this.totalData = response.count;
          this.highlights = response.highlights;
          this.status = 'SUCCESS';
        },
        error: err => {
          const errorMessage = err?.originalError?.message || 'Ocorreu um erro ao tentar buscar os destaques. Tente novamente, por favor.';
          this.notificationService.error('Erro', errorMessage);
          this.status = 'ERROR';
        }
      });
  }

  handleDeleteHighlight(highlightToDelete: Highlight) {
    const notification = this.notificationService.info('Excluindo', 'Excluindo este destaque...', { nzDuration: 0 })

    this.highlightsService.deleteHighlight(highlightToDelete._id).pipe(timeout(10000)).subscribe({
      next: () => {
        this.notificationService.remove(notification.messageId);
        this.notificationService.success('Sucesso', 'Destaque excluído com sucesso.');
        this.getData();
      },
      error: err => {
        const errorMessage = err?.originalError?.message || 'Ocorreu um erro ao tentar excluir o destaque. Tente novamente, por favor.';
        this.notificationService.remove(notification.messageId);
        this.notificationService.error('Erro', errorMessage);
      }
    });
  }

  handleToggleHighlight(highlightToToggle: Highlight) {
    const isHighlightActive = !highlightToToggle.isActive

    const notification = this.notificationService.info(
      isHighlightActive ? 'Desativando' : 'Ativando',
      `${isHighlightActive ? 'Desativando' : 'Ativando'} este destaque...`,
      { nzDuration: 0 }
    )

    this.highlightsService
      .toggleHighlight(highlightToToggle._id, { isActive: isHighlightActive })
      .pipe(timeout(20000))
      .subscribe({
        next: () => {
          this.highlights = this.highlights.map(highlight =>
            highlight._id === highlightToToggle._id ? { ...highlight, isActive: isHighlightActive } : highlight
          );

          this.notificationService.remove(notification.messageId);
          this.notificationService.success('Sucesso', `Destaque ${isHighlightActive ? 'ativado' : 'desativado'} com sucesso.`);
        },
        error: err => {
          const errorMessage =
            err?.originalError?.message ||
            `Ocorreu um erro ao tentar ${isHighlightActive ? 'ativar' : 'desativar'} o destaque. Tente novamente, por favor.`;

          this.notificationService.remove(notification.messageId);
          this.notificationService.error('Erro', errorMessage);
        }
      });
  }

  handlePreviewHighlight(highlight: Highlight) {
    const modal: NzModalRef = this.modalService.create({
      nzTitle: 'Pré-visualização do destaque',
      nzContent: ModalPreviewHighlightComponent,
      nzComponentParams: { highlightId: highlight._id },
      nzWidth: 640,
      nzFooter: [{
        label: 'Fechar',
        type: 'primary',
        onClick: () => modal.destroy()
      }]
    });
  }

  refreshIndexChange(value: any) {
    this.nzPageIndex = value - 1
    this.getData();
  }

  refreshSizeChange(value: any) {
    this.nzPageSize = value
    this.getData();
  }
}
