import { Component, OnInit } from '@angular/core';
import { Partner } from '@api/model/partner';
import { PartnerService } from 'src/app/service/partner.service';
import { Router } from '@angular/router';
import { EcondosQuery } from '@api/model/query';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { formatPhone } from '@api/utils'

@Component({
  selector: 'app-partners-page',
  templateUrl: './partners-page.component.html',
  styleUrls: ['./partners-page.component.scss']
})
export class PartnersPageComponent implements OnInit {

  partners: Partner[] = [];
  isVisible = false;
  //id do parceiro para exclusão
  partnerId: string = "";

  constructor( private partnerService: PartnerService,
    private router: Router,
    private notification: NzNotificationService ) { }

  ngOnInit(): void {
    const query: EcondosQuery = {
      $select: 'description name logo tags url order phones emails enabled enabledInWhitelabel startDate endDate ', $sort: 'order'
    }

    this.partnerService.getPartners(query).subscribe(response => {
      const partnersFormatted = response.partners.map((partner) => {
        //formatação dos campos de telefone
        const partnerToFormat = partner
        const phones: string[] = []

        for (let phone of partnerToFormat.phones){
           phones.push(formatPhone(phone))
        }

        partner.phones = phones

        return partner
      })

      this.partners = partnersFormatted
    })
  }

  goToCreatePartner(): void{
    this.router.navigate(['/home/partners/new']);
  }

  //mostra o modal para exclusão de parceiro e defino o id do parceiro selecionado
  showModal(partnerId: string): void {
    this.partnerId = partnerId;
    this.isVisible = true;
  }

  //Cria uma mensagem de notificação
  createNotification(type: string, title: string, message: string): void {
    this.notification.create(
      type,
      title,
      message
    );
  }

  //handlers para modal de exclusão
  handleOk(): void {
    this.partnerService.deletePartner(this.partnerId).subscribe( (result: any) => {
      this.createNotification('success', "Parceiro Deletado", "Parceiro foi deletado com sucesso!");
      window.location.reload();
    }, (err: any) => {
      this.createNotification('error', 'Falha no processo', 'Houve um erro ao tentar deletar o parceiro!')
      console.log(err)
    })
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
