import { CondoService } from '@api/service/condo.service';
import { ResidenceService } from '@api/service/residences.service';
import { Component, OnInit } from '@angular/core';
import { Condo } from '@api/model/condo';
import { WhiteLabel } from '@api/model/white-label';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalCreateFinancialResponsible } from '../../../components/modal-create-financial-responsible/modal-create-financial-responsible.component';
import { ModalWhiteLabelSelect } from '../../../components/modal-white-label-select/modal-white-label-select';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ModalNotifyCondo } from '../../../components/modal-notify-condo/modal-notify-condo';
import { timeout } from 'rxjs/operators';
import { EcondosQuery } from '@api/model/query';

@Component({
  selector: 'app-condos-details-page',
  templateUrl: './condos-details-page.component.html',
  styleUrls: ['./condos-details-page.component.scss']
})
export class CondosDetailsPageComponent implements OnInit {
  whiteLabels: WhiteLabel[] = [];
  residencesCount: number = -1;

  condoId: string;

  public condoParamsKeys: string[] = [];
  public CONDO_PARAMS: { [key: string]: Condo_Params } = {};

  hasChanged: boolean = false;
  plan: string = '';
  PLANS: { [key: string]: string[] } = {
    BASIC: [
      'pets',
      'cameras',
      'reservations',
      'ads',
      'lostAndFounds',
      'condoRating',
      'voting',
      'condoFaq',
      'finance',
      'condoDocuments',
      'deliveries',
      'commentsModeration',
      'maintenance',
      'maintenanceTickets',
      'maintenanceTicketsVisibility'
    ],
    INTER: [
      'finance',
      'condoRating',
      'condoDocuments',
      'commentsModeration',
      'maintenance',
      'maintenanceTickets',
      'maintenanceTicketsVisibility'
    ],
    FULL: []
  };

  query: EcondosQuery = {
    $select: 'name cnpj createdAt plan address city state daysOfTrial params whiteLabel',
    $populate: [{ path: 'whiteLabel', select: 'name' }]
  };

  public STATUS = {
    LOADING: 0,
    SUCCESS: 1,
    ERROR: 2
  };
  public status: number | null = null;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private ModalService: NzModalService,
    private residenceService: ResidenceService,
    private condoService: CondoService,
    private notification: NzNotificationService
  ) {
    this.condoId = this.route.snapshot.params.condoId;

    this.CONDO_PARAMS = {
      DEVICE_ACCESS_REQUEST: {
        label: 'Solicitações de dispositivos de acesso',
        description: 'Habilita ou desabilita o módulo de solicitações de dispositivos de acesso',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        originalValue: '',
        paramName: 'deviceAccessRequest'
      },

      NEW_USERS_NEED_REQUEST: {
        label: 'Precisa de aprovação',
        description: `Quando ativado todos novos condôminos precisarão ser aceitos pela administração do(a) condomínio`,
        true: 'NEED_REQUEST',
        false: 'NO_NEED_REQUEST',
        value: '',
        toggleValue: '',
        originalValue: '',
        paramName: 'newUsers'
      },

      // PUBLIC_OCCURRENCE_CREATION: {
      //   label: 'Liberar criação de ocorrência pública',
      //   description: 'Quando ativado a visualização e criação de ocorrências públicas estarão liberadas para qualquer usuário do condomínio',
      //   true: 'ANYONE',
      //   false: 'ONLY_ADMINS',
      //   value: '',
      //   toggleValue: '',
      //   originalValue: '',
      //   paramName: 'publicOccurrencesCreation'
      // },

      PRIVATE_OCCURRENCE_CREATION: {
        label: 'Liberar criação de ocorrência entre condôminos',
        description:
          'Quando ativado, condôminos podem criar ocorrências privadas para outros condôminos, quando desativado apenas administradores podem criar ocorrências privadas para condôminos',
        true: 'ANYONE',
        false: 'ONLY_ADMINS',
        value: '',
        toggleValue: '',
        originalValue: '',
        paramName: 'privateOccurrencesCreation'
      },
      COMMENT_MODERATION: {
        label: 'Liberar comentários',
        description: 'Quando ativado os comentários serão livres para qualquer usuário',
        true: 'NONE',
        false: 'REQUIRED',
        value: '',
        toggleValue: '',
        originalValue: '',
        paramName: 'commentsModeration'
      },
      RESIDENCE_CREATION: {
        label: 'Liberar criação de unidade',
        description: 'Quando ativado a criação de unidade será livre para qualquer usuário',
        true: 'PERMITTED',
        false: 'PROHIBITED',
        value: '',
        toggleValue: '',
        originalValue: '',
        paramName: 'residenceCreation'
      },
      FINANCE: {
        label: 'Financeiro disponível para condôminos',
        description: 'Se habilitado, o módulo financeiro estará visível para todos condôminos do(a) condomínio.',
        true: 'ANYONE',
        false: 'ONLY_ADMINS',
        value: '',
        toggleValue: '',
        paramName: 'finance'
      },
      ACCESS_CONTROL: {
        label: 'Controle de acesso',
        description: 'Habilita ou desabilita o módulo de controle de acesso.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'accessControl'
      },
      ACCESS_LIBERATION: {
        label: 'Liberação de visitantes',
        description: 'Habilita ou desabilita o módulo de liberação de visitantes.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'accessLiberation'
      },
      DELIVERIES: {
        label: 'Recebimento de encomendas',
        description: 'Habilita ou desabilita o módulo de recebimento de encomendas.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'deliveries'
      },
      KEYS: {
        label: 'Painel de chaves',
        description: 'Habilita ou desabilita o módulo do painel de chaves para a portaria.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'keys'
      },
      GATE_NOTEBOOK: {
        label: 'Livro da portaria',
        description:
          'Habilita ou desabilita o uso do livro de anotações na portaria (Este livro é visível apenas para o síndico, admnistradores e porteiros do(a) condomínio).',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'gateNotebook'
      },
      VOTING: {
        label: 'Enquetes',
        description: 'Habilita ou desabilita o módulo de enquetes.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'voting'
      },
      RESERVATIONS: {
        label: 'Reservas',
        description: 'Habilita ou desabilita o módulo de reservas.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'reservations'
      },
      DOCUMENTS: {
        label: 'Documentos',
        description: 'Habilita ou desabilita o módulo de documentos.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'condoDocuments'
      },
      ADS: {
        label: 'Classificados',
        description: 'Habilita ou desabilita o módulo de classificados.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'ads'
      },
      MAINTENANCE: {
        label: 'Manutenção',
        description: 'Habilita ou desabilita o módulo de controle de manutenção.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'maintenance'
      },
      MAINTENANCE_TICKETS: {
        label: 'Chamados de manutenção',
        description: 'Habilita ou desabilita o módulo de chamados de manutenção.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'maintenanceTickets'
      },
      MAINTENANCE_TICKETS_VISIBILITY: {
        label: 'Condômino poderá visualizar todos os chamados de manutenção',
        description:
          'Quando habilitado, o condômino poderá ver todos chamados de manutenção, quando desabilitado, o condômino visualizará somente os chamados de manutenção que ele criou.',
        true: 'ANYONE',
        false: 'RESTRICTED',
        value: '',
        toggleValue: '',
        paramName: 'maintenanceTicketsVisibility'
      },
      LOST_AND_FOUNDS: {
        label: 'Achados e perdidos',
        description: 'Habilita ou desabilita o módulo de achados e perdidos.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'lostAndFounds'
      },
      CONDO_RATING: {
        label: 'Avaliações',
        description: 'Habilita ou desabilita o módulo de avaliações do(a) condomínio.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'condoRating'
      },
      CONDO_FAQ: {
        label: 'Perguntas frequentes',
        description: 'Habilita ou desabilita o módulo de perguntas frequentes do(a) condomínio.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'condoFaq'
      },
      ADVICES: {
        label: 'Avisos',
        description: 'Habilita ou desabilita o módulo de avisos do(a) condomínio.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'advices'
      },
      OCCURRENCES: {
        label: 'Ocorrências',
        description: 'Habilita ou desabilita o módulo de ocorrências do(a) condomínio.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'occurrences'
      },
      VIRTUAL_KEY: {
        label: 'Chave virtual',
        description: 'Habilita ou desabilita o uso da chave virtual por condôminos.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'virtualKey'
      },
      CAMERAS: {
        label: 'Câmeras para condômino',
        description: 'Habilita ou desabilita a visualização das câmeras liberadas para condôminos no aplicativo.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'cameras'
      },
      SEARCHABLE: {
        label: 'Busca para novos usuários',
        description: 'Habilita ou desabilita a visualização do(a) condomínio quando novos usuários se cadastram.',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'searchable'
      },
      PETS: {
        label: 'Pets',
        description: 'Habilita ou desabilita o módulo de Pets',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'pets'
      },
      PANIC_BUTTON: {
        label: 'Botão de pânico',
        description: 'Habilita ou desabilita o Botão de Pânico',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'panicButton'
      },
      INTERCOM: {
        label: 'Intercom',
        description: 'Habilita ou desabilita o módulo de Interfone',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'intercom'
      },
      GATE_CHAT: {
        label: 'Chat com a portaria',
        description: 'Habilita ou desabilita o módulo de chat com a portaria',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'gateChat'
      },
      RESIDENCE_HARDWARE_EVENTS_HISTORY: {
        label: 'Histórico de acesso de moradores de unidade',
        description: 'Habilita ou desabilita o histórico de acesso de moradores de unidade',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        paramName: 'residenceHardwareEventsHistory'
      },
      WHATSAPP_BOT: {
        label: 'Liberar uso do bot do whatsapp',
        description: 'Quando ativado, permitirá ao condomínio integrar-se ao Bot do WhatsApp',
        true: 'ENABLED',
        false: 'DISABLED',
        value: '',
        toggleValue: '',
        originalValue: '',
        paramName: 'whatsappBot'
      }
    };
  }

  ngOnInit(): void {
    this.condoService.getCondoById(this.condoId, this.query).subscribe(
      condo => this.setParams(condo),
      err => this.router.navigate(['/home'])
    );
  }

  getResidences(condoId: string) {
    this.residenceService.getResidencesCount(condoId).subscribe(
      count => {
        this.residencesCount = count;
      },
      err => {
        console.log(err);
      }
    );
  }

  showModal(condo?: any): void {
    const modal: NzModalRef = this.ModalService.create({
      nzTitle: 'Cadastrar responsavel financeiro',
      nzContent: ModalCreateFinancialResponsible,
      nzComponentParams: { condo },
      nzWidth: 800,
      nzFooter: [
        {
          label: 'Close',
          type: 'primary',
          onClick: () => modal.destroy()
        }
      ]
    });
  }

  showModalWhiteLabelSelect(condo: Condo, hasNew: boolean): void {
    this.ModalService.create({
      nzTitle: !hasNew ? 'Alterar whitelabel' : 'Adicionar whitelabel',
      nzContent: ModalWhiteLabelSelect,
      nzComponentParams: { hasNew, condo },
      nzFooter: null
    });
  }

  updateSelfConfigPermition(condo: Condo) {
    this.condoService.updateCondo(condo._id, { selfConfig: condo.selfConfig === 'ENABLED' ? 'DISABLED' : 'ENABLED' }).subscribe(
      () => {
        condo.selfConfig = condo.selfConfig === 'ENABLED' ? 'DISABLED' : 'ENABLED';
      },
      err => {
        this.notification.create('error', 'Erro', 'Não foi possível atualizar permissões de configurações do condomínio');
      }
    );
  }

  showNotifyModal(condo: Condo): void {
    this.ModalService.create({
      nzTitle: 'Enviar notificação',
      nzContent: ModalNotifyCondo,
      nzComponentParams: { condo },
      nzFooter: null
    });
  }

  setParams(condo: Condo) {
    const condoParams = condo.params;

    for (const key in this.CONDO_PARAMS) {
      if (this.CONDO_PARAMS.hasOwnProperty(key)) {
        const param = this.CONDO_PARAMS[key];
        param.value = condoParams[param.paramName];
        param.originalValue = param.value == param.true;
        param.toggleValue = param.value == param.true;
      }
    }

    this.condoParamsKeys = Object.keys(this.CONDO_PARAMS);
  }

  onPlanChange() {
    this.hasChanged = true;

    for (const key in this.CONDO_PARAMS) {
      const paramName = this.CONDO_PARAMS[key].paramName;
      const planOptions = this.PLANS[this.plan];
      if (planOptions.find(option => option === paramName)) {
        this.CONDO_PARAMS[key].toggleValue = false;
      } else {
        this.CONDO_PARAMS[key].toggleValue = true;
      }
    }
  }

  updateCondoConfigs(condo: Condo) {
    this.status = this.STATUS.LOADING;
    const params: { [key: string]: string } = {};
    // Constrói o objeto com todos parâmetros do condo
    for (const key in this.CONDO_PARAMS) {
      if (this.CONDO_PARAMS.hasOwnProperty(key)) {
        const param = this.CONDO_PARAMS[key];
        params[param.paramName] = param[param.toggleValue ? 'true' : 'false'];
      }
    }
    const condoParams = {
      params: params
    };

    this.condoService
      .updateCondo(condo?._id, condoParams)
      .pipe(timeout(10000))
      .subscribe(
        data => {
          this.status = this.STATUS.SUCCESS;
          condo.params = params;
          this.setParams(condo);
          this.notification.create('success', 'Atualização de Parâmetros', 'Parâmetros atualizados com sucesso');
        },
        err => {
          this.status = this.STATUS.ERROR;
          this.notification.create('error', 'Atualização de Parâmetros', 'Não foi possível atualizar os parâmetros, tente novamente');
        }
      );
  }
}

interface Condo_Params {
  label: string;
  description: string;
  true: string;
  false: string;
  value: string;
  toggleValue: string | boolean;
  originalValue?: string | boolean;
  paramName: string;
}
