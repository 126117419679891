<nz-list [nzBordered]="bordered">
  <nz-list-item *ngFor="let item of getEntries(data)" style="justify-content: start">
    <strong class="mr-2">{{ item[0] }}: </strong>
    <!-- Tratamento para objeto -->
    <ng-container *ngIf="isObject(item[1]) && !isArray(item[1])">
      <app-object-tree-view [data]="item[1]" [bordered]="false"></app-object-tree-view>
    </ng-container>
    <!-- Tratamento para array -->
    <ng-container *ngIf="isArray(item[1])">
      <ul>
        <li *ngFor="let subItem of item[1]">
          <!-- Verifica se o subItem é um objeto ou um valor simples -->
          <ng-container *ngIf="isObject(subItem); else simpleValue">
            <app-object-tree-view [data]="subItem" [bordered]="false"></app-object-tree-view>
          </ng-container>
          <ng-template #simpleValue>
            {{ subItem }}
          </ng-template>
        </li>
      </ul>
    </ng-container>
    <!-- Tratamento para valores simples -->
    <ng-container *ngIf="!isObject(item[1]) && !isArray(item[1])">
      {{ item[1] }}
    </ng-container>
  </nz-list-item>
</nz-list>
