import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '@environment';
import {Observable} from 'rxjs';

export interface OneSignalApp {
  name: string;
  apns_p12: string;
  apns_p12_password: string;
  android_gcm_sender_id: string;
  organization_id: string;
  gcm_key: string;
}

@Injectable({providedIn: 'root'})
export class OneSignalService {
  private endPoint = 'https://onesignal.com/api/v1/apps/'
  private headers!: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      authorization: `Basic ${environment.ONE_SIGNAL_AUTH_KEY}`
    })
  }

  public getApp(app_id: string): Observable<{ name: any; id: any }> {
    return this.http.get(`${this.endPoint}${app_id}`, {headers: this.headers}).pipe(
      map((response: any) => ({id: response?.id, name: response?.name}))
    )
  }

  public createApp(app: OneSignalApp): Observable<{ authKey: any; name: any; id: any }> {
    return this.http.post(this.endPoint, app, {headers: this.headers}).pipe(
      map((response: any) => ({id: response?.id, name: response?.name, authKey: response?.basic_auth_key}))
    )
  }
}
