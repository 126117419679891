export const readFileAsDataURL = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = fileReaderEvent => {
      const fileContent = fileReaderEvent.target?.result?.toString();

      if (fileContent) {
        resolve(fileContent);
      }
    };

    reader.onerror = errorEvent => reject(errorEvent);

    reader.readAsDataURL(file);
  });
};
