// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendUrl: 'https://api-staging.econdos.com.br/api/v2/',
  backendUrlv1: 'https://api-staging.econdos.com.br/api/v1/',
  socketUrl: 'wss://api-staging.econdos.com.br',
  ONE_SIGNAL_AUTH_KEY: 'xxxxxxxxxxxxxxxx',
  ONE_SIGNAL_WHITELABEL_ORG_ID: '43698991-270a-402b-a95f-f1d745649aee'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
