import {CondoService} from '@api/service/condo.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Condo} from '@api/model/condo';
import {EcondosQuery} from '@api/model/query';
import {generateCsv, generateReport} from '../../../../util/report-generator';
import {SessionService} from '../../../../service/session.service';
import {Log} from '@api/model/log';
import {LogsService} from '@api/service/logs.service';
import {FormControl} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  retry,
  switchMap,
  takeUntil,
  tap,
  timeout
} from 'rxjs/operators';
import {noop, Subject} from 'rxjs';

interface TableField {
  value: string,
  label: string
}

@Component({
  selector: 'app-logs-page',
  templateUrl: './condo-logs-page.component.html',
  styleUrls: ['./condo-logs-page.component.scss']
})
export class CondoLogsPageComponent implements OnInit, OnDestroy {
  public logs: Log[] = [];
  public condos: Condo[] = [];

  public nzPageSize: number;
  public nzPageIndex: number;

  public fields: TableField[];

  public canGoToNextPage = true;

  public searchCondo: FormControl = new FormControl('');
  public searchRouteUrl: FormControl = new FormControl('');
  public searchRequestMethod: FormControl = new FormControl('');

  public selectedCondo = '';
  public selectedSortBy = '';
  public selectedRangeDate = [new Date(), new Date()];

  nzFilterOption = (): boolean => true;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private logsService: LogsService,
    private condoService: CondoService,
  ) {
    this.nzPageSize = 10;
    this.nzPageIndex = 1;
    this.fields = [
      { value: 'condo', label: 'Condomínio' },
      { value: 'user', label: 'Usuário' },
      { value: 'date', label: 'Data' },
      { value: 'method', label: 'Tipo' },
      { value: 'url', label: 'URL' },
      { value: 'status', label: 'Status' }
    ];
  }

  ngOnInit(): void {
    this.getData();

    this.searchCondo.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      tap(v => !(v || '').length ? this.condos = [] : null),
      filter(v => !!v && v.length >= 3),
      switchMap(condoName =>
        this.condoService.getCondosWithCount({$select: 'name', name: {$regex: condoName, $options: 'i'}}).pipe(
          timeout(10000),
          map(({data: condos}) => condos),
          retry(3)
        )
      ),
      tap(condos => this.condos = condos),
      takeUntil(this.destroyed$)
    ).subscribe(noop)

    this.searchRequestMethod.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      tap(() => {
        this.logs = [];
        this.getData(1);
      }),
      takeUntil(this.destroyed$)
    ).subscribe(noop)

    this.searchRouteUrl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      tap(() => {
        this.logs = [];
        this.getData(1);
      }),
      takeUntil(this.destroyed$)
    ).subscribe(noop)
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getData(page?: number) {
    this.nzPageIndex = page || this.nzPageIndex;
    const query: EcondosQuery = {
      $limit: this.nzPageSize,
      $page: this.nzPageIndex,
    };
    if (this.selectedCondo) {
      query.condo = this.selectedCondo;
    }
    if (this.searchRouteUrl.value) {
      query.url = this.searchRouteUrl.value.trim();
    }
    if (this.searchRequestMethod.value) {
      query.method = this.searchRequestMethod.value.trim();
    }
    if (this.selectedSortBy) {
      query.$sort = this.selectedSortBy;
    }
    if (this.selectedRangeDate) {
      const [startDate, endDate] = this.selectedRangeDate;
      query.startDate = startDate;
      query.endDate = endDate;
    }
    this.logsService.get(query).subscribe((logs) => {
      this.logs = logs
      this.canGoToNextPage = !(logs.length < this.nzPageSize)
    }, err => {
      console.log(err);
    })
  }

  public goToNextPage(): void {
    this.logs = [];
    this.nzPageIndex++;
    this.getData();
  }

  public goToPreviousPage(): void {
    this.logs = [];
    this.nzPageIndex--;
    this.getData();
  }

  handleCondoFilter(value: string) {
    this.selectedCondo = value;
    this.getData();
  }

  closeCondoFilter() {
    this.selectedCondo = '';
    this.getData();
  }

}
