<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="book"></i>
      Contratos
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title>Contratos</nz-page-header-title>
</nz-page-header>

<nz-dropdown-menu #name="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input
        type="text"
        nz-input
        placeholder="Buscar por nome"
        [(ngModel)]="searchName"
      />
      <button
        class="reset-button"
        nz-button
        nzSize="small"
        (click)="searchName = ''; getData()"
      >
        Limpar
      </button>
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        class="search-button"
        (click)="getData()"
      >
        Buscar
      </button>
    </div>
  </div>
</nz-dropdown-menu>

<nz-dropdown-menu #id="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input
        type="text"
        nz-input
        placeholder="Buscar por id"
        [(ngModel)]="searchId"
      />
      <button
        class="reset-button"
        nz-button
        nzSize="small"
        (click)="searchId = ''; getData()"
      >
        Limpar
      </button>
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        class="search-button"
        (click)="getData()"
      >
        Buscar
      </button>
    </div>
  </div>
</nz-dropdown-menu>



<div class="px-4">
  <nz-table #contractsTable [nzData]="condos" [nzFrontPagination]="false">
    <thead>
    <tr>
      <th nzCustomFilter [nzSortFn]="compare">
        Nome
        <nz-filter-trigger
          [(nzVisible)]="filterNameView"
          [nzActive]="searchName.length > 0"
          [nzDropdownMenu]="name"
        >
          <i class="search-icon" nz-icon nzType="search"></i>
        </nz-filter-trigger>
      </th>
      <th nzCustomFilter [nzSortFn]="compare">Localização</th>
      <th nzCustomFilter [nzSortFn]="compare">
        ID do Condomínio
        <nz-filter-trigger
          [(nzVisible)]="filterIdView"
          [nzActive]="searchId.length > 0"
          [nzDropdownMenu]="id"
        >
          <i class="search-icon" nz-icon nzType="search"></i
          ></nz-filter-trigger>
      </th>

      <th nzCustomFilter [nzSortFn]="compareDates">Início do Contrato</th>
      <th>Ações</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let condo of contractsTable.data">
      <td>
        {{ condo?.name }}
      </td>
      <td>
        {{ condo?.address }}
      </td>
      <td>
        {{ condo?._id }}
      </td>
      <td>
        {{
        condo.sale?.contractStartDate
          ? (condo.sale.contractStartDate | amTimeAgo)
          : "Data não informada"
        }}
      </td>
      <td>
        <button  [routerLink]="['/home', 'condos', condo?._id]" nz-button nzType="primary">Detalhes</button>
      </td>
    </tr>
    </tbody>
  </nz-table>
  <nz-pagination
    nz-row
    nzJustify="end"
    nzShowSizeChanger
    [nzTotal]="totalData"
    [nzPageIndex]="0"
    (nzPageIndexChange)="refreshIndexChange($event)"
    (nzPageSizeChange)="refreshSizeChange($event)"
  ></nz-pagination>
</div>
