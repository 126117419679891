<nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
  <input
    type="text"
    placeholder="Busque por um condomínio"
    nz-input
    [(ngModel)]="inputValue"
    (input)="onChange($event)"
    [nzAutocomplete]="auto"
    [disabled]="filtering"
  />
  <nz-autocomplete #auto>
    <nz-auto-option
      *ngFor="let option of options$ | async"
      [nzValue]="option._id"
      [nzLabel]="option.name"
      (selectionChange)="handleSelectionChange($event)"
    >
      <nz-avatar nzShape="square" [nzSrc]="option.pictures[0]?.url || '/assets/img/default-condo-picture.png'" [nzText]="option.name" [nzAlt]="option.name"></nz-avatar>
      <span>{{ option.name }}</span>
    </nz-auto-option>
  </nz-autocomplete>
</nz-input-group>

<ng-template #suffixIconButton>
  <button
    *ngIf="!filtering"
    nz-button
    nzType="primary"
    nzSearch
    (click)="handleSearch()"
    [disabled]="inputValue.length < 3 || !itemSelected"
  >
    <i nz-icon nzType="search"></i>
  </button>
  <button *ngIf="filtering" nz-button nzDanger>
    <i nz-icon nzType="close" nzTheme="outline" (click)="handleOnClose()"></i>
  </button>
</ng-template>
