<nz-layout class="fixed-side">
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" nzWidth="200px">
    <div class="side-menu-header">
      <img class="logo" src="assets/img/logo.png" (click)="isCollapsed = !isCollapsed">
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline">
      <ng-container *ngFor="let menuItem of menuItems">
        <li *ngIf="menuItem.subMenus?.length" nz-submenu [nzTitle]="menuItem.title" [nzIcon]="menuItem.icon" [nzOpen]="activatedRoute === menuItem.route">
          <ul>
            <li nz-menu-item [nzMatchRouter]="true" [nzMatchRouterExact]="true" *ngFor="let subMenu of menuItem.subMenus">
              <a [routerLink]="subMenu.route">
                <i nz-icon [nzType]="subMenu.icon"></i> {{subMenu.title}}
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="!menuItem.subMenus?.length" nz-menu-item [nzMatchRouter]="true" [nzMatchRouterExact]="true">
          <a [routerLink]="menuItem.route">
            <i nz-icon [nzType]="menuItem.icon"></i> <span *ngIf="!isCollapsed">{{menuItem.title}}</span>
          </a>
        </li>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout class="right-side">
    <nz-header class="page-header">
      <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="isCollapsed = !isCollapsed"></i>
      <div class="user">
        <nz-avatar [nzSize]="32" [nzSrc]="user.picture?.thumbnail" nzIcon="user"></nz-avatar>
        <div class="user-info">
          <p>{{user.firstName}} {{user.lastName}}</p>
        </div>
        <nz-divider nzType="vertical"></nz-divider>
        <button nz-button nzType="default" nzSize="small" nzDanger (click)="logout()">
          <i nz-icon nzType="poweroff"></i> Sair
        </button>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
