import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

interface DashboardItem {
  route: string;
  title: string;
  icon: string;
}

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent {

  items: DashboardItem[] = [
    {icon: 'shop', title: 'Whitelabels', route: 'whitelabels'},
    {icon: 'user', title: 'Usuários', route: 'users'},
    {icon: 'shop', title: 'Condomínios', route: 'condos'},
    {icon: 'contacts', title: 'Parceiros', route: 'partners'},
    {icon: 'database', title: 'Logs', route: 'logs'},
  ]

  data$: Observable<any>;

  constructor(public route: ActivatedRoute) {
    this.data$ = route.data.pipe(
      map(data => data.data)
    );
  }
}
