import {Component, OnInit} from '@angular/core';
import {Condo} from '@api/model/condo';
import {EcondosQuery} from '@api/model/query';
import {WhiteLabel} from '@api/model/white-label';
import {CondoService} from '@api/service/condo.service';
import {WhiteLabelService} from '@api/service/white-label.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-modal-white-label-select',
  templateUrl: './modal-white-label-select.html',
  styleUrls: ['./modal-white-label-select.scss'],
})
export class ModalWhiteLabelSelect implements OnInit {
  condo!: Condo;
  hasNew: boolean | undefined

  whiteLabels: WhiteLabel[] = [];
  selectedWhiteLabel: string = '';

  constructor(
    private whitelabelService: WhiteLabelService,
    private notification: NzNotificationService,
    private ModalService: NzModalService,
    private condoService: CondoService
  ) {
  }

  ngOnInit(): void {
    this.getWhitelabels()
  }

  getWhitelabels() {
    const query: EcondosQuery = {
      $select: 'companyName',
      $limit: 500
    }
    this.whitelabelService.get(query).subscribe(whitelabels => {
      this.whiteLabels = whitelabels;
      this.selectedWhiteLabel = this.condo.whiteLabel?._id || ''
    }, err => {
      console.log(err);
      this.notification.create('error', 'Busca', 'Não foi possível buscar white labels');
    });
  }

  submit() {
    if (this.selectedWhiteLabel) {
      this.condoService.updateCondo(this.condo?._id as string, {whiteLabel: this.selectedWhiteLabel}).subscribe(res => {
        this.notification.create('success', 'Whitelabel', 'Whitelabel alterado com sucesso!');
        const whitelabel = this.whiteLabels.find(a => a._id === this.selectedWhiteLabel)
        this.condo.whiteLabel = whitelabel
        this.closeModal()
      }, err => {
        this.notification.create('error', 'Whitelabel', 'Whitelabel não pode ser alterado!');
        console.error(err);
      })
    }
  }

  removeWhitelabel() {
    this.condoService.updateCondo(this.condo?._id as string, {whiteLabel: null}).subscribe(res => {
      this.notification.create('success', 'Whitelabel', 'Whitelabel removido com sucesso');
      this.condo.whiteLabel = null
      this.closeModal()
    }, err => {
      this.notification.create('error', 'Whitelabel', 'Whitelabel não pode ser alterado!');
      console.error(err);
    })
  }

  closeModal() {
    this.ModalService.closeAll()
  }
}
