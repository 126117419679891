import { Component, Input } from '@angular/core';
import { Highlight } from '@api/model/highlight';

@Component({
  selector: 'app-highlight-card',
  templateUrl: 'highlight-card.component.html',
  styleUrls: ['./highlight-card.component.scss']
})
export class HighlightCardComponent {
  @Input() highlight: Highlight | undefined;
}
