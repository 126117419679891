import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isBefore, startOfDay } from 'date-fns';

export const beforeTodayValidator = (): ValidatorFn => {
  return (control: AbstractControl) => {
    const dateToCompare = startOfDay(new Date());
    const controlDate = startOfDay(control.value);

    return isBefore(controlDate, dateToCompare) ? { isBeforeDate: true } : null;
  };
};
