import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-object-tree-view',
  templateUrl: 'object-tree-view.component.html',
  styleUrls: ['./object-tree-view.component.scss']
})
export class ObjectTreeViewComponent {
  @Input() data!: {};
  @Input() bordered = true;
  constructor() {}
  getEntries(obj: any): [string, any][] {
    return Object.entries(obj);
  }

  isObject(value: any): boolean {
    return value && typeof value === 'object' && !Array.isArray(value);
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }
}
