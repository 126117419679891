import {NgModule} from '@angular/core';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzCardModule} from "ng-zorro-antd/card";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzSpaceModule} from "ng-zorro-antd/space";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzResultModule} from "ng-zorro-antd/result";
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import {NzStatisticModule} from 'ng-zorro-antd/statistic';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {NzSelectModule} from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzListModule } from 'ng-zorro-antd/list';
import {NzMessageModule} from 'ng-zorro-antd/message';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import {NzAutocompleteModule} from "ng-zorro-antd/auto-complete";
import { NzAlertModule } from 'ng-zorro-antd/alert';

@NgModule({
  exports: [
    NzDatePickerModule,
    NzPaginationModule,
    NzBreadCrumbModule,
    NzButtonModule,
    NzCardModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzInputModule,
    NzLayoutModule,
    NzPageHeaderModule,
    NzResultModule,
    NzSpaceModule,
    NzTableModule,
    NzModalModule,
    NzAvatarModule,
    NzCollapseModule,
    NzNotificationModule,
    NzTagModule,
    NzPaginationModule,
    NzCheckboxModule,
    NzStatisticModule,
    NzSelectModule,
    NzDropDownModule,
    NzStepsModule,
    NzDividerModule,
    NzRadioModule,
    NzToolTipModule,
    NzListModule,
    NzMessageModule,
    NzSwitchModule,
    NzAutocompleteModule,
    NzAlertModule
  ]
})
export class NgZorroAntdModule {

}
