import { Injectable } from '@angular/core';
import { EcondosQuery } from '@api/model/query';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as qs from 'qs';
import { BuildHighlight, Highlight } from '@api/model/highlight';
import { File } from '@api/model/file';
import { environment } from '@environment';

export type SaveHighlightDTO = Pick<
  Highlight,
  'title' | 'description' | 'presentationMode' | 'link' | 'linkLabel' | 'position' | 'isActive'
> & {
  startDate: string;
  endDate: string | null;
  whiteLabels: string[];
  picture?: File;
};

@Injectable({ providedIn: 'root' })
export class HighlightsService {
  protected endPoint = environment.backendUrl;

  constructor(protected http: HttpClient) {}

  getHighlights(
    query: EcondosQuery = {}
  ): Observable<{
    count: number;
    highlights: Highlight[];
  }> {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http.get(`${this.endPoint}highlights`, options).pipe(
      map((res: any) => ({
        count: res.headers.get('count'),
        highlights: res.body.map((highlight: any) => BuildHighlight(highlight))
      }))
    );
  }

  getHighlightById(highlightId: Highlight['_id'], query: EcondosQuery = {}) {
    const httpParams = new HttpParams({ fromString: qs.stringify(query) });

    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };

    return this.http
      .get(`${this.endPoint}highlights/${highlightId}`, options)
      .pipe(map((res: any) => BuildHighlight(res.body)));
  }

  createHighlight(data: SaveHighlightDTO) {
    return this.http.post(`${this.endPoint}highlights`, data);
  }

  updateHighlight(highlightId: Highlight['_id'], highlight: SaveHighlightDTO) {
    return this.http.put(`${this.endPoint}highlights/${highlightId}`, highlight);
  }

  deleteHighlight(highlightId: Highlight['_id']) {
    return this.http.delete(`${this.endPoint}highlights/${highlightId}`);
  }

  toggleHighlight(highlightId: Highlight['_id'], { isActive }: { isActive: boolean }) {
    return this.http.put(`${this.endPoint}highlights/${highlightId}`, { isActive });
  }
}
