import {Injectable, Input} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {CondoService} from "@api/service/condo.service";
import {EcondosQuery} from "@api/model/query";
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CondosResolver implements Resolve<any> {
  nzPageSize: number;
  nzPageIndex: number;

  constructor(private _router: Router, private condoService: CondoService) {
    this.nzPageSize = 10;
    this.nzPageIndex = 0;
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {
    const stateData = this._router.getCurrentNavigation()?.extras.state
    if (stateData?.condos) {
      return of({condos: stateData.condos })
    } else {
      const whiteLabelId = route.params.whiteLabelId;
      const query: EcondosQuery = {
        $select: 'name cnpj createdAt plan address city state daysOfTrial hikvision controlId utech intelbras linear phones',
        $limit: this.nzPageSize,
        $page: this.nzPageIndex,
      };
      if(whiteLabelId){
        query.whiteLabel = whiteLabelId
      }
      return this.condoService.getCondosAsSystemAdmin(query).pipe(map(({data, count}) => ({data, count, query })));
    }
  }
}
