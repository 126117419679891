import {Component, OnInit} from '@angular/core';
import {Condo} from '@api/model/condo';
import {CondoService} from '@api/service/condo.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {timeout} from 'rxjs/operators';

@Component({
  selector: 'app-modal-notify-condo',
  templateUrl: './modal-notify-condo.html',
  styleUrls: ['./modal-notify-condo.scss']
})
export class ModalNotifyCondo implements OnInit {
  condo!: Condo;

  form: FormGroup;

  constructor(
    private notification: NzNotificationService,
    private modalService: NzModalService,
    private condoService: CondoService,
    private formBuilder: FormBuilder
  ) {

    this.form = this.formBuilder.group({
      title: ['Produtos e serviços', Validators.required],
      message: ['Mensagem sobre produtos e serviços', Validators.required],
      type: ['OPEN-URL', Validators.required],
      url: ['https://lar-e-assistencia.com.br', Validators.required]
    })

  }

  ngOnInit(): void {
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const notificationData = form.value;
      this.condoService.notifyCondo(this.condo._id, notificationData).pipe(
        timeout(50000)
      ).subscribe(res => {
        this.notification.create('success', 'Notificação enviada', 'A notificação foi enviada');
        this.closeModal();
      }, err => {
        console.log(err);
        const error = err.error?.error || err.toString();
        this.notification.create('error', 'Notificação não enviada', error);
      })
    }
  }

  closeModal() {
    this.modalService.closeAll()
  }
}
