<div>
  <div>
    <p>Whitelabel</p>
     <nz-select style="width: 80%" nzShowSearch nzAllowClear nzPlaceHolder="Selecione o whitelabel" [(ngModel)]="selectedWhiteLabel">
      <nz-option *ngFor="let whitelabel of whiteLabels" [nzLabel]="whitelabel.companyName" [nzValue]="whitelabel._id"></nz-option>
    </nz-select>
    <br>
    <div style="display: flex; justify-content: flex-end; gap: 8px">
      <button style="margin-top: 20px;" nz-button nzType="primary" type="button" nzDanger (click)="closeModal()">Cancelar</button>
      <button *ngIf="condo.whiteLabel" style="margin-top: 20px;" nz-button nzType="default" type="button" (click)="removeWhitelabel()">Remover whitelabel</button>
      <button style="margin-top: 20px;" nz-button nzType="primary" type="button" (click)="submit()">Confirmar</button>
    </div>
  </div>
</div>

