import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import * as qs from 'qs';
import {EcondosQuery} from '../model/query';
import {WhiteLabel} from '../model/white-label';
import {environment} from '@environment';

@Injectable({providedIn: 'root'})
export class WhiteLabelService {

  private endPoint = `${environment.backendUrl}white-labels/`;

  constructor(private http: HttpClient) {
  }

  get(query: EcondosQuery = {}): Observable<WhiteLabel[]> {
    const params = new HttpParams({fromString: qs.stringify(query)});
    return this.http.get<WhiteLabel[]>(this.endPoint + '6J2usLV9ddSNaEy9V4z3cbp9RrApyaDM5vHrNWkY', {params});
  }

  getWithCount(query: EcondosQuery = {}): Observable<{ whiteLabels: WhiteLabel[], count: number }> {
    const params = new HttpParams({fromString: qs.stringify(query)});
    return this.http.get<any>(this.endPoint + '6J2usLV9ddSNaEy9V4z3cbp9RrApyaDM5vHrNWkY', {params, observe: 'response'})
      .pipe(map(({body: whiteLabels, headers}) => ({
          whiteLabels,
          count: parseInt(headers.get('count') || '0', 10)
        })
      ));
  }

  getWhitelabel(whitelabelId: string, query: EcondosQuery = {}): Observable<WhiteLabel> {
    const params = new HttpParams({fromString: qs.stringify(query)});
    return this.http.get<WhiteLabel>(`${this.endPoint}${whitelabelId}`, {params});
  }

  create(newWhiteLabel: WhiteLabel) {
    return this.http.post<WhiteLabel[]>(this.endPoint, newWhiteLabel);
  }

  update(whiteLabelId: string, updateWhiteLabel: WhiteLabel) {
    return this.http.put<WhiteLabel[]>(`${this.endPoint}${whiteLabelId}`, updateWhiteLabel);
  }

}
