<div class="content">
  <form nz-form [formGroup]="form" class="login-form" (ngSubmit)="login()">
    <nz-form-item>
      <nz-form-control nzErrorTip="Preencha o email!">
        <nz-input-group nzPrefixIcon="user">
          <input type="text" nz-input formControlName="username" placeholder="Insira o email"/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Preencha a senha!">
        <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
          <input [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Digite a senha"
                 formControlName="password"/>
        </nz-input-group>
        <ng-template #suffixTemplate>
          <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
             (click)="passwordVisible = !passwordVisible"></i>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <button nzBlock nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Entrar</button>
  </form>
</div>
