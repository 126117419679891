import {Condo} from '../api/model/condo';
import {User} from '@api/model/user';
import {CondoUser} from '@api/model/condo-user';

export const mapOrder = (array: any[], order: any, key: string) => {
  array.sort((a: any, b: any) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};



export let isValidCPF = (cpf = '') => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999")
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return false;

  return true;
};

export let isValidCNPJ = (cnpj = '') => {

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14)
    return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999")
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros: any = cnpj.substring(0, tamanho);
  let digitos: any = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0)) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1)) {
    return false;
  }

  return true;
};

export function formatPhone(phone: string) {
  if (phone) {
    phone = phone.toString();
    phone = phone.replace(/[^*\d]/g, '');                   // Remove tudo o que não é dígito exceto o asterisco
    phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2');  // Coloca parênteses em volta dos dois primeiros dígitos
    phone = phone.replace(/(\d)(\d{4})$/, '$1-$2');     // Coloca hífen entre o quarto e o quinto dígitos
  }
  return phone;
}

export function formatCep(cep: string) {
  if (cep) {
    cep = cep.toString();
    cep = cep.replace(/\D/g, ''); // Remove tudo o que não é dígito
    cep = cep.replace(/(\d)(\d{3})$/, '$1-$2'); // Coloca hífen separando os 2 grupos de dígitos
  }
  return cep;
}

export function formatCurrency(value: any) {
  try {
    let tmp = value + '';
    const isNegativeValue = tmp.indexOf('-') != -1;
    tmp = tmp.replace(/\D/g, '');
    tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    if (isNegativeValue && tmp) tmp = '-' + tmp;
    return tmp ? 'R$ ' + tmp : isNegativeValue ? 'R$ -' : '';
  } catch (e) {
    console.log(e);
    return 'R$ 0,00';
  }
}

export function formatPercent(value: number): string {
  return `${value} %`;
}

export function parsePercent(value: string): string {
  return value.replace(' %', '');
}

export function currencyFormatter(value: number): string {
  return formatCurrency(value);
}

export function parseCurrency(value: string): string {
  const sanitizedValue = value.replace(/[^0-9,]/g, '');
  return sanitizedValue.replace(/,/g, '.');
}
