import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '@api/model/user';
import {SessionService} from '../service/session.service';
import {UserService} from '@api/service/user.service';

interface MenuItem {
  icon: string;
  title: string;
  route: string;
  subMenus?: MenuItem[]
}

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  public isCollapsed = false;
  public activatedRoute = 'dashboard';

  public user!: User;

  public menuItems: MenuItem[] = [
    {
      icon: 'dashboard', title: 'Dashboard', route: 'dashboard'
    },
    {
      icon: 'shop', title: 'Whitelabels', route: 'whitelabels',
      subMenus: [
        {icon: 'unordered-list', title: 'Visualizar todos', route: 'whitelabels'},
        {icon: 'plus', title: 'Criar whitelabel', route: 'whitelabels/create'}
      ]
    },
    {
      icon: 'user', title: 'Usuários', route: 'users',
      subMenus: [
        {icon: 'unordered-list', title: 'Visualizar todos', route: 'users'},
      ]
    },
    {
      icon: 'shop', title: 'Condomínios', route: 'condos',
      subMenus: [
        {icon: 'unordered-list', title: 'Visualizar todos', route: 'condos'},
        {icon: 'book', title: 'Contratos', route:'contracts'}
      ]
    },
    {
      icon: 'dollar', title: 'Financeiro', route: 'financial',
      subMenus: [
        {icon: 'unordered-list', title: 'Visualizar todos', route: 'condoFinancialInfo'},
        {icon: 'plus', title: 'Cadastro financeiro', route: 'condoFinancialInfo/create'}
      ]
    },
    {
      icon: 'contacts', title: 'Parceiros', route: 'partners',
      subMenus: [
        {icon: 'unordered-list', title: 'Visualizar todos', route: 'partners'},
        {icon: 'plus', title: 'Criar Parceiro', route: 'partners/new'},
      ]
    },
    {
      icon: 'cluster', title: 'Marketplace', route: 'marketplace',
      subMenus: [
        {icon: 'unordered-list', title: 'Visualizar todos', route: 'marketplace'},
        {icon: 'plus', title: 'Criar Marketplace', route: 'marketplace/new'},
      ]
    },
    {
      icon: 'database', title: 'Logs', route: 'logs',
      subMenus: [
        {icon: 'unordered-list', title: 'Condomínios', route: 'logs/condos'},
        {icon: 'unordered-list', title: 'Hardwares', route: 'logs/hardwares'}
      ]
    },
    {
      icon: 'appstore', title: 'Funcionalidades', route: 'features',
      subMenus: [
        { icon: 'unordered-list', title: 'Visualizar todas', route: 'features' },
        { icon: 'star', title: 'Destaques', route: 'features/highlights' },
      ]
    },
    {
      icon: 'book', title: 'Termos de Uso', route: 'use-terms'
    },
  ]

  constructor(private router: Router,
              private sessionService: SessionService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.activatedRoute = this.router.url.substr(6);
    this.user = this.sessionService.user;
  }

  public logout(): void {
    this.userService.logout().subscribe(async () => {
      this.sessionService.clearSession();
      await this.router.navigateByUrl('/login');
    })
  }
}
