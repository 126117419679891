import { Component, OnDestroy, OnInit } from '@angular/core';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';
import { LogsService } from '@api/service/logs.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { HardwareLog } from '@api/model/hardwareLog';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ObjectTreeViewComponent } from '../../../../components/object-tree-view/object-tree-view.component';

interface TableField {
  value: string;
  label: string;
}

@Component({
  selector: 'app-logs-page',
  templateUrl: './hardware-logs-page.component.html',
  styleUrls: ['./hardware-logs-page.component.scss']
})
export class HardwareLogsPageComponent implements OnInit, OnDestroy {
  public hardwareLogs: HardwareLog[] = [];
  public condos: Condo[] = [];
  totalData = 0;

  public nzPageSize: number;
  public nzPageIndex: number;

  public fields: TableField[];
  showErrors = true;
  selectedCondo = '';
  public canGoToNextPage = true;

  public searchErrorStatus: FormControl = new FormControl('');
  public searchActuator: FormControl = new FormControl('');
  public selectedSortBy = '';
  public selectedRangeDate = [new Date(), new Date()];

  private destroyed$: Subject<boolean> = new Subject<boolean>();
  httpErrors: { [key: string]: string };

  constructor(
    private logsService: LogsService,
    private notificationService: NzNotificationService,
    private modal: NzModalService
  ) {
    this.httpErrors = {
      400: '400 - Requisição mal feita',
      401: '401 - Não autorizado',
      403: '403 - Proibido',
      404: '404 - Não encontrado',
      406: '406 - Conteúdo não aceito',
      408: '408 - Tempo da requisição excedido',
      409: '409 - Conflito',
      413: '413 - Tamanho da requisição excedido',
      429: '429 - Muitas requisições seguidas',
      431: '431 - Cabeçalho da requisição muito grande'
    };
    this.nzPageSize = 10;
    this.nzPageIndex = 1;
    this.fields = [
      { value: 'condo', label: 'Condomínio' },
      { value: 'user', label: 'Usuário' },
      { value: 'date', label: 'Data' },
      { value: 'method', label: 'Tipo' },
      { value: 'url', label: 'URL' },
      { value: 'status', label: 'Status' }
    ];
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getData(page?: number) {
    this.nzPageIndex = page || this.nzPageIndex;
    const query: EcondosQuery = {
      $limit: this.nzPageSize,
      $page: this.nzPageIndex
    };
    if (this.searchErrorStatus.value) {
      query.status = this.searchErrorStatus.value.trim();
    }
    if (this.selectedSortBy) {
      query.$sort = this.selectedSortBy;
    }
    if (this.selectedRangeDate) {
      const [startDate, endDate] = this.selectedRangeDate;
      query.startDate = startDate;
      query.endDate = endDate;
    }
    if (this.selectedCondo) {
      query.condo = this.selectedCondo;
    }
    if (this.searchActuator.value) {
      query.actuator = this.searchActuator.value;
    }
    (this.showErrors ? this.logsService.getHardwareErrorLogs(query) : this.logsService.getHardwareLogs(query)).subscribe({
      next: data => {
        this.totalData = data?.count;
        this.hardwareLogs = data.hardwareLogs;
        this.canGoToNextPage = !(this.hardwareLogs.length < this.nzPageSize);
      },
      error: err => {
        const errorMessage = err.error?.message || 'Erro na requisição';
        this.notificationService.error('Erro', errorMessage);
      }
    });
  }

  refreshIndexChange(value: any) {
    this.nzPageIndex = --value;
    this.getData();
  }

  refreshSizeChange(value: any) {
    this.nzPageSize = value;
    this.getData();
  }

  openLogInfo(hardwareLog: HardwareLog) {
    this.modal.create({
      nzTitle: 'Informações do log',
      nzContent: ObjectTreeViewComponent,
      nzComponentParams: { data: hardwareLog },
      nzWidth: 1024,
      nzCancelDisabled: true
    });
  }

  handleCondoFilter(value: string) {
    this.selectedCondo = value;
    this.getData();
  }

  closeCondoFilter() {
    this.selectedCondo = '';
    this.getData();
  }

  changeLogType() {
    this.getData();
    this.hardwareLogs = [];
  }
}
