import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {EcondosQuery} from '@api/model/query';
import {WhiteLabel} from '@api/model/white-label';
import {WhiteLabelService} from '@api/service/white-label.service';
import {noop, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzMessageService} from 'ng-zorro-antd/message';
import { ClipboardService } from 'ngx-clipboard';
import {UserService} from '@api/service/user.service';

@Component({
  selector: 'app-white-labels-page',
  templateUrl: './white-labels-page.component.html',
  styleUrls: ['./white-labels-page.component.scss']
})
export class WhiteLabelsPageComponent implements OnInit, OnDestroy {

  whiteLabels: WhiteLabel[] = [];
  nzPageSize = 10;
  nzPageIndex = 0;
  totalData = 0;

  searchByName = new FormControl('');
  searchByStatus = new FormControl([]);
  unsubscribe: Subject<boolean> = new Subject()

  listOfStatus: Array<{ label: string; value: string }> = [];

  statusKeys: any = {
    NEW: 'Novo',
    AWAITING_RESOURCES: 'Aguardando recursos',
    IN_REVIEW: 'Em revisão',
    DONE: 'Criado'
  }

  updateWhiteLabelStatusModalIsVisible = false;
  sendTestEmailIsModalIsVisible = false;

  newStatus = '';
  newObs = '';
  whitelabelIdToUpdate = '';
  emailSendToTest = '';

  constructor(private whitelabelService: WhiteLabelService,
              private notificationService: NzNotificationService,
              private messageService: NzMessageService,
              private clipboard: ClipboardService,
              private userService: UserService) {
    this.searchByName.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => {
        this.nzPageIndex = 0;
      }),
      switchMap(name => {
        const query: EcondosQuery = {}
        if (name) {
          query.companyName = {$regex: name, $options: 'i'}
        }
        return this.getWhiteLabels(query)
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(values => this.whiteLabels = values);

    this.searchByStatus.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.nzPageIndex = 0;
      }),
      switchMap(values => {
        const query: EcondosQuery = {};
        if (values.length) {
          query.status = {$in: values};
        }
        return this.getWhiteLabels(query);
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(values => this.whiteLabels = values);
  }

  ngOnInit(): void {
    const statusTypes = [
      {value: 'NEW', label: 'Novo'},
      {value: 'AWAITING_RESOURCES', label: 'Aguardando recursos'},
      {value: 'IN_REVIEW', label: 'Em revisão'},
      {value: 'DONE', label: 'Criado'}
    ];
    this.listOfStatus = statusTypes;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete()
  }

  getWhiteLabels(customQuery: EcondosQuery = {}): Observable<WhiteLabel[]> {
    let $select = 'companyName webAppUrl icon status obs x86Installer x64Installer customerSupportUrl';
    if (customQuery.$select) {
      $select += customQuery.$select;
    }
    const $page = this.nzPageIndex;
    const $limit = this.nzPageSize;
    const query: EcondosQuery = { ...customQuery, $select, $page, $limit }
    return this.whitelabelService.getWithCount(query).pipe(
      tap(({whiteLabels, count}) => {
        this.whiteLabels = whiteLabels;
        this.totalData = count;
      }),
      map(({whiteLabels}) => whiteLabels)
    )
  }

  handleUpdateStatusModalOk(): void {

    if (this.whitelabelIdToUpdate && (this.newObs || this.newStatus)) {
      const loading = this.messageService.loading('Atualizando...').messageId;
      const update: any = {
        obs: this.newObs || '',
        status: this.newStatus || this.whiteLabels[this.whiteLabels.findIndex(w => w._id === this.whitelabelIdToUpdate)].status || ''
      }
      this.whitelabelService.update(this.whitelabelIdToUpdate, update).subscribe(() => {
        this.messageService.remove(loading);
        this.updateWhiteLabelStatusModalIsVisible = false;
        this.whiteLabels[this.whiteLabels.findIndex(w => w._id === this.whitelabelIdToUpdate)].status = update.status;
        this.whiteLabels[this.whiteLabels.findIndex(w => w._id === this.whitelabelIdToUpdate)].obs = update.obs;
        this.notificationService.success('Status atualizado', 'O status do whitelabel selecionado foi atualizado');
      }, () => {
        this.messageService.remove(loading);
        this.notificationService.error('Erro ao atualizar', 'O status do whitelabel não foi atualizado');
      })
    }
  }

  handleUpdateStatusModalCancel(): void {
    this.newObs = '';
    this.newStatus = '';
    this.updateWhiteLabelStatusModalIsVisible = false;
  }

  handleSendTestEmailModalOk(): void {
    if (this.whitelabelIdToUpdate && this.emailSendToTest) {
      const loading = this.messageService.loading('Enviando e-mail de teste...').messageId;
      this.userService.resetUserWhiteLabelPassword(this.emailSendToTest, this.whitelabelIdToUpdate).subscribe(() => {
        this.messageService.remove(loading);
        this.sendTestEmailIsModalIsVisible = false;
        this.notificationService.success('E-mail enviado', 'Cheque sua caixa de entrada e verifique se chegou algum e-mail');
      }, () => {
        this.messageService.remove(loading);
        this.notificationService.error('Erro ao enviar e-mail', 'Tente novamente!');
      })
    }
  }

  handleSendTestEmailModalCancel(): void {
    this.emailSendToTest = '';
    this.sendTestEmailIsModalIsVisible = false;
  }

  copyLink(value: string=''): void {
    this.clipboard.copy(value);
    this.notificationService.success('Link copiada!','');
  }

  refreshIndexChange(value: any) {
    this.nzPageIndex = value - 1
    this.getWhiteLabels().subscribe(noop)
  }

  refreshSizeChange(value: any) {
    this.nzPageSize = value
    this.getWhiteLabels().subscribe(noop)
  }
}
