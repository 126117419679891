import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {WhiteLabelService} from "@api/service/white-label.service";
import {map, tap} from "rxjs/operators";
import {EcondosQuery} from '@api/model/query';

@Injectable({providedIn: 'root'})
export class WhiteLabelResolver implements Resolve<any> {

  constructor(private _router: Router,
              private whitelabelService: WhiteLabelService) {
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<any> {
    const stateData = this._router.getCurrentNavigation()?.extras.state
    if (stateData?.whiteLabel) {
      return of(stateData.whiteLabel)
    } else {
      const query: EcondosQuery = {
        $select: 'status obs enabled name companyName oneSignalAppId oneSignalRestApiKey oneSignalGoogleSenderId mailgunKey mailgunDomain nodemailerUri emailjs nodemailerConfig emailPrefix emailSignature emailFromName emailFrom phone emailSupport webAppUrl applePackageName googlePackageName textReplacements filesReplacements cssVariables icon splash logo favIcon pageTitle banner qrCodePageUrl qrCodePageMessage appCenterAndroidAppSecret codePushAndroidProductionKey appCenterIOSAppSecret codePushIOSProductionKey ignoreActivationEmail createdBy updatedBy',
        _id: route.params?.whiteLabelId,
      };
      return this.whitelabelService.get(query).pipe(tap(data => console.log({data})),map(res => res[0]))
    }
  }
}
