<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="../../">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="shop"></i>
      Logs
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="shop"></i>
      Hardwares
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title
    >Logs de {{ showErrors ? 'erros de' : '' }} hardwares
    <i nz-icon nzType="exclamation-circle" nzTheme="outline" nz-tooltip="Os logs tem válidade de 15 dias no banco de dados"></i
  ></nz-page-header-title>
  <nz-page-header-subtitle> {{ totalData }} eventos </nz-page-header-subtitle>
  <nz-page-header-extra>
    <nz-range-picker
      class="mr-3"
      [nzFormat]="'dd/MM/yyyy'"
      [(ngModel)]="selectedRangeDate"
      [ngModelOptions]="{ standalone: true }"></nz-range-picker>
    <nz-select [(ngModel)]="selectedSortBy" nzPlaceHolder="Ordenar por" [ngModelOptions]="{ standalone: true }">
      <nz-option nzValue="-date" nzLabel="Mais recente"></nz-option>
      <nz-option nzValue="date" nzLabel="Mais antigo"></nz-option>
    </nz-select>
  </nz-page-header-extra>
  <nz-page-header-content style="display: flex; justify-content: space-between">
    <div class="control-toolbar" nz-row nzJustify="start" nzAlign="middle" nzGutter="15">
      <nz-col>
        <label class="mr-2">Mostrar log de erros</label>
        <nz-switch nzSize="default" [(ngModel)]="showErrors" (ngModelChange)="changeLogType()"></nz-switch>
      </nz-col>
    </div>
    <div class="control-toolbar" nz-row nzJustify="end" nzAlign="middle" nzGutter="15">
      <app-search-condo (filter)="handleCondoFilter($event)" (close)="closeCondoFilter()"> </app-search-condo>
      <nz-col>
        <input type="text" nz-input placeholder="Busque pelo id do acionador" [formControl]="searchActuator" />
      </nz-col>

      <nz-col *ngIf="showErrors">
        <nz-select nzPlaceHolder="Selecione o status da requisição" nzAllowClear [formControl]="searchErrorStatus">
          <nz-option *ngFor="let option of httpErrors | keyvalue" [nzValue]="option.key" [nzLabel]="option.value"></nz-option>
        </nz-select>
      </nz-col>
      <nz-col>
        <button nz-button nzType="primary" (click)="getData()">Buscar</button>
      </nz-col>
    </div>
  </nz-page-header-content>
</nz-page-header>

<div class="px-2">
  <nz-table #logsTable [nzData]="hardwareLogs" [nzFrontPagination]="false">
    <thead>
      <tr>
        <th *ngIf="showErrors">Status</th>
        <th>Tamanho (kB)</th>
        <th>Ip</th>
        <th>Data</th>
        <th>Condomínio</th>
        <th>Id do Atuador</th>
        <th>Nome do Atuador</th>
        <ng-container *ngIf="showErrors">
          <th>Mensagem de erro</th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of logsTable.data" (click)="openLogInfo(log)">
        <td *ngIf="showErrors">{{ log.status }}</td>
        <td>{{ log.contentLength / 1000 | number: '1.2-2' }} kB</td>
        <td>{{ log.ip }}</td>
        <td>{{ log.date | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
        <td>{{ log.condoName }}</td>
        <td>{{ log.actuator }}</td>
        <td>{{ log.actuatorName }}</td>
        <ng-container *ngIf="showErrors">
          <td>{{ log.errorMessage }}</td>
        </ng-container>
      </tr>
    </tbody>
  </nz-table>
</div>

<nz-pagination
  nz-row
  nzJustify="end"
  nzShowSizeChanger
  [nzTotal]="totalData"
  [nzPageIndex]="0"
  [nzPageSize]="nzPageSize"
  [nzPageSizeOptions]="[50, 100, 250, 500]"
  (nzPageIndexChange)="refreshIndexChange($event)"
  (nzPageSizeChange)="refreshSizeChange($event)">
</nz-pagination>
