import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../service/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  passwordVisible = false;

  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authService: AuthService) {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
  }

  login() {
    if (this.form.valid) {
      // @ts-ignore
      const username = this.form.get('username').value;
      // @ts-ignore
      const password = this.form.get('password').value;
      this.authService.login(username, password).subscribe(() => {
        this.router.navigate(['/home']);
      }, err => {
        alert('Login ou senha inválidos');
      })
    } else {
      this.form.markAllAsTouched();
    }
  }

}
