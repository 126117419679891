import { Residence } from './../model/residence';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as qs from 'qs';
import {EcondosQuery} from '../model/query';
import {environment} from '@environment';

@Injectable({providedIn: 'root'})
export class ResidenceService {

  private endPoint = `${environment.backendUrl}`;

  constructor(private http: HttpClient) {
  }

  getResidences(condoId: string, query: EcondosQuery = {}): Observable<Residence[]> {
    const params = new HttpParams({fromString: qs.stringify(query)});
    return this.http.get<Residence[]>(`${this.endPoint}condos/${condoId}/residences/`, {params});
  }
  
  getResidencesCount(condoId: string): Observable<number>{
    return this.http.get<number>(`${this.endPoint}condos/${condoId}/residences/?$count`);
  }

  getResidencesCountAllCondos(query: EcondosQuery = {}): Observable<number>{
    const params = new HttpParams({fromString: qs.stringify(query)});
    return this.http.get<number>(`${this.endPoint}my-condos/residences`, {params});
  }
  
}
