import {File} from './file';

export const PLANS_LABELS = {
  FREE: 'Grátis',
  GATE: 'Módulo Portaria',
  PREMIUM: 'Premium',
  TRIAL: 'Teste'
} as const;

export const TYPES_LABELS = {
  APARTMENT: 'Apartamento',
  HOUSE: 'Casa',
  BUSINESS: 'Comercial'
} as const;

export interface Condo {
  _id: string;
  name: string;
  alias: string;
  type: keyof typeof TYPES_LABELS;
  pictures: File[];
  documents: File[];
  address: string;
  number: string;
  extensionLine: string;
  params: any;
  reservationParams: any;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  cnpj: string;
  phones: string[];
  plan: { name: keyof typeof PLANS_LABELS; features: Array<string>; camerasLimit: number };
  daysOfTrial: number;
  neighborhood: string;
  createdBy: any;
  createdAt: string;
  updatedBy: any;
  generalInfo: any[];
  sale: any;
  whiteLabel: any;
  timeZone: string;
  linear: {
    licence: string,
    enabled: boolean,
    receivers: any[],
    residentQrCode: boolean,
    residentQrCodeTime: number,
    residentQrCodeCredits: number,
    residentPasswordFormat: 'RESIDENCE+PASS' | 'ONLY-PASS',
    serverMode?: {
      enabled: boolean,
      host: string,
      port: number,
      password: string
    }
  }
  hardwareParams: {
    gatekeeperCanRegisterDevice: boolean,
    gatekeeperCanEditDevice: boolean,
    gatekeeperCanDeleteDevice: boolean,
    visitorsQRCodeFromUser?: string,
    visitorsQRCodeFromGatekeeper?: string,
    visitorsQRCodeFromGatekeeperDefaultCredits?: number,
    visitorsQRCodeFromGatekeeperDefaultTime?: number,
    visitorsPasswordFromGatekeeper?: string,
    visitorsPasswordFromGatekeeperDefaultCredits?: number,
    visitorsPasswordFromGatekeeperDefaultTime?: number,
    visitorPasswordFromGatekeeperOnlyToLeave?: boolean
  };

  controlId: {
    enabled: boolean;
  };
  hikvision: {
    enabled: boolean;
  };
  utech: {
    enabled: boolean;
  };
  intelbras: {
    enabled: boolean;
  };

  selfConfig: string;
  deletedBy: any;
  deletedAt: string;

}

