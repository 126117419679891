import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface queryData {
  value: string;
  parameter: string
}


@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  @Input() dropdownOptions: {label: string, value: string}[] = [];

  @Output() onClose = new EventEmitter();
  @Output() onFilter = new EventEmitter<queryData>();

  constructor() {}

  searchParameter: string = '';
  searchValue: string = '';
  filtering: boolean = false;

  ngOnInit(): void {}

  handleOnClose() {
    this.filtering = false;
    this.searchValue = '';
    this.searchParameter = '';
    this.onClose.emit();
  }

  handleOnFilter() {
    this.filtering = true;
    this.onFilter.emit({value: this.searchValue, parameter: this.searchParameter});
  }

}
