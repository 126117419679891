import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "@environment";
import {Observable} from "rxjs";
import {UseTerms} from "@api/model/use-terms";
import {map} from "rxjs/operators";
import {EcondosQuery} from "@api/model/query";
import * as qs from 'qs';

@Injectable({providedIn: 'root'})
export class UseTermsService {

  private endPoint = `${environment.backendUrl}use-terms/`;

  constructor(private http: HttpClient) {
  }

  getByWhiteLabel(whiteLabelId: string): Observable<UseTerms> {
    return this.http.get(`${this.endPoint}whitelabel/${whiteLabelId}`).pipe(map(res => res as UseTerms))
  }

  get(query: EcondosQuery) {
    const params = new HttpParams({fromString: qs.stringify(query)});
    return this.http.get<UseTerms[]>(this.endPoint, {params});
  }

  create(data: UseTerms) {
    return this.http.post(`${this.endPoint}`, data)
  }

  update(useTermsId: string, data: {
    updateType: 'patch' | 'minor' | 'major',
    updatedUseTerms: UseTerms
  }) {
    return this.http.put(`${this.endPoint}${useTermsId}`, data)
  }

  toggleStatus(useTermsId: string) {
    return this.http.put(`${this.endPoint}toggle-status/${useTermsId}`, {})
  }
}
