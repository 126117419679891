<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <i nz-icon nzType="home"></i>
    </nz-breadcrumb-item>

    <nz-breadcrumb-item>
      <i nz-icon nzType="appstore"></i>
      Funcionalidades
    </nz-breadcrumb-item>

    <nz-breadcrumb-item>
      <i nz-icon nzType="star"></i>
      Destaques e informativos
    </nz-breadcrumb-item>

    <nz-breadcrumb-item>
      <i nz-icon nzType="plus"></i>
      Criar destaque
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <nz-page-header-title>
    Criar destaque
  </nz-page-header-title>

  <nz-page-header-extra>
    <button nz-button nzType="default" [routerLink]="highlightId ? '../..' : '..'" [disabled]="submitFormStatus === 'LOADING'">
      <i nz-icon nzType="close"></i>
      Cancelar
    </button>

    <button nz-button nzType="primary" (click)="handleSubmit()" [disabled]="submitFormStatus === 'LOADING'">
      <ng-container *ngIf="submitFormStatus !== 'LOADING'">
        <i nz-icon nzType="check"></i>
        Salvar
      </ng-container>

      <ng-container *ngIf="submitFormStatus === 'LOADING'">
        <i nz-icon nzType="reload" [nzSpin]="true"></i>
        Salvando...
      </ng-container>
    </button>
  </nz-page-header-extra>
</nz-page-header>

<nz-card class="p-4" *ngIf="fetchHighlightStatus === 'LOADING'">
  <nz-spin nzTip="Carregando destaques..."></nz-spin>
</nz-card>

<nz-card class="p-4" *ngIf="fetchHighlightStatus === 'ERROR'">
  <div style="text-align: center">
    <h2 nz-typography nzType="danger">Erro ao carregar destaques</h2>
    <h4 nz-typography>Ocorreu um erro ao carregar os destaques. Tente novamente, por favor.</h4>
    <button nz-button nzType="primary" (click)="fetchHighlight()">Tentar novamente</button>
  </div>
</nz-card>

<form nz-form [formGroup]="highlightForm" *ngIf="fetchHighlightStatus === 'SUCCESS'">
  <div nz-row class="p-3">
    <main nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24">Modo de apresentação</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-radio-group formControlName="presentationMode" nzSize="small" style="width: 100%">
            <nz-list nzBordered class="presentation-mode-picker">
              <label nz-list-item *ngFor="let option of presentationModeOptions | keyvalue" style="align-items: center; justify-content: flex-start; gap: 1.5rem">
                <span nz-radio [nzValue]="option.key"></span>
                <div style="display: flex; flex-direction: column">
                  <strong>{{ option.value.label }}</strong>
                  <strong nz-typography nzType="secondary" style="font-weight: normal">{{ option.value.description }}</strong>
                </div>
              </label>
            </nz-list>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24">Título</nz-form-label>
        <nz-form-control
          nzHasFeedback
          [nzSpan]="24"
          [nzValidateStatus]="title.touched && title.invalid ? 'error' : ''"
          nzErrorTip="Título é obrigatório">
          <input nz-input formControlName="title" placeholder="Ex.: Informativo do mês">
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24">Descrição</nz-form-label>
        <nz-form-control
          nzHasFeedback
          [nzSpan]="24"
          [nzValidateStatus]="description.touched && description.invalid ? 'error' : ''"
          nzErrorTip="Descrição é obrigatória">
          <textarea
            nz-input
            formControlName="description"
            placeholder="Digite as informações do destaque aqui..."
            rows="3"
            style="max-height: 20rem"></textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="presentationMode.value !== 'TEXT_ONLY'">
        <nz-form-label [nzSpan]="24">Imagem</nz-form-label>

        <nz-form-control
          nzHasFeedback
          [nzSpan]="24"
          nzExtra="A proporção ideal para a imagem é a de 16:9, com resolução mínima de 640x360.">
          <input #fileInput type="file" [hidden]="true" accept="image/*" [multiple]="false" (change)="handlePictureInputChange($event)" />

          <button
            nz-button
            nzBlock
            [nzDanger]="picture.invalid && picture.touched"
            (click)="fileInput.click()"
            [nzLoading]="isUploadingPicture"
            *ngIf="!picture.value">
            <ng-container *ngIf="!isUploadingPicture">
              <span nz-icon nzType="upload"></span>
              Selecionar imagem
            </ng-container>
            <ng-container *ngIf="isUploadingPicture">
              Carregando imagem...
            </ng-container>
          </button>

          <p nz-typography nzType="danger" *ngIf="picture.invalid && picture.touched">
            Imagem é obrigatória quando o modo de apresentação é "Somente imagem" ou "Texto e imagem"
          </p>

          <ng-container *ngIf="picture.value">
            <nz-card style="width: 100%" [nzCover]="coverTemplate" [nzActions]="[actionSetting, actionEdit]">
            </nz-card>

            <ng-template #coverTemplate>
              <img [src]="highlightUploadedPicture" class="highlight-picture__preview" />
            </ng-template>

            <ng-template #actionSetting>
              <button
                nz-button
                nzType="text"
                [nzDanger]="true"
                (click)="handleRemoveSelectedPicture(); fileInput.value = ''"
                [disabled]="isUploadingPicture"
                style="width: 100%">
                <i nz-icon nzType="delete"></i>
                Remover imagem
              </button>
            </ng-template>

            <ng-template #actionEdit>
              <button
                nz-button
                nzType="text"
                (click)="fileInput.click()"
                [nzLoading]="isUploadingPicture"
                style="width: 100%">
                <i nz-icon nzType="upload"></i>
                Alterar imagem
              </button>
            </ng-template>
          </ng-container>
        </nz-form-control>
      </nz-form-item>

      <div nz-row [nzGutter]="16">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Link</nz-form-label>
            <nz-form-control
              nzHasFeedback
              [nzSpan]="24"
              [nzValidateStatus]="link.touched && link.invalid ? 'error' : ''"
              nzErrorTip="Link em formato inválido">
              <input nz-input formControlName="link" placeholder="Ex.: https://saiba-mais.com">
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Rótulo do link</nz-form-label>
            <nz-form-control nzHasFeedback [nzSpan]="24">
              <input nz-input formControlName="linkLabel" placeholder="Ex.: Saiba mais">
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <nz-form-item>
        <nz-form-label [nzSpan]="24">WhiteLabels</nz-form-label>
        <nz-form-control>
          <app-search-whitelabel
            [showSearchButton]="false"
            [clearInputAfterSelect]="true"
            (selectWhiteLabel)="handleSelectWhiteLabel($event)"></app-search-whitelabel>

          <nz-list nzItemLayout="horizontal" nzBordered class="mt-2">
            <nz-list-item *ngFor="let whiteLabel of selectedWhiteLabels">
              <nz-list-item-meta [nzAvatar]="whiteLabel.icon || ''">
                <nz-list-item-meta-title>{{ whiteLabel.name }}</nz-list-item-meta-title>
                <nz-list-item-meta-description>{{ whiteLabel.companyName }}</nz-list-item-meta-description>
              </nz-list-item-meta>

              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <button nz-button nzType="default" nzDanger (click)="handleRemoveSelectedWhiteLabel(whiteLabel)">
                    <i nz-icon nzType="delete"></i>
                  </button>
                </nz-list-item-action>
              </ul>
            </nz-list-item>
            <nz-list-empty *ngIf="!selectedWhiteLabels.length" nzNoResult="Nenhum whitelabel selecionado ainda"></nz-list-empty>
          </nz-list>

          <p nz-typography nzType="danger" *ngIf="whiteLabels.invalid && whiteLabels.touched">
            Selecione ao menos um whitelabel
          </p>
        </nz-form-control>
      </nz-form-item>

      <div nz-row [nzGutter]="16">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Data inicial</nz-form-label>
            <nz-form-control nzHasFeedback [nzSpan]="24">
              <nz-date-picker formControlName="startDate" nzFormat="dd/MM/yyyy" style="width: 100%"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Data final</nz-form-label>
            <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Data final precisa ser maior que a data inicial">
              <nz-date-picker formControlName="endDate" nzFormat="dd/MM/yyyy" style="width: 100%"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <nz-form-item>
        <nz-form-label [nzSpan]="24">Posição</nz-form-label>
        <nz-form-control
          nzHasFeedback
          [nzSpan]="24"
          [nzValidateStatus]="position.touched && position.invalid ? 'error' : ''"
          nzErrorTip="A posição mínima é de 1">
          <div style="display: flex; align-items: center; gap: 0.25rem">
            <button nz-button nzType="default" [disabled]="position.value === 1" (click)="handleDecreasePosition()">
              <i nz-icon nzType="minus"></i>
            </button>

            <nz-input-number formControlName="position" [nzMin]="1" [nzStep]="1" nzPlaceHolder="Ex. 1" style="flex: 1"></nz-input-number>

            <button nz-button nzType="default" (click)="handleIncreasePosition()">
              <i nz-icon nzType="plus"></i>
            </button>
          </div>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24">Ativar destaque?</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <div style="display: flex; flex-direction: row; align-items: center; gap: 1rem">
            <nz-switch formControlName="isActive"></nz-switch>
            <p nz-typography nzType="secondary" class="m-0">
              Destaques ativos serão exibidos aos usuários. Desative-o caso queira que este destaque não seja mais exibido.
            </p>
          </div>
        </nz-form-control>
      </nz-form-item>
    </main>

    <aside nz-col nzSpan="12" class="p-5">
      <h4 nz-typography>Pré-visualização</h4>

      <app-highlight-card
        class="w-full"
        [highlight]="
          $any({
            title: title.value || 'Título do destaque',
            description: description.value || 'Digite as informações do destaque aqui...',
            picture: picture.value,
            presentationMode: presentationMode.value
          })
        "></app-highlight-card>
    </aside>
  </div>
</form>
