import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {File} from '../model/file';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {EcondosQuery} from "@api/model/query";
import * as qs from 'qs';

@Injectable({providedIn: 'root'})
export class FileService {

  private endPoint = `${environment.backendUrl}files/`;

  constructor(private http: HttpClient) {
  }

  getAllFiles(query?: EcondosQuery): Observable<File[]> {
    const httpParams = new HttpParams({fromString: qs.stringify(query)});
    const options = {
      headers: new HttpHeaders(),
      params: httpParams,
      observe: 'response' as 'body'
    };
    return this.http.get<File[]>(this.endPoint, options);
  }

  createFile(file: any) {
    return this.http.post(this.endPoint, file);
  }

  getFilesSignature(queryString?: string) {
    return this.http.get(this.endPoint + '/signature' + (queryString || ''));
  }

  deleteFile(fileId: string) {
    return this.http.delete(this.endPoint + fileId);
  }

  getFileById(fileId: string, queryString?: string): Observable<File> {
    return this.http.get(`${this.endPoint}${fileId}${(queryString || '')}`) as any;
  }

  updateFile(fileId: string, file: any) {
    return this.http.put(this.endPoint + fileId, file);
  }

  uploadFilesFromFormData(formData: FormData) {
    return this.http.post<File[]>(`${this.endPoint}upload`, formData);
  }

  uploadFiles(files: any) {
    const formData = new FormData();
    for (const file of files) {
      formData.append(file.name || 'file' + new Date().getTime(), file);
    }
    return this.uploadFilesFromFormData(formData);
  }

  setFileTags(fileId: string, tags: string[]) {
    return this.http.put(`${this.endPoint}${fileId}`, {tags});
  }

  public dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString});
  }

}
