import {Component} from '@angular/core';

@Component({
  templateUrl: 'not-found-page.component.html'
})
export class NotFoundPageComponent {

  constructor() {
  }


}
