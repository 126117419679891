import {Injectable} from "@angular/core";
import {UseTermsService} from "@api/service/use-terms.service";
import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {Observable} from "rxjs";
import {UseTerms} from "@api/model/use-terms";

@Injectable({providedIn: 'root'})
export class UseTermsResolver implements Resolve<any> {
  constructor(private _router: Router,
              private useTermsService: UseTermsService) {
  }
  resolve(route: ActivatedRouteSnapshot): Observable<UseTerms> {
    const _id = route.params.whiteLabelId;
    return this.useTermsService.getByWhiteLabel(_id);
  }

}
