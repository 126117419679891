import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class AddressService {
  constructor(
      private http: HttpClient
    ) {}

  getAddress(zipcode: string){
    return this.http.get(`http://viacep.com.br/ws/${zipcode}/json/`)


  }
}
