import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {User} from '../model/user';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {EcondosQuery} from '@api/model/query';
import * as qs from 'qs';
import {CondoUser} from "@api/model/condo-user";

@Injectable({providedIn: 'root'})
export class UserService {

  private endPoint = `${environment.backendUrl}users/`;

  constructor(private http: HttpClient) {
  }

  getUserQS(): string {
    const qs: Array<string> = [];
    qs[qs.length] = '$populate[0][path]=defaultCondo';
    qs[qs.length] = '$populate[0][populate][path]=pictures';
    qs[qs.length] = '$populate[1]=defaultResidence';
    qs[qs.length] = '$populate[2][path]=residencesUser';
    qs[qs.length] = '$populate[2][select]=identification condo block identification number requesters users voter';
    qs[qs.length] = '$populate[3]=residencesVoter';
    qs[qs.length] = '$populate[4]=picture';
    qs[qs.length] = '$populate[5][path]=condosAdmin';
    qs[qs.length] = '$populate[5][populate][path]=pictures';
    qs[qs.length] = '$populate[6][path]=condosGatekeeper';
    qs[qs.length] = '$populate[6][populate][path]=pictures';
    qs[qs.length] = '$populate[7][path]=condosOwner';
    qs[qs.length] = '$populate[7][populate][path]=pictures';
    qs[qs.length] = '$populate[8][path]=condosJanitor';
    qs[qs.length] = '$populate[8][populate][path]=pictures';
    qs[qs.length] = '$populate[9][path]=condos';
    qs[qs.length] = '$populate[9][populate][path]=pictures';
    qs[qs.length] = '$populate[10][path]=condosRequester';
    qs[qs.length] = '$populate[10][populate][path]=pictures';
    qs[qs.length] = '$populate[11][path]=residencesRequester';
    qs[qs.length] = '$populate[11][select]=condo';

    return qs.join('&');
  }

  getMe(query: EcondosQuery): Observable<User> {
    const httpParams = new HttpParams({fromString: qs.stringify(query)});
    const options = {
      headers: new HttpHeaders(),
      params: httpParams
    };
    return this.http.get<User>(`${this.endPoint}me`, options);
  }

  getAllUsers(query?: EcondosQuery): Observable<CondoUser[]> {
    const params = new HttpParams({fromString: qs.stringify(query)});
    return this.http.get<CondoUser[]>(this.endPoint, {params});
  }

  getUser(userId?: string, query?: EcondosQuery): Observable<CondoUser> {
    const params = new HttpParams({fromString: qs.stringify(query)});
    return this.http.get<CondoUser>(this.endPoint + userId, {params})
  }

  createUser(user: any) {
    return this.http.post(this.endPoint, user);
  }

  deleteUser(userId: string) {
    return this.http.delete(this.endPoint + userId);
  }

  updateUser(userId: string, user: any) {
    return this.http.put(this.endPoint + userId, user);
  }

  changeUserDefaultCondo(userId: string, condoId: string) {
    return this.http.put(this.endPoint + userId + '/defaultCondo/' + condoId, {});
  }

  activateUser(userId: string) {
    return this.http.put(this.endPoint + userId + '/activate/', {});
  }

  resetUserPassword(userId: string, queryString = '') {
    return this.http.get(`${this.endPoint}${userId}/reset/${queryString}`);
  }

  resetUserWhiteLabelPassword(userId: string, whiteLabelId: string) {
    return this.http.get(`${this.endPoint}${userId}/reset`, {headers: {'x-white-label': whiteLabelId}});
  }

  changeUserPassword(newPassword: string, activationKey: string) {
    return this.http.put(`${this.endPoint}activate?activationKey=${activationKey}`, {password: newPassword});
  }

  resetPassword(userId: string): Observable<{ password: number }> {
    return this.http.get(`${this.endPoint}${userId}/resetPassword`) as Observable<{ password: number }>
  }

  changePassword(data: { oldPassword: string, newPassword: string, confirmPassword: string }) {
    return this.http.put(`${this.endPoint}me/password`, data);
  }

  activateUserWithToken(activationKey: string) {
    return this.http.put(`${this.endPoint}activate?activationKey=${activationKey}`, {});
  }

  sendActivationEmail(userId: string) {
    return this.http.get(this.endPoint + userId + '/reactivate/');
  }

  logout() {
    return this.http.get(`${environment.backendUrl}users/logout/`);
  }

  login(queryString = this.getUserQS()): Observable<User> {
    queryString = '?' + queryString;
    return this.http.get<User>(`${environment.backendUrl}auth/basic${queryString}`);
  }

  logoff() {
    return this.http.get(`${this.endPoint}logoff`);
  }

}
