<nz-page-header class="site-page-header" nzTitle="Dashboard"></nz-page-header>
<div class="dashboard">
  <nz-card class="m-2" *ngFor="let item of items" style="cursor: pointer" [routerLink]="['/home/' + item.route]">
    <h2>{{item.title}}</h2>
    <div style="text-align: center">
      <i style="font-size: 3rem" nz-icon [nzType]="item.icon" nzTheme="outline"></i>
    </div>
  </nz-card>
</div>

<div nz-row>
  <div nz-col nzSpan="24">
    <nz-card class="m-2" nzTitle="Estatísticas" *ngIf="{
    users: (data$ | async).users,
    condos: (data$ | async).condos
    } as data">
      <nz-row>
        <nz-col [nzSpan]="12">
          <nz-statistic [nzValue]="(data.condos | number)!" nzTitle="Condomínios pagantes"></nz-statistic>
        </nz-col>
        <nz-col [nzSpan]="12">
          <nz-statistic [nzValue]="(data.users | number)!" nzTitle="Moradores"></nz-statistic>
        </nz-col>
      </nz-row>

    </nz-card>
  </div>
</div>
