<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="../..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a routerLink="..">
        <i nz-icon nzType="shop"></i>
        Whitelabels
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <img [src]="whiteLabel?.favIcon" style="width: 20px">
      {{ whiteLabel?.companyName }}
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <nz-page-header-title>{{ whiteLabel?.companyName }}</nz-page-header-title>
  <nz-page-header-subtitle style="width: 220px">
    <nz-input-group nzSearch>
      <input [formControl]="searchByName" type="text" nz-input placeholder="Pesquise por um condominio"/>
    </nz-input-group>
  </nz-page-header-subtitle>
</nz-page-header>

<div class="px-4">
  <div>
    <label>Link: </label>
    <a [href]="whiteLabel.webAppUrl" target="_blank">{{ whiteLabel.webAppUrl }}</a>

    <nz-divider nzType="vertical"></nz-divider>

    <label>Id: </label>
    <u>{{whiteLabel._id}}</u>
  </div>

  <div nz-row>
    <div nz-col><h2>Condomínios</h2></div>
    <div nz-col style="margin-left: auto; margin-right: 30px">
      <ng-container>
        <nz-tag
          *ngIf="this.totalResidences > 0"
          style="font-size: 16px; padding: 5px; margin-right: 5px"
          >Total de unidades: {{ this.totalResidences }}</nz-tag
        >
        <button style="margin: 0 5px" nz-button [nzType]="'primary'" routerLink="edit"><span>Editar whitelabel</span></button>
        <button nz-button nzType="primary" routerLink="use-terms"><span>Termos de Uso</span></button>
        <button
          *ngIf="!this.totalResidences && this.condos.length"
          style="margin: 0 5px"
          (click)="getTotalResidences()"
          nz-button
          nzType="primary"
        >
          Ver total de unidades
        </button>
      </ng-container>
      <nz-tag style="font-size: 16px; padding: 5px"
        >Condomínios: {{ this.totalCondos }}</nz-tag
      >
    </div>
  </div>

  <nz-table #nzCondosTable [nzData]="condos" [nzFrontPagination]="false">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Localização</th>
        <th>Unidades</th>
        <th>Integrações</th>
        <th>Criado em</th>
        <th>CNPJ</th>
        <th>Plano</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let condo of nzCondosTable.data">
        <td>
          {{ condo.name }}
        </td>
        <td>
          {{ condo.address }}, {{ condo.city }} - {{ condo.state }}
        </td>
        <td>
          <button *ngIf="!condo.residences && condo.residences !== 0"  (click)=getData(condo._id) nz-button nzType="primary">Ver unidades</button>
          <ng-container *ngIf="condo.residences >= 0">
            {{condo.residences}} unidade(s)
          </ng-container>
        </td>
        <td>
          <div *ngIf="condo.controlId?.enabled">
            ControlID
          </div>
          <div *ngIf="condo.intelbras?.enabled">
            Intelbras
          </div>
          <div *ngIf="condo.hikvision?.enabled">
            Hikvision
          </div>
          <div *ngIf="condo.linear?.enabled">
            Linear
          </div>
          <div *ngIf="condo.utech?.enabled">
            uTech
          </div>
        </td>
        <td>
          {{ condo.createdAt | date: 'dd/MM/yy' }}
        </td>
        <td>
          {{ condo.cnpj }}
        </td>
        <td>
          {{ condo.plan.name }}
        </td>
      </tr>
    </tbody>
  </nz-table>
  <nz-pagination nz-row nzJustify="end" nzShowSizeChanger [nzTotal]="totalCondos" [nzPageIndex]="0" (nzPageIndexChange)="refreshIndexChange($event)" (nzPageSizeChange)="refreshSizeChange($event)">
  </nz-pagination>

</div>
