import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Ad} from '../model/advertiser';
import {EcondosQuery} from "@api/model/query";
import {environment} from '@environment';

import * as qs from 'qs';

@Injectable({providedIn: 'root'})
export class AdvertiserService {
  //endpoint do backend usado para as funções
  private endPoint = `${environment.backendUrlv1}`;
  private model = 'advertises/';
  private fullEndPoint = `${this.endPoint}${this.model}`;

  constructor(private http: HttpClient) {}

  //retorna uma lista dos parceiros
  getAdvertises(params?: EcondosQuery): Observable<{ count: number, advertises: Ad[] }> {
    const httpParams = new HttpParams({fromString: qs.stringify(params)});
    return this.http.get(`${this.endPoint}`, {
      params: httpParams,
      observe: 'response' as 'body'
    }).pipe(map((res: any) => ({
        count: res.headers.get('count'),
        advertises: res.body
      })
      )
    )
  }

  //retorna um parceiro específico com o id fornecido
  getAdvertiserById(advertiserId: string, params?: EcondosQuery): Observable<Ad> {
    const httpParams = new HttpParams({fromString: qs.stringify(params)});
    return this.http.get(this.endPoint + advertiserId,{
      params: httpParams,
      observe: 'response' as 'body'
    }).pipe(map((res: any) => (
      res.body
    )
    ))
  }

  //cria um novo parceiro com a informação enviada e retorna a informação dele
  createAdvertiser({advertiser, condoId}: {advertiser: Ad, condoId: string}){
      return this.http.post(`${this.endPoint}condos/${condoId}/${this.model}`, advertiser)
  }

  //atualiza a informação do parceiro de Id fornecido com a informação fornecida, e retorna o usuário atualizado
  updateAdvertiser({advertiserId, advertiser, condoId}: {advertiserId: string, advertiser: Ad, condoId: string}){
    return this
    .http
    .put(`${this.endPoint}condos/${condoId}/${this.model}${advertiserId}` , advertiser)
  }

  //exclui o usuário com id fornecido retornando nada.
  deleteAdvertiser({advertiserId, condoId}: {advertiserId: string, condoId: string}){
    return this
      .http
      .delete(`${this.endPoint}condos/${condoId}/${this.model}${advertiserId}`)
  }
}
