<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="../..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>

    <nz-breadcrumb-item>
      <a routerLink="..">
        <i nz-icon nzType="appstore"></i>
        Funcionalidades
      </a>
    </nz-breadcrumb-item>

    <nz-breadcrumb-item>
      <i nz-icon nzType="star"></i>
      Destaques e informativos
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <nz-page-header-title>
    <div class="title">
      Destaques e informativos
      <nz-tag nzColor="blue">Beta</nz-tag>
    </div>

    <p nz-typography nzType="secondary" class="subtitle">
      Os destaques criados aqui aparecerão no carrossel da tela inicial da aplicação móvel
    </p>
  </nz-page-header-title>

  <nz-page-header-extra>
    <button nz-button nzType="default" (click)="getData()" [disabled]="status === 'LOADING'">
      <i nz-icon nzType="reload" [nzSpin]="status === 'LOADING'"></i>
    </button>

    <button nz-button nzType="primary" routerLink="/home/features/highlights/create">
      <i nz-icon nzType="plus"></i>
      Criar Destaque
    </button>
  </nz-page-header-extra>
</nz-page-header>

<div class="px-4">
  <nz-card class="p-4" *ngIf="status === 'LOADING'">
    <nz-spin nzTip="Carregando destaques..."></nz-spin>
  </nz-card>

  <nz-card class="p-4" *ngIf="status === 'ERROR'">
    <div style="text-align: center">
      <h2 nz-typography nzType="danger">Erro ao carregar destaques</h2>
      <h4 nz-typography>Ocorreu um erro ao carregar os destaques. Tente novamente, por favor.</h4>
      <button nz-button nzType="primary" (click)="getData()">Tentar novamente</button>
    </div>
  </nz-card>

  <nz-table #highlightsTable [nzData]="highlights" [nzFrontPagination]="false" *ngIf="status === 'SUCCESS'">
    <thead>
      <tr>
        <th>Título</th>
        <th>Posição</th>
        <th>Modo de apresentação</th>
        <th>Período de exibição</th>
        <th>Cliques</th>
        <th>Ativo?</th>
        <th>Ações</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let data of highlightsTable.nzData">
        <td>{{ data.title }}</td>
        <td>{{ data.position }}</td>
        <td>
          <nz-tag [nzColor]="presentationModeColor[data.presentationMode]">
            {{ HIGHLIGHT_PRESENTATION_MODE_INFO[data.presentationMode].label }}
          </nz-tag>
        </td>
        <td>
          a partir de {{ data.startDate | date : 'dd/MM/yyyy' }}
          <ng-container *ngIf="data.endDate">
            até {{ data.endDate | date : 'dd/MM/yyyy' }}
          </ng-container>
        </td>
        <td>{{ data.clicksCount || 'Nenhum clique ainda' }}</td>
        <td>
          <nz-tag [nzColor]="data.isActive ? 'green' : 'red'">
            {{ data.isActive ? 'Sim' : 'Não' }}
          </nz-tag>
        </td>
        <td>
          <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="false">
            Ações
            <i nz-icon nzType="down"></i>
          </button>

          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="handlePreviewHighlight(data)">
                <span>
                  <i nz-icon nzType="eye"></i>
                  Pré-visualizar
                </span>
              </li>

              <li nz-menu-item (click)="handleToggleHighlight(data)">
                <span>
                  <i nz-icon nzType="poweroff"></i>
                  {{ data.isActive ? 'Desativar' : 'Ativar' }}
                </span>
              </li>

              <li nz-menu-item>
                <a [routerLink]="[data._id, 'edit']">
                  <i nz-icon nzType="edit"></i>
                  Editar
                </a>
              </li>

              <li nz-menu-item nz-popconfirm nzPopconfirmTitle="Deseja realmente excluir este destaque?" (nzOnConfirm)="handleDeleteHighlight(data)">
                <span nz-typography nzType="danger">
                  <i nz-icon nzType="delete"></i>
                  Excluir
                </span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <nz-pagination
    nz-row
    nzJustify="end"
    nzShowSizeChanger
    [nzTotal]="totalData"
    [nzPageIndex]="0"
    [nzPageSize]="nzPageSize"
    [nzPageSizeOptions]="[50, 100, 250, 500]"
    (nzPageIndexChange)="refreshIndexChange($event)"
    (nzPageSizeChange)="refreshSizeChange($event)"></nz-pagination>
</div>
