
export enum STATUS {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED'
  }
  
  export enum STATES {
    NEW = 'NEW',
    USED = 'USED'
  }
  
  export enum STATES_LABEL {
    NEW = 'Novo',
    USED = 'Usado'
  }
  
  export enum CATEGORIES {
    FOOD = 'FOOD',
    PET = 'PET',
    CHILD = 'CHILD',
    ELECTRONIC = 'ELECTRONIC',
    JOB_SERVICE = 'JOB_SERVICE',
    SPORTS = 'SPORTS',
    REALTY = 'REALTY',
    FASHION_BEAUTY = 'FASHION_BEAUTY',
    FURNITURE = 'FURNITURE',
    VEHICLE = 'VEHICLE',
    OTHER = 'OTHER'
  }
  
  export enum CATEGORIES_LABEL {
    FOOD = 'Alimentos',
    PET = 'Animais e acessórios',
    CHILD = 'Bebês e crianças',
    ELECTRONIC = 'Eletrônicos',
    JOB_SERVICE = 'Empregos e serviços',
    SPORTS = 'Esportes',
    REALTY = 'Imóveis',
    FASHION_BEAUTY = 'Moda e beleza',
    FURNITURE = 'Móveis e eletrodomésticos',
    VEHICLE = 'Veículos',
    OTHER = 'Outros'
  }

  export interface Ad {
    _id?: string | null;
    pictures?: Array<File>;
    title?: string;
    description?: string;
    type?: string;
    status?: STATUS.OPEN;
    // residence?: Residence;
    price?: number;
    phone?: string;
    category?: CATEGORIES;
    state?: STATES;
    city?: string;
    uf?: string;
    region?: string;
    public?: boolean;
    condo?: string; 
    // createdBy?: User;
    // updatedBy?: User;
    createdAt?: string;
    updatedAt?: string;
    marketplace?: string; 
  }

  export const BuildAd = (ad: Partial<Ad>): Ad => {
    return {
      _id: ad._id || null,
      pictures: ad.pictures || [],
      title: ad.title || '',
      description: ad.description || '',
      type: ad.type || '',
      status: ad.status || STATUS.OPEN,
      // residence: ad.residence || null,
      price: ad.price || undefined,
      phone: ad.phone || '',
      category: ad.category || CATEGORIES.FOOD,
      state: ad.state || STATES.NEW,
      city: ad.city || '',
      uf: ad.uf || '',
      region: ad.region || '',
      public: ad.public || false,
      condo: ad.condo || '',
      // createdBy: ad.createdBy || null,
      // updatedBy: ad.updatedBy || null,
      // createdAt: ad.createdAt || '',
      // updatedAt: ad.updatedAt || '',,
      marketplace: ad.marketplace || '',
    };
  }