import { AbstractControl, ValidatorFn } from '@angular/forms';

// Source: https://stackoverflow.com/a/62742520
export const urlValidator: ValidatorFn = (control: AbstractControl) => {
  let validUrl = true;

  try {
    new URL(control.value);
  } catch {
    validUrl = false;
  }

  return validUrl ? null : { invalidUrl: true };
};
