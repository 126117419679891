import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {User} from '@api/model/user';
import {SessionService} from "../service/session.service";

@Injectable({providedIn: 'root'})
export class LoggedGuard implements CanActivate {

  constructor(private sessionService: SessionService,
              private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    const user: User = this.sessionService.loadSessionFromLocalData();
    if (user && user.token && user.isSystemAdmin) {
      return true;
    } else {
      this.router.navigate(['/login']);
      this.sessionService.clearSession();
      return false;
    }

  }

}
