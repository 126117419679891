import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NZ_I18N, pt_BR} from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import pt from '@angular/common/locales/pt';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginPageComponent} from './login-page/login-page.component';
import {NgZorroAntdModule} from './ng-zorro-antd.module';
import {DashboardPageComponent} from './home/children/dashboard-page/dashboard-page.component';
import {CondosPageComponent} from './home/children/condos-page/condos-page.component';
import {WhiteLabelsPageComponent} from './home/children/whitelabels-page/white-labels-page.component';
import {
  WhiteLabelDetailsPageComponent
} from './home/children/white-label-details-page/white-label-details-page.component';
import {JwtModule} from '@auth0/angular-jwt';
import {UsersPageComponent} from './home/children/users-page/users-page.component';
import {NotFoundPageComponent} from './not-found-page/not-found-page.component';
import {UserDetailsPageComponent} from './home/children/user-details-page/user-details-page.component';
import {ModalEditMarketplaceComponent} from './components/modal-edit-marketplace/modal-edit-marketplace.component';
import {ModalEditEmailComponent} from './components/modal-edit-email/modal-edit-email.component';
import {
  ModalResetUserPasswordComponent
} from './components/modal-reset-user-password/modal-reset-user-password.component';
import {CondosDetailsPageComponent} from './home/children/condos-details-page/condos-details-page.component';
import {
  ModalCreateFinancialResponsible
} from './components/modal-create-financial-responsible/modal-create-financial-responsible.component';
import {CreateWhiteLabelComponent} from './home/children/create-white-label-page/create-white-label-page';
import {HomeComponent} from './home/home.component';
import {ModalWhiteLabelSelect} from './components/modal-white-label-select/modal-white-label-select';
import {PartnersPageComponent} from './home/children/partners/partners-page/partners-page.component';
import {PartnersComponent} from './home/children/partners/partners-details/partners-details.component';
import {MarketplacePageComponent} from './home/children/marketplace/marketplace-page/marketplace-page.component';
import {ContractsPageComponent} from './home/children/contracts-page/contracts-page.component';
import {MomentModule} from 'ngx-moment';
import 'moment/locale/pt-br';
import {ClipboardModule} from 'ngx-clipboard';
import {ModalNotifyCondo} from './components/modal-notify-condo/modal-notify-condo';
import {SearchFilterComponent} from './components/search-filter/search-filter.component';
import {SearchDropdownComponent} from './components/search-dropdown/search-dropdown.component';
import {SearchWhitelabelComponent} from './components/search-whitelabel/search-whitelabel.component';
import {CondoLogsPageComponent} from './home/children/logs-page/condo-logs-page/condo-logs-page.component';
import {UseTermsPageComponent} from './home/children/use-terms-page/use-terms-page.component';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {EditorModule} from '@tinymce/tinymce-angular';
import {NzDescriptionsModule} from 'ng-zorro-antd/descriptions';
import {NzAnchorModule} from 'ng-zorro-antd/anchor';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {CookieInterceptor} from './interceptors/cookie.interceptor';
import { FeaturesPageComponent } from './home/children/features-page/features-page.component';
import { HighlightsPageComponent } from './home/children/features-page/children/highlights-page/highlights-page.component';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import {
  CreateHighlightPageComponent
} from './home/children/features-page/children/create-highlight-page/create-highlight-page.component';
import {HighlightCardComponent} from './home/children/features-page/components/highlight-card/highlight-card.component';
import {NzInputNumberModule} from 'ng-zorro-antd/input-number';
import {
  ModalPreviewHighlightComponent
} from './home/children/features-page/components/modal-preview-highlight/modal-preview-highlight.component';
import {
  CreateCondoFinancialInfoPageComponent
} from './home/children/create-condo-financial-info-page/create-condo-financial-info-page';
import {
  CondoFinancialInfoPageComponent
} from './home/children/condo-financial-info-page/condo-financial-info-page.component';
import {SearchCondoComponent} from './components/search-condo/search-condo.component';
import {ModalReadjustPriceComponent} from './components/modal-readjust-price/modal-readjust-price.component';
import {NoPermissionErrorPageComponent} from './no-permission-error-page/no-permission-error-page.component';
import {HardwareLogsPageComponent} from './home/children/logs-page/hardware-logs-page/hardware-logs-page.component';
import {ObjectTreeViewComponent} from './components/object-tree-view/object-tree-view.component';

registerLocaleData(pt);

export function tokenGetter() {
  try {
    const userString = localStorage.getItem('user') || '';
    const user = JSON.parse(userString) || {};
    return user.token || '';
  } catch (e) {
    return '';
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomeComponent,
    DashboardPageComponent,
    CondosPageComponent,
    CondosDetailsPageComponent,
    WhiteLabelsPageComponent,
    WhiteLabelDetailsPageComponent,
    UsersPageComponent,
    NotFoundPageComponent,
    UserDetailsPageComponent,
    ModalEditEmailComponent,
    ModalEditMarketplaceComponent,
    ModalResetUserPasswordComponent,
    ModalCreateFinancialResponsible,
    PartnersPageComponent,
    PartnersComponent,
    MarketplacePageComponent,
    CreateWhiteLabelComponent,
    ModalCreateFinancialResponsible,
    ModalWhiteLabelSelect,
    ModalNotifyCondo,
    ContractsPageComponent,
    SearchFilterComponent,
    SearchDropdownComponent,
    SearchWhitelabelComponent,
    CondoLogsPageComponent,
    UseTermsPageComponent,
    FeaturesPageComponent,
    HighlightsPageComponent,
    CreateHighlightPageComponent,
    HighlightCardComponent,
    ModalPreviewHighlightComponent,
    CondoFinancialInfoPageComponent,
    CreateCondoFinancialInfoPageComponent,
    SearchCondoComponent,
    ModalReadjustPriceComponent,
    NoPermissionErrorPageComponent,
    HardwareLogsPageComponent,
    ObjectTreeViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    MomentModule,
    ClipboardModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [
          'localhost:8080',
          '127.0.0.1:8080',
          'api.econdos.com.br',
          'ws.econdos.com.br',
          'api-staging.econdos.com.br',
          'ws-staging.econdos.com.br'
        ]
      }
    }),
    NzTabsModule,
    EditorModule,
    NzDescriptionsModule,
    NzAnchorModule,
    NzUploadModule,
    NzSpinModule,
    NzTypographyModule,
    NzPopconfirmModule,
    NzInputNumberModule
  ],
  providers: [
    {provide: NZ_I18N, useValue: pt_BR},
    {provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
