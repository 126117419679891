import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {debounceTime, filter, switchMap} from "rxjs/operators";
import {EcondosQuery} from "@api/model/query";
import {NzOptionSelectionChange} from "ng-zorro-antd/auto-complete";
import {Condo} from '@api/model/condo';
import {CondoService} from '@api/service/condo.service';

@Component({
  selector: 'app-search-condo',
  templateUrl: './search-condo.component.html',
  styleUrls: ['./search-condo.component.scss']
})
export class SearchCondoComponent {
  @Output() filter = new EventEmitter<string>();
  @Output() close = new EventEmitter<any>();

  inputValue: string = '';

  options$: Observable<Condo[]>;
  inputSubject = new Subject<string>();
  filtering: boolean = false;
  itemSelected: boolean = false;

  constructor(private condoService: CondoService) {
    this.options$ = this.inputSubject.pipe(
      debounceTime(1000),
      filter((qs) => qs.length >= 3),
      switchMap((queryString) => {
        const query: EcondosQuery = {
          $select:
            'name pictures',
          $populate: [{path: 'pictures', select: 'url'}],
          $limit: 50,
          name: { $regex: queryString, $options: 'i' },
        };
        return this.condoService.get(query);
      })
    );
  }

  onChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.inputSubject.next(value);
  }

  handleSearch() {
    this.filtering = true;
    this.filter.emit(this.inputValue);
  }

  handleOnClose() {
    this.filtering = false;
    this.inputSubject.next('');
    this.inputValue = '';
    this.close.emit();
  }

  handleSelectionChange(event: NzOptionSelectionChange) {
    const { isUserInput, source } = event;
    if (isUserInput) {
      this.itemSelected = true;
      this.inputValue = source.nzValue;
    }
  }
}
