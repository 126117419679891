import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { WhiteLabelService } from '@api/service/white-label.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, filter, switchMap, timeout } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CondoFinancialInfo } from '@api/model/condo-financial-info';
import { WhiteLabel } from '@api/model/white-label';
import { Condo, PLANS_LABELS, TYPES_LABELS } from '@api/model/condo';
import { CondoService } from '@api/service/condo.service';
import { EcondosQuery } from '@api/model/query';
import {
  currencyFormatter,
  formatPercent,
  parseCurrency,
  parsePercent,
} from '@api/utils';
import { ResidenceService } from '@api/service/residences.service';
import { CondoFinancialInfoService } from '@api/service/condo-financial-info.service';
import { toZonedTime } from 'date-fns-tz';
import { addDays, format } from 'date-fns';
import { beforeTodayValidator } from '../../../validators/before-today.validator';

@Component({
  selector: 'app-create-condo-financial-info-page',
  templateUrl: './create-condo-financial-info-page.html',
  styleUrls: ['./create-condo-financial-info-page.scss'],
})
export class CreateCondoFinancialInfoPageComponent
  implements OnInit, OnDestroy
{
  @Input() public condoFinancialInfo!: CondoFinancialInfo;

  public financialForm: FormGroup = this.formBuilder.group({
    whiteLabel: new FormControl(''),
    condo: new FormControl('', Validators.required),
    planName: new FormControl(''),
    type: new FormControl(''),
    daysOfTrial: new FormControl(0, Validators.min(0)),
    residencesCount: new FormControl(0, Validators.min(0)),
    price: new FormControl('', Validators.min(0)),
    lastAdjustmentDate: new FormControl(''),
    lastAdjustmentPercentage: new FormControl(0, Validators.min(0)),
    nextAdjustmentDate: new FormControl('', beforeTodayValidator()),
    nextAdjustmentPercentage: new FormControl(0, Validators.min(0)),
    adjustmentIndex: new FormControl(''),
    obs: new FormControl('')
  });

  whiteLabelOptions: WhiteLabel[] = [];
  whiteLabelInputSubject = new Subject<string>();

  condoOptions: Condo[] = [];
  condoInputSubject = new Subject<string>();
  //Plano trial só existe no getter e não é salvo
  PLANS_LABELS = Object.keys(PLANS_LABELS).map((key) => ({
    label: PLANS_LABELS[key as keyof typeof PLANS_LABELS],
    value: key
  })).filter(plan => plan.value !== 'TRIAL');

  TYPES_LABELS = Object.keys(TYPES_LABELS).map((key) => ({
    label: TYPES_LABELS[key as keyof typeof TYPES_LABELS],
    value: key
  }));

  public status: 'LOADING' | 'SUCCESS' | 'ERROR' | null = null;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  selectedWhiteLabel: WhiteLabel | null = null;
  selectedCondo: Condo | null = null;
  createdAt: string = '';
  endDateOfTrial = '';

  readonly currencyFormatter = currencyFormatter;
  readonly parseCurrency = parseCurrency;
  readonly formatPercent = formatPercent;
  readonly parsePercent = parsePercent;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly whitelabelService: WhiteLabelService,
    private readonly condoService: CondoService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NzNotificationService,
    private readonly residenceService: ResidenceService,
    private readonly condoFinancialService: CondoFinancialInfoService,
  ) {
    this.whiteLabelInputSubject
      .pipe(
        debounceTime(1000),
        filter((qs) => qs.length >= 3),
        switchMap((queryString) => {
          const query: EcondosQuery = {
            $select: 'name icon companyName',
            $limit: 50,
            name: { $regex: queryString, $options: 'i' },
          };
          return this.whitelabelService.get(query);
        })
      )
      .subscribe((whiteLabels) => {
        this.whiteLabelOptions = whiteLabels;
      });

    this.condoInputSubject
      .pipe(
        debounceTime(1000),
        filter((qs) => qs.length >= 3),
        switchMap((queryString) => {
          const query: EcondosQuery = {
            $select: 'name pictures plan daysOfTrial createdAt type',
            $populate: [{ path: 'pictures', select: 'url' }],
            $limit: 50,
            name: { $regex: queryString, $options: 'i' },
          };
          if (this.financialForm.get('whiteLabel')?.value) {
            query.whiteLabel = this.financialForm.get('whiteLabel')?.value;
          }
          return this.condoService.get(query);
        })
      )
      .subscribe((condos) => {
        this.condoOptions = condos;
      });

    this.financialForm
      .get('daysOfTrial')!
      .valueChanges.pipe()
      .subscribe((value) => {
        if(this.selectedCondo){
          this.endDateOfTrial = format(
            addDays(
              toZonedTime(
                this.selectedCondo.createdAt,
                this.selectedCondo.timeZone,
              ),
              value,
            ),
            'dd/MM/yyyy',
          );
        }
      });

    this.financialForm.get('whiteLabel')!.valueChanges.subscribe((value) => {
      if(!this.condoFinancialInfo){
        this.onWhiteLabelChange(value);
      }
    });

    this.financialForm.get('condo')!.valueChanges.subscribe((value) => {
      this.onCondoChange(value);
    });
  }

  ngOnInit(): void {
    this.condoFinancialInfo = this.route.snapshot.data?.condoFinancialInfo;
    if (this.condoFinancialInfo) {
      if(this.condoFinancialInfo.condo.plan.name === 'TRIAL') {
        this.condoFinancialInfo.condo.plan.name = 'FREE';
      }
      this.financialForm.patchValue(this.condoFinancialInfo, {emitEvent: false});
      this.selectedCondo = this.condoFinancialInfo.condo;
      this.populateCondoFields();
      this.selectedWhiteLabel = this.condoFinancialInfo?.whiteLabel;
      if (this.selectedWhiteLabel._id) {
        this.whiteLabelOptions = [this.selectedWhiteLabel];
        this.financialForm
          .get('whiteLabel')
          ?.setValue(this.selectedWhiteLabel._id);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onSearchWhiteLabel(value: string): void {
      this.whiteLabelInputSubject.next(value);
  }

  onWhiteLabelChange(event: any): void {
    if (event) {
      const whiteLabel = this.whiteLabelOptions.find(
        (option) => option._id === event,
      );

      if (whiteLabel) {
        this.selectedWhiteLabel = whiteLabel;
      }
      const query: EcondosQuery = {
        $select: 'name pictures plan daysOfTrial createdAt type',
        $populate: [{ path: 'pictures', select: 'url' }],
        $limit: 50,
      };
      if (this.financialForm.get('whiteLabel')?.value) {
        query.whiteLabel = this.financialForm.get('whiteLabel')?.value;
      }
      this.condoService.get(query).subscribe((condos) => {
        this.condoOptions = condos;
      });

    } else {
      this.selectedWhiteLabel = null;
    }

    this.financialForm.get('condo')?.setValue('');
  }

  onSearchCondo(value: string): void {
    this.condoInputSubject.next(value);
  }

  onCondoChange(event: any): void {
    if (event) {
      const condo = this.condoOptions.find(
        (option) => option._id === event,
      );

      if (condo) {
        if(condo.plan.name === 'TRIAL') {
          condo.plan.name = 'FREE';
        }
        this.selectedCondo = condo;
      }

      this.populateCondoFields();
      this.residenceService
        .getResidencesCount(event)
        .subscribe((residencesCount) => {
          this.financialForm.get('residencesCount')!.setValue(residencesCount);
        });
    } else {
      this.selectedCondo = null;
    }
  }

  populateCondoFields() {
    if(this.selectedCondo){
    this.createdAt = format(
      toZonedTime(this.selectedCondo.createdAt, this.selectedCondo.timeZone),
      'dd/MM/yyyy',
    );
    this.financialForm
      .get('daysOfTrial')!
      .setValue(this.selectedCondo.daysOfTrial);
    this.endDateOfTrial = format(
      addDays(
        toZonedTime(this.selectedCondo.createdAt, this.selectedCondo.timeZone),
        this.selectedCondo.daysOfTrial,
      ),
      'dd/MM/yyyy'
    );
    this.financialForm.get('planName')!.setValue(this.selectedCondo.plan.name);
    this.financialForm.get('type')!.setValue(this.selectedCondo.type);
    }
  }

  private markFinancialFormAsDirty(): void {
    Object.values(this.financialForm.controls).forEach((control) => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }

  public save(): void {
    this.status = 'LOADING';
    if (this.financialForm.valid) {
      const data = this.financialForm.value;
      if (this.financialForm.get('whiteLabel')?.value) {
        data.whiteLabel = this.financialForm.get('whiteLabel')?.value;
      } else {
        delete data.whiteLabel;
      }
      let subscription: Observable<any>;
      if (this.condoFinancialInfo) {
        subscription = this.condoFinancialService.update(
          this.condoFinancialInfo._id,
          data
        );
      } else {
        subscription = this.condoFinancialService.create(data);
      }
      subscription.pipe(timeout(10000)).subscribe(
        () => {
          this.status = 'SUCCESS';
          this.notificationService.create(
            'success',
            'Informações financeiras',
            'Salvas com sucesso!',
          );
          this.router.navigate(['home', 'condoFinancialInfo']);
        },
        (res) => {
          this.status = 'ERROR';
          this.notificationService.create(
            'error',
            'Erro',
            res?.error?.message || 'Não foi possível cadastrar!',
          );
        },
      );
    } else {
      this.markFinancialFormAsDirty();
      this.notificationService.create(
        'error',
        'Campos inválidos',
        'Existem campos inválidos!'
      );
      this.status = 'ERROR';
    }
  }
}
