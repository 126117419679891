<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <i nz-icon nzType="shop"></i>
      Condomínios
    </nz-breadcrumb-item>
  </nz-breadcrumb>

  <nz-page-header-title>
    Condomínios {{showDeleted ? 'deletados' : ''}}
  </nz-page-header-title>

  <nz-page-header-subtitle>
    {{ totalData || 0 }} resultados
  </nz-page-header-subtitle>
</nz-page-header>
<div class="px-4 mb-2 control-toolbar">
  <div class="mr-2 mt-2">
    <app-search-filter
      [dropdownOptions]="generalSearchOptions"
      (onFilter)="handleGeneralSearchFilter($event)"
      (onClose)="closeGeneralSearchFilter()"
    ></app-search-filter>
  </div>
  <div class="mr-2 mt-2">
    <app-search-whitelabel
    (onFilter)="handleWhitelabelFilter($event)"
    (onClose)="closeWhitelabelFilter()">

    </app-search-whitelabel>
  </div>
  <div class="mr-2 mt-2">
    <app-search-dropdown
      [dropdownOptions]="planOptions"
      (onFilter)="handleSearchPlanFilter($event)"
      (onClose)="closeSearchPlanFilter()">
    </app-search-dropdown>
  </div>

  <nz-button-group class="mt-2">
    <button (click)="countAllCondosResidences(condos)" nz-button nzType="primary">Mostrar unidades de todos</button>
    <button (click)="toggleShowDeleted()" nz-button [nzType]="showDeleted ? 'default' : 'primary'">
      Mostrar apenas {{showDeleted ? 'não' : ''}} excluídos
    </button>
  </nz-button-group>


  <nz-button-group class="mt-2">
    <button nz-button (click)="exportAsCsv(fields, condos)">
      <i nz-icon nzType="file-excel" nzTheme="outline"></i>
      Excel
    </button>
    <button nz-button (click)="exportAsPDF(fields, condos)">
      <i nz-icon nzType="file-pdf" nzTheme="outline"></i>
      PDF
    </button>
  </nz-button-group>
</div>

<div class="px-4">
  <nz-table #condosTable [nzData]="condos" [nzFrontPagination]=false>
    <thead>
      <tr>
        <th
          (click)="getSortedData('name')"
          nz-tooltip
          nzTooltipTitle="Clique para ordenar"
        >
          Nome
          <i nz-icon nzType="{{currentSortData.field ==='name' ? currentSortData.order === 'asc' ? 'up' : 'down' : 'sort-descending'}}" nzTheme="outline"></i>
        </th>
        <th>Localização</th>
        <th>Whitelabel</th>
        <th>
          CNPJ
        </th>
        <th>
          ID
        </th>
        <th
          (click)="getSortedData('createdAt')"
          nz-tooltip
          nzTooltipTitle="Clique para ordenar"
        >
          Data de Criação
          <i nz-icon nzType="{{currentSortData.field ==='createdAt' ? currentSortData.order === 'asc' ? 'up' : 'down' : 'sort-descending'}}" nzTheme="outline"></i>
        </th>
        <th>Plano</th>
        <th>Unidades</th>
        <th *ngIf="showDeleted">Excluido por</th>
        <th *ngIf="showDeleted">Data de exclusão</th>
        <th>Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let condo of condosTable.data; let i = index;">
        <td>
          {{ condo?.name}}
        </td>
        <td>
          {{condo?.address}}, {{condo?.city}} - {{condo?.state}}
        </td>
        <td>
          {{condo?.whiteLabel?.name || 'Não possui'}}
        </td>
        <td>
          {{condo?.cnpj || 'Não informado'}}
        </td>
        <td>
          {{condo?._id}}
        </td>
        <td>
          {{condo.createdAt | date:'dd/MM/yyyy'  || 'Não informada'}}
        </td>
        <td>
          {{condo?.plan?.name}}
        </td>
        <td>
          <ng-template #residences>
            {{condo.residences || 0}}
          </ng-template>
          <button *ngIf="!condo.residences && condo.residences !== 0; else residences"  (click)=countResidencesData(condo._id) nz-button nzType="primary">Mostrar</button>
        </td>
        <td *ngIf="showDeleted">{{condo.deletedBy?.firstName}} {{condo.deletedBy?.lastName}}</td>
        <td *ngIf="showDeleted">{{condo.deletedAt | date:'dd/MM/yyyy'}}</td>
        <td>
          <nz-button-group>
            <button [routerLink]="[condo?._id]" nz-button nzType="primary">Detalhes</button>
            <button *ngIf="showDeleted" nz-button nzType="primary"
              (click)="restoreCondo(condo?._id, i)">Restaurar</button>
          </nz-button-group>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <nz-pagination
    nz-row
    nzJustify="end"
    nzShowSizeChanger
    [nzTotal]="totalData"
    [nzPageIndex]="0"
    [nzPageSize]="nzPageSize"
    [nzPageSizeOptions]="[50, 100, 250, 500]"
    (nzPageIndexChange)="refreshIndexChange($event)"
    (nzPageSizeChange)="refreshSizeChange($event)"
  >
  </nz-pagination>
</div>
