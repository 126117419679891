import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CondoUser } from '@api/model/condo-user';
import { UserService } from '@api/service/user.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-modal-edit-email',
  templateUrl: './modal-edit-email.component.html',
  styleUrls: ['./modal-edit-email.component.scss'],
})
export class ModalEditEmailComponent implements OnInit {
  @Input() user!: CondoUser;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private notification: NzNotificationService
    ) {
    this.form = this.fb.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {}

  submitForm(): void {
    if (this.form.valid) {
      this.userService.updateUser(this.user?._id, { email: this.form.value.email }).subscribe(
        (res) => {
          this.notification.create('success', 'Email', 'Email Alterado');
          this.user.email = this.form.value.email;
        },
        (error) => {
          this.notification.create('error', 'Email', 'Erro ao alterar email');
        }
      );
    }
  }
}
