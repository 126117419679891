import { Component, OnInit } from '@angular/core';
import { Condo } from '@api/model/condo';
import { EcondosQuery } from '@api/model/query';
import { CondoService } from '@api/service/condo.service';

@Component({
  selector: 'app-contracts-page',
  templateUrl: './contracts-page.component.html',
  styleUrls: ['./contracts-page.component.scss'],
})
export class ContractsPageComponent implements OnInit {
  condos: Condo[] = [];

  nzPageSize: number;
  nzPageIndex: number;
  totalData: any;

  searchName: string = '';
  searchId: string = '';


  filterNameView: boolean;
  filterIdView: boolean;

  constructor(private condoService: CondoService) {
    this.filterIdView = false;
    this.filterNameView = false;
    this.nzPageSize = 10;
    this.nzPageIndex = 0;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    const query: EcondosQuery = {
      $select: 'name address sale',
      $limit: this.nzPageSize,
      $page: this.nzPageIndex,
    };
    if (this.searchName) {
      query.name = {
        $regex: this.searchName,
        $options: 'i',
      };
    }
    if (this.searchId) {
      query._id = this.searchId;
    }

    this.condoService.getCondosWithCount(query).subscribe(
      ({ data: condos, count }) => {
        this.condos = condos;
        this.totalData = Number(count);
        this.searchName = '';
        this.searchId = '';
      },
      (err) => {
        console.log(err);
      }
    );
  }
  refreshIndexChange(value: any) {
    this.nzPageIndex = --value;
    this.getData();
  }

  refreshSizeChange(value: any) {
    this.nzPageSize = value;
    this.getData();
  }

  compare(a: Condo, b: Condo) {
    return a?.name.localeCompare(b?.name);
  }

  compareDates(a: Condo, b: Condo) {
    if (b.sale?.contractStartDate && a.sale?.contractStartDate) {
      return (
        new Date(b.sale.contractStartDate).getTime() -
        new Date(a.sale.contractStartDate).getTime()
      );
    } else {
      return -1;
    }
  }
}
