import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {User} from '@api/model/user';
import {environment} from '../../environments/environment';
import {filter, tap} from 'rxjs/operators';
import {SessionService} from './session.service';
import * as qs from 'qs';
import {CondoService} from '@api/service/condo.service';
import {EcondosQuery} from '@api/model/query';
import {UserService} from '@api/service/user.service';

@Injectable({providedIn: 'root'})
export class AuthService {

  private endPoint = `${environment.backendUrl}users/me`;

  constructor(private http: HttpClient,
              private sessionService: SessionService,
              private condoService: CondoService,
              private userService: UserService) {

  }

  login(userName: string, password: string): Observable<User> {
    this.sessionService.clearSession();
    userName = userName.toLowerCase().trim();
    let headers = new HttpHeaders();
    const authHeader = 'Basic ' + btoa(userName + ':' + password);
    headers = headers.append('Authorization', authHeader);
    const params = {
      $select: 'picture firstName lastName token isSystemAdmin systemAdminAccessLevel',
      $populate: [
        {path: 'picture', select: 'url thumbnail name'}
      ]
    };
    const httpParams: HttpParams = new HttpParams({fromString: qs.stringify(params)});
    return this.http.get<User>(`${this.endPoint}`, {headers, params: httpParams}).pipe(
      filter(user => user.isSystemAdmin),
      tap(user => this.sessionService.saveSession(user))
    );
  }

  updateSession() {
    const params: EcondosQuery = {
      $select: 'picture firstName lastName token isSystemAdmin systemAdminAccessLevel',
      $populate: [
        {path: 'picture', select: 'url thumbnail name'},
        {path: 'condosOwner'},
        {path: 'condosAdmin'},
        {path: 'condosGatekeeper'}
      ]
    };
    return this.userService.getMe(params).pipe(tap(user => this.sessionService.saveSession(user)));
  }

}
