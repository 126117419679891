import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {WhiteLabelService} from "@api/service/white-label.service";
import {map} from "rxjs/operators";
import { EcondosQuery } from '@api/model/query';

@Injectable({providedIn: 'root'})
export class CreateWhiteLabelResolver implements Resolve<any> {

  constructor(private _router: Router,
              private whitelabelService: WhiteLabelService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any>{
    const _id = route.params.whiteLabelId;
    const query: EcondosQuery = {
      $select: 'enabled name companyName oneSignalAppId oneSignalRestApiKey oneSignalGoogleSenderId mailgunKey mailgunDomain nodemailerUri emailjs nodemailerConfig emailPrefix emailSignature emailFromName emailFrom phone emailSupport webAppUrl applePackageName googlePackageName textReplacements filesReplacements cssVariables icon splash logo favIcon pageTitle banner qrCodePageUrl qrCodePageMessage appCenterAndroidAppSecret codePushAndroidProductionKey appCenterIOSAppSecret codePushIOSProductionKey ignoreActivationEmail createdBy updatedBy x86Installer x64Installer customerSupportUrl',
      _id,
    };
      return this.whitelabelService.get(query).pipe(map(res => res[0]))
  }
}
