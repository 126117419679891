<nz-page-header class="site-page-header">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>
      <a routerLink="../..">
        <i nz-icon nzType="home"></i>
      </a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      Usuários
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-title>Usuários</nz-page-header-title>
  <nz-page-header-subtitle style="width: 220px;">
    <nz-input-group nzSearch >
      <input [formControl]="searchByIdAndEmail" type="text" nz-input placeholder="Pesquise por id ou email" />
    </nz-input-group>
  </nz-page-header-subtitle>
</nz-page-header>

<div class="px-4">
  <nz-table #usersTable [nzData]="users" nzShowPagination nzShowSizeChanger [nzPageIndex]="page" (nzPageIndexChange)="changePage($event)">
    <thead>
    <tr>
      <th>ID</th>
      <th>Nome</th>
      <th>email</th>
      <th>Ações</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of usersTable.data">
      <td>
        {{user._id}}
      </td>
      <td>
        {{ user.firstName }} {{user.lastName}}
      </td>
      <td>
        {{user.email}}
      </td>
      <td>
        <button nz-button [nzType]="'primary'" [routerLink]="[user._id]"><span>Detalhes</span></button>
      </td>
    </tr>
    </tbody>
  </nz-table>
</div>
